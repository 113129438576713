import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import './rightsidebar.css'
import useHome from "../../../hooks/useHome";
import ImageComponent from "../../Comman/ImageComponent";
import Loader from '../../Comman/UserLoader'

const RightSidebarComponents = () => {
  const [whatsNewPosts, setwhatsNewPosts] = useState(false);
  const [featuredCrators, setFeaturedCrators] = useState(false);
  const { getWhatsNewPost,getFeaturedCrators } = useHome()
  let history = useHistory();
  useEffect(async () => {
      var whatsNewPostData = await getWhatsNewPost();
      if(whatsNewPostData.posts){
        await setwhatsNewPosts(whatsNewPostData.posts);
      }
      
      
      var featuredCrators = await getFeaturedCrators();
      await setFeaturedCrators(featuredCrators.data.crators);
  }, [history])
  
  const getOwnerName = (Owner)=>{
    return Owner.postOwner ? Owner.postOwner.name:''
  }

  return (
    <>
      <div className="HomeRightSidebar">
        <div className="WhatsNewSection WhatsNewSectionMenuItems">
          <div className="WhatsNewHead">
            <h3>What’s New</h3>
          </div>
          {whatsNewPosts ? 
            <div className="WhatsNewList">
            { whatsNewPosts.map((whatsnew,index) => (
                <div className="InnerList">
                  <div className="WhatsNewImage">
                  <ImageComponent sx={{ width: '100px' }} url={whatsnew?.postOwner?.profile_image} />
                  </div>
                  <div className="WhatsNewName">
                  <a className="username" href="#">
                      <span>{whatsnew?.postTexLoadert?.substring(0,10)}</span><br /><span>{getOwnerName(whatsnew)}</span>
                    </a>
                    <a className="username" href="#">
                      <span>{whatsnew?.postTexLoadert?.substring(0,10)}</span><br /><span>@{getOwnerName(whatsnew)}</span>
                    </a>
                    <div className="ExcertImg">
                    <span className="postOptionDots">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="5" viewBox="0 0 19 5" className="ant-dropdown-trigger"><g id="Group_15" data-name="Group 15" transform="translate(-2629 -250)"><circle id="Ellipse_1" data-name="Ellipse 1" cx="2.5" cy="2.5" r="2.5" transform="translate(2629 250)"></circle><circle id="Ellipse_2" data-name="Ellipse 2" cx="2.5" cy="2.5" r="2.5" transform="translate(2636 250)"></circle><circle id="Ellipse_3" data-name="Ellipse 3" cx="2.5" cy="2.5" r="2.5" transform="translate(2643 250)"></circle></g></svg>
                    </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            :<Loader/> }
        </div>
        <div className="FeaturedCrators FeaturedCratorsMenuItems">
          <div className="FeaturedCratorHead">
            <h3>Featured CRATORS</h3>
          </div>
          {
            featuredCrators ? featuredCrators.map((featuredCrator,index) => (
            <div className="InnerCrator">
              <div className="FeaturedImage">
                <ImageComponent url={featuredCrator.profile_image} />
              </div>
              <div className="FeaturedName">
                <a href="#">@{featuredCrator.username ? featuredCrator.username :featuredCrator.name}</a>
              </div>
            </div>
            )) : <Loader/>
          }
        </div>
      </div>
    </>
  );
};

export default RightSidebarComponents;