import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import usePayment from "../../hooks/usePayment";
import { useHistory } from "react-router-dom";
import useUser from "../../hooks/useUser";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {Modal} from 'antd'
import { Button, Radio } from 'antd';
import StripeCheckout from 'react-stripe-checkout';

export default function AddFundComponent() {
  const { createPayment, getPointsList } = usePayment();
  const { isLogin, GetUserProfile } = useUser();
  const [points, sePoints] = useState();
  const [visible, setVisible] = useState(false)
  const [dynemicFund, setDynemicFund] = useState(false)  
  const [html, setHtml] = useState('')
  const [title, setTitle] = useState('')
  const [fundDetails,setFundDetaills] = useState({})
  const { auth } = useSelector((state)=>state.auth)

  const history = useHistory();

  useEffect(async () => {
    await authCheck();
    await sePoints(await getPointsList());
  }, []);

  const authCheck = async () => {
    var response = await isLogin();
    if (parseInt(response.status) === 401) {
      history.push("/login");
    } else {
      //history.push("/");
    }
  };

  const addFund = async (data) =>{
    alert('sfsdfdsfdf')
    console.log('data',data)
    // await setVisible(false)
    // await setFundDetaills(data)
    // await setTitle("Add Fund")
    // await setVisible(true)
  }

  const customFund = async(data) =>{
    await setDynemicFund(true) 
  }

  const closePop = async () => {
    await setVisible(false)
  }

  async function handleToken(token,amount) {
    token.amount = amount  
    var res = await createPayment(token)
    if(res){
      await isLogin();
      await setVisible(false)
    }
  }

  const closeDynemicPop = async() =>{
    await setDynemicFund(false) 
  }

  return (
    <div
      className="i_general_box_container generalBox extensionPost"
      style={{ height: "500px !important" }}
    >
      <div className="btest">
        <div className="i_user_details">
          {/*MESSAGE HEADER*/}
          {/*/MESSAGE HEADER*/}
          <div className="i_header_others_box">
            <div className="crnt_points">$ {auth.walletPoints} </div>
            {/**/}
            <div className="premium_plans_container">
              <h1>Add Funds</h1>
              <h2>Select an option</h2>
              <div className="buyCreditWrapper flex_ tabing">
                {/**/}
                <Box sx={{ flexGrow: 1 }}>
                  <list container spacing={2}>
                    {points &&
                      points.map((value, key) => (
                        <div onClick={(()=>addFund(value))} className="purchaseButton flex_ tabing">
                          Add{" "}
                          <strong
                            className="tabing flex_"
                            style={{ display: "inline-flex" }}
                          >
                          ${value.packageFund}
                          </strong>
                        </div>
                      ))}
                  </list>
                </Box>
                {/**/}
              </div>
              <div onClick={(()=>customFund())} className="purchaseButton flex_ tabing">
                Other{" "}
                <strong
                  className="tabing flex_"
                  style={{ display: "inline-flex" }}
                >
                  $_______{" "}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal 
          title={title} 
          visible={visible} 
          footer={null}
          onCancel={(()=>closePop())}
      >
         <div>
          <StripeCheckout
            stripeKey="pk_test_51Jzps1FIk7q10ficH1eltzOpWcX2es9Ziqehb4aMwWp5WxiDNhOmGejVtJYqdGLe1QAoACBZwtTcFvqZAnilZ7Zt00RhRGqHSU"
            token={((token)=>handleToken(token,fundDetails.packageFund))}
            amount={fundDetails.packageFund * 100}
            name="Crator"
            billingAddress
            shippingAddress
          >
            <Button
              type="submit"
              fullWidth={true}
              variant="contained"
              background="#3274e4"
            >
              Pay With Stripe ${fundDetails.packageFund}
            </Button>
          </StripeCheckout>
        </div>
      </Modal> 
      <Modal 
          title={'Add Fund'} 
          visible={dynemicFund} 
          footer={null}
          onCancel={(()=>closeDynemicPop())}
      >
          <input name="fund" />
          <input
            type="submit"
            onSubmit={((event)=>addFund(event.target.fund.value))}
            value="Pay With Stripe"
           />
      </Modal>
    </div>
  );
}
