import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getIn, Field, Formik, Form, ErrorMessage } from "formik";
import { paymentMethodSchema } from "../../../../utils/Validations";
import InputGroup from "../../../Comman/InputComponent";

const PaymentMethodForm = ({ addPayment, details }) => {
    const [formData, setFormdata] = useState([]);

    useEffect(async () => {
        await setFormdata(details);
    }, [details]);

    const initialValues = {
        paypalEmail: details?.paypalEmail || "",
        RepaypalEmail: details?.paypalEmail || "",
        cryptoAddress: details?.cryptoAddress || "",
        recryptoAddress: details?.cryptoAddress || "",
        cryptoCoin: details?.cryptoCoin || "",
        bank: details?.bankAccount || "",
        paymentMethod: details?.paymentMethod || ""
    }

    return (
        <>
            <div>
                {formData ?
                    <Formik
                        initialValues={initialValues}
                        validationSchema={paymentMethodSchema}
                        enableReinitialize={true}
                        onSubmit={(values, { resetForm }) => {
                            delete values.RepaypalEmail;
                            delete values.recryptoAddress;
                            addPayment(values)
                            resetForm()
                        }}
                    >
                        {({ errors, touched, values, setFieldValue }) => (
                            <Form className="form-group">
                                <br></br>
                                <div className="mt-5">
                                    <div className="col-md-4 form-group error-div">
                                        <h2>Cryptocurency</h2>
                                        <label>
                                            <Field type="radio" name="paymentMethod" value="crypto" />
                                            Check the box on the right if you want crypto currency to be the default payment option.
                                        </label>
                                        <InputGroup type="text" name="cryptoAddress" />
                                        <InputGroup type="text" name="recryptoAddress" />
                                        <div role="group" aria-labelledby="my-radio-group">
                                            <label>
                                                <Field type="radio" name="cryptoCoin" value="EGC" />
                                                EGC
                                            </label>
                                            <label>
                                                <Field type="radio" name="cryptoCoin" value="BNB" />
                                                BNB
                                            </label>
                                            <label>
                                                <Field type="radio" name="cryptoCoin" value="BUSD" />
                                                BUSD
                                            </label>

                                            <div>Picked: {values.picked}</div>
                                        </div>
                                        <label>
                                            <Field type="radio" name="paymentMethod" value="paypal" />
                                            Check the box on the right if you want crypto currency to be the default payment option.
                                        </label>
                                        <InputGroup type="text" name="paypalEmail" />
                                        <InputGroup type="text" name="RepaypalEmail" />

                                        <label>
                                            <Field type="radio" name="paymentMethod" value="bank" />
                                            Check the box on the right if you want crypto currency to be the default payment option.
                                        </label>
                                        <InputGroup type="text" name="bank" />
                                    </div>
                                </div>
                                <div className="settingSubmit"><button type="submit">Save</button></div>
                            </Form>
                        )}
                    </Formik>
                    : ''}
            </div>
        </>
    );
};

export default connect((state) => state)(PaymentMethodForm);