import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function FullPageLoader() {
  return (
    <div style={{ textAlign: "center" }}>
      <Box sx={{ display: "flex", width: "100%" }}>
        <iframe
          title="loading"
          src="https://embed.lottiefiles.com/animation/96372"
        ></iframe>
      </Box>
    </div>
  );
}
