import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import "./LanguagesComponet.css";
import AdminLeftMenu from "../adminLeftSideMenu/adminLeftMenuComponent";
import useAdminDashboard from "../../../hooks/useAdminDashboard";
import useUser from "../../../hooks/useUser";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";

import Button from "@material-ui/core/Button";
import Popup from "../../Comman/Popup";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const LanguagesComponet = (props) => {
  const { id } = useParams();
  const { auth } = useSelector((state) => state);
  const [allPostData, setAllPostData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [html, setHtml] = useState("");
  const [title, setTitle] = useState("");

  const userDetail = auth.auth;
  let history = useHistory();
  const { isLogin } = useUser();
  const { createLanguagesData, postDeleteData, posActiveDeactiveData } =
    useAdminDashboard();

  useEffect(async () => {
    await authCheck();
  }, [history]);

  const authCheck = async () => {
    var response = await isLogin();
    if (parseInt(response.status) === 401) {
      history.push("/login");
    } else {
      if (props) {
        return;
      }
      history.push("/");
    }
  };

  const getOwnerName = (Owner) => {
    return Owner.postOwner ? Owner.postOwner.name : "";
  };

  const closePop = async () => {
    await setVisible(false);
  };

  const postDelete = async (id, status) => {
    var postDelete = await postDeleteData(id, status);
  };

  const posActiveDeactive = async (id, status) => {
    var posActiveDeactive = await posActiveDeactiveData(id, status);
  };

  const createLanguages = async (event) => {
    event.preventDefault();
    var data = {
      name: event.target.name.value,
      code: event.target.code.value,
      nativeName: event.target.nativeName.value,
    };
    var languagesData = await createLanguagesData(data);
    await setVisible(false);
  };

  const createLanguagePopup = async (post) => {
    await setVisible(false);
    setVisible(true);
    setTitle("Add New Language");
    var html = (
      <div>
        <div className="add_app_not_point">Language Abbreviation</div>
        <div className="add_app_not_point">
          Please be sure to include this language abbreviation in the language
          file you are currently using.Open file langs/eng.php and fined the
          array $LANGNAME add your abbreviate name in array.Open other language
          packs from langs file and do the same.
        </div>
        <div className="form">
          <form onSubmit={(event) => createLanguages(event)}>
            <div className="col-md-4 form-group error-div">
              <div className="languageLabel">
                <leble for="language">Language</leble>
              </div>
              <div className="formLabel">
                <input name="name" placeholder="e.g: ch" />
              </div>
            </div>
            <div className="col-md-4 form-group error-div">
              <div className="codeLabel">
                <leble for="code">Code</leble>
              </div>
              <div className="formLabel">
                <input name="code" placeholder="Enter Language Code" />
              </div>
            </div>
            <div className="col-md-4 form-group error-div">
              <div className="nativeNameLabel">
                <leble for="nativeName">Native Name</leble>
              </div>
              <div className="formLabel">
                <input name="nativeName" placeholder="Enter Native Name" />
              </div>
            </div>
            <div className="col-md-4 form-group error-div">
              <input type="submit" value="Save" name="Save" />
            </div>
          </form>
        </div>
      </div>
    );
    setHtml(html);
  };

  return (
    <>
      <nav>
        <div style={{ display: "flex" }}>
          <div className="sideBar">
            <AdminLeftMenu />
          </div>
          <div
            className="admin_main_wrp settingFormFields"
            style={{ width: "100%" }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => createLanguagePopup()}
                  >
                    Create a new language{" "}
                  </Button>
                </Table>
              </TableContainer>
            </Box>
          </div>
          <Popup
            visible={visible}
            component={html}
            onClose={() => closePop()}
            title={title}
          />
        </div>
      </nav>
    </>
  );
};

export default LanguagesComponet;
