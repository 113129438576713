import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";

function Card({ data }) {
    const [dashBoardData, setdashBoardData] = useState([])
    const history = useHistory();
    useEffect(() => {
        setdashBoardData(data)
    }, [data]);

    return (
        <div>
            <div>DashBoard</div>
            <h3>Here you can find some data on your earnings.</h3>
            <br></br>
            {console.log(data)}
            <div>
                {!isEmpty(data) ?
                    <div>
                        <div wid>
                            <h3>Premium Content Earning</h3>
                            <h3>$ {data?.premiumEarning}</h3>
                            <span>
                            </span>
                        </div>
                        <div>
                            <h3>Subscription Earnings</h3>
                            <h3>$ {data?.subscriptionEarning}</h3>
                            <span>
                            </span>
                        </div>
                        <div>
                            <h3>Balance</h3>
                            <h3>$ {data?.totalBalance}</h3>
                            <span>
                            </span>
                        </div>
                        <div>
                            <h3>Total Tip</h3>
                            <h3>$ {data?.tipEarning}</h3>
                            <span>
                            </span>
                        </div>
                    </div>
                    :
                    <div>
                        No data available
                    </div>
                }
            </div>
        </div >
    )
}

export default Card
