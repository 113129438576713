import axios from "./config";
import cookies from "js-cookie";
const Api = axios
class DashboardRepository {

    async SaveUserProfile(data) {
        const reponse = await Api.post(
            `users/update-profile/`, data
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error.response)
                return (error.response)
            });
        return reponse;
    }

    async SaveUserAccount(data) {
        const reponse = await Api.post(
            `users/update-account`, data
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error.response)
                return (error.response)
            });
        return reponse;
    }

    async getFollowersList(query) {
        const reponse = await Api.get(
            `friends/get-my-followers/${query}`
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error.response)
                return (error.response)
            });
        return reponse;
    }

    async followUser(id) {
        const reponse = await Api.put(
            `friends/update-follow-status/${id}`
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error.response)
                return (error.response)
            });
        return reponse;
    }

    async updateEmail(data) {
        const reponse = await Api.post(
            `users/update-email`, data
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return (error.response)
            });
        return reponse;
    }

    async createPlan(data) {
        const reponse = await Api.post(
            `user-subscription-plan`, data
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return (error.response)
            });
        return reponse;
    }

    async getAllPlans() {
        const reponse = await Api.get(
            `user-subscription-plan`
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return (error.response)
            });
        return reponse;
    }

    async addPaymentMethods(data) {
        const reponse = await Api.post(
            `users/update-payment-method`, data
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return (error.response)
            });
        return reponse;
    }

    async getSubscriptionHistory(query) {
        const reponse = await Api.get(
            `user-subscription/${query}`
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return (error.response)
            });
        return reponse;
    }

    async getPaymentHistory(query) {
        const reponse = await Api.get(
            `user-payment/${query}`
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return (error.response)
            });
        return reponse;
    }

    async getDashBoardDetails() {
        const reponse = await Api.get(
            `users/dashboard-stats`
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return (error.response)
            });
        return reponse;
    }
}

export default new DashboardRepository();
