import axios from "./config";
import cookies from "js-cookie";
const Api = axios;
var token = cookies.get("token");
Api.defaults.headers.common["Authorization"] = "Bearer " + token;
class HomeRepository {
  async GetSubscribeUsers(params) {
    const reponse = await Api.get(`friends/get-my-followers/${params}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async GetFollwersUsers(params) {
    const reponse = await Api.get(`user-subscription?filter=${params}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async GetDashboardData() {
    const reponse = await Api.get(`users/dashboard-summary`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async GetPost(page) {
    const reponse = await Api.get(`posts?page=${page}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async SubscribePlane(data) {
    const reponse = await Api.post(`user-subscription/`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async CreateLiveStream(data) {
    const reponse = await Api.post(`live-stream/`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async GetLiveStream(type) {
    const reponse = await Api.get(`live-stream/get-live-stream/${type}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async joinRoom(room) {
    const reponse = await Api.get(`live-stream/join/${room}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async joinPaidRoom(room) {
    const reponse = await Api.get(`live-stream/paid/${room}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async deleteRoom(room) {
    const reponse = await Api.delete(`live-stream/${room}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async StoryCreate(data) {
    const reponse = await Api.post(`story/`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async GetStory() {
    const reponse = await Api.get(`story/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }
}
export default new HomeRepository();
