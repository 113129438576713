import React, { useState, useEffect } from "react";
import { Menu, Dropdown } from "antd";
import { connect } from "react-redux";
import ImageComponent from "../../../Comman/ImageComponent";
import Popup from "../../../Comman/Popup";
import useHome from "../../../../hooks/useHome";
import useUser from "../../../../hooks/useUser";
import { notification } from "antd";
import { SmileOutlined, CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { Modal } from "antd";
import SharePost from "./SharePost";

const TextPost = ({
  auth,
  post,
  updatePost,
  deletePost,
  packages,
  updateWhoCanSee,
  onChangeMessageEvent,
  updatePostData,
}) => {
  const [visible, setVisible] = useState(false);
  const [html, setHtml] = useState("");
  const [title, setTitle] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const {
    LikePost,
    PinPost,
    savePost,
    disableComment,
    tipToUser,
    resharePost,
  } = useHome();
  const { IsValidUser } = useUser();
  const history = useHistory();

  useEffect(async () => {
    var user = auth.user ? auth.user : auth;
    const response = await IsValidUser(post.postOwner._id);
    console.log("response", response);
    if (response.status === 200) {
      await setIsAuth(response.isSame);
    }
  }, [history]);

  const edit = (post, id) => {
    setVisible(false);
    var html = (
      <div>
        <form onSubmit={(event) => submit(event, id)}>
          <input className="input_field" name="post" defaultValue={post} />
          <div className="stoy_Submit">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    );

    setVisible(true);
    setTitle("Edit Post");
    setHtml(html);
  };

  const submit = async (event, id) => {
    event.preventDefault();
    var data = {
      name: event.target.post.value,
      id: id,
    };
    var response = await updatePost(data);
    if (response) {
      await setVisible(false);
    }
  };

  const like = async (postID) => {
    var res = await LikePost(postID);
    if (res) {
      await updatePostData();
      notification.open({
        message: "Update",
        description: "Profile Successfully Updated...!",
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
    } else {
      notification.open({
        message: "Error",
        description: "Something went wrong...!",
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
    }
  };

  const whoCanSee = (whocansee, id) => {
    setVisible(false);
    var html = (
      <select
        className="who_is_see"
        onChange={(event) => changeWhoCanSee(event, id)}
        name="who_can_see"
      >
        <option selected={whocansee === 1 ? true : false} value={1}>
          Everyone
        </option>
        <option selected={whocansee === 2 ? true : false} value={2}>
          Standerde
        </option>
        <option selected={whocansee === 3 ? true : false} value={3}>
          premium
        </option>
        {packages &&
          packages.map((values, key) => (
            <option value={values.packageType}>{values.packageName}</option>
          ))}
      </select>
    );

    setVisible(true);
    setTitle("Who Can See");
    setHtml(html);
  };

  const Delete = async (id) => {
    var response = await deletePost(id);
  };
  const closePop = async () => {
    await setVisible(false);
  };

  const changeWhoCanSee = async (event, postID) => {
    var data = {
      whoCanSee: event.target.value,
      postID: postID,
    };
    await updateWhoCanSee(data);
    setVisible(false);
  };

  const getTime = (dt) => {
    const calendarStrings = {
      lastDay: "[Yesterday at] LT",
      sameDay: "[Today at] LT",
      nextDay: "[Tomorrow at] LT",
      lastWeek: "[last] dddd [at] LT",
      nextWeek: "dddd [at] LT",
      sameElse: "L",
    };

    return <ReactTimeAgo date={dt} locale="en-US" />;
  };

  const toggleCommentsEvent = () => {
    onChangeMessageEvent();
  };

  const pin = async (postId) => {
    if (await PinPost(postId)) {
      await updatePostData();
    }
  };

  const CommentDiable = async (postId) => {
    if (await disableComment(postId)) {
      await updatePostData();
    }
  };

  const gotoUserprofile = (username) => {
    history.push(`/profile/${username}`);
  };

  const bookmarkPost = async (postId) => {
    var data = {
      postId,
    };
    var res = await savePost(data);
    await updatePostData();

    if (res) {
      notification.open({
        message: "Saved",
        description: "Post Successfully Saved In Save List...!",
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
    } else {
      notification.open({
        message: "Error",
        description: "Something went wrong...!",
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
    }
  };

  const tip = async (event, postId) => {
    event.preventDefault();
    var data = {
      id: postId,
      tip_amount: event.target.amount.value,
    };
    if (await tipToUser(data)) {
      await updatePostData();
      setVisible(false);
      notification.open({
        message: "Saved",
        description: "Tip Successfully Send!",
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
    } else {
      notification.open({
        message: "Error",
        description: "Something went wrong...!",
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
    }
  };
  const ReshareSharePost = async (event, postId) => {
    event.preventDefault();
    var data = {
      parentPostId: postId,
      caption: event.target.post.value,
    };
    if (await resharePost(data)) {
      await updatePostData();
      setVisible(false);
      notification.open({
        message: "Saved",
        description: "Post Successfully Reshared!",
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
    } else {
      notification.open({
        message: "Error",
        description: "Something went wrong...!",
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
    }
  };

  const reShareModal = (id) => {
    setVisible(false);
    var html = (
      <div>
        <form onSubmit={(event) => ReshareSharePost(event, id)}>
          <input className="input_field" name="post" />
          <SharePost post={post} />
          <div className="stoy_Submit">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    );

    setVisible(true);
    setTitle("Reshare Post");
    setHtml(html);
  };

  const openTipModal = (id) => {
    setVisible(false);
    var html = (
      <div>
        <form onSubmit={(event) => tip(event, post._id)}>
          <input
            className="input_field"
            name="amount"
            placeholder="Tip Amount"
          />
          <div className="stoy_Submit">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    );

    setVisible(true);
    setTitle("Tip");
    setHtml(html);
  };
  const menu = (
    <Menu>
      {isAuth ? (
        <>
          <Menu.Item>
            <a href="#" onClick={() => whoCanSee(post.whoCanSee, post._id)}>
              Who can see?
            </a>
          </Menu.Item>
          <Menu.Item>
            <a href="#" onClick={() => edit(post.postText, post._id)}>
              Edit post
            </a>
          </Menu.Item>
          <Menu.Item>
            <a onClick={(id) => Delete(post._id)}>Delete post</a>
          </Menu.Item>
          <Menu.Item>
            <a onClick={() => CommentDiable(post._id)}>Disable Comment</a>
          </Menu.Item>
        </>
      ) : (
        <Menu.Item>
          <a onClick={() => pin(post._id)}>Pin on my pofile</a>
        </Menu.Item>
      )}
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          Copy post url
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <div className="postCard" key={post.postOwner}>
        <div className="userProfileWrap">
          <div className="topPostHeader">
            <div className="userProfile">
              <div className="userImage">
                <ImageComponent url={post.postOwner.profile_image} />
                {post.pinnedByMe ? "" : ""}
              </div>
              <div className="userInformation">
                <div className="userName">
                  {post.postOwner.userFullName
                    ? post.postOwner.userFullName
                    : post.postOwner.name}
                </div>
                <div
                  className="userNameEmail"
                  onClick={() =>
                    post.postOwner.username
                      ? gotoUserprofile(post.postOwner.username)
                      : ""
                  }
                >
                  @
                  {post.postOwner.username
                    ? post.postOwner.username
                    : post.postOwner.email.split("@")[0]}
                </div>
              </div>
            </div>
            <div className="postAction">
              <div className="postDatetimes">{getTime(post.createdAt)}</div>
              <Dropdown overlay={menu} placement="bottomLeft" arrow>
                <span className="postOptionDots">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="5"
                    viewBox="0 0 19 5"
                    className="ant-dropdown-trigger"
                  >
                    <g
                      id="Group_15"
                      data-name="Group 15"
                      transform="translate(-2629 -250)"
                    >
                      <circle
                        id="Ellipse_1"
                        data-name="Ellipse 1"
                        cx="2.5"
                        cy="2.5"
                        r="2.5"
                        transform="translate(2629 250)"
                      ></circle>
                      <circle
                        id="Ellipse_2"
                        data-name="Ellipse 2"
                        cx="2.5"
                        cy="2.5"
                        r="2.5"
                        transform="translate(2636 250)"
                      ></circle>
                      <circle
                        id="Ellipse_3"
                        data-name="Ellipse 3"
                        cx="2.5"
                        cy="2.5"
                        r="2.5"
                        transform="translate(2643 250)"
                      ></circle>
                    </g>
                  </svg>
                </span>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="feedPostText">
          <p>
            {post.sharedPostId !== ""
              ? post?.sharedBy?.caption
              : post?.postText}
          </p>
          {post.postFile.length > 0 ? 
            <ImageComponent
              classname={"postImage"}
              url={post.postFile.length > 0 ? post.postFile[0] : ""}
            />
          :''}
        </div>
        <div className="postLikesWrap">
          <div className="postLikes">
            <div className="postLikesdot1"></div>
            <div className="postLikesdot2"></div>
            <div className="postLikesdot3"></div>
          </div>
          <div className="postLikesCount">
            {post.postLikes.length > 0 ? post.postLikes.length : 0}
          </div>
        </div>
        <div className="postBottomBoarderline"></div>
        <div className="postActionItems">
          <div className="postActionItems-left">
            {post.commentStatus ? (
              <div className="postActionMessage" onClick={toggleCommentsEvent}>
                <span className="messageicon_1"></span>
                <span className="messageicon_2"></span>
              </div>
            ) : (
              ""
            )}
            {!isAuth ? 
              <div
                className="postActionPayment"
                onClick={() => openTipModal(post._id)}
              ></div>
              :''}
            <div
              className="postActionLikes"
              onClick={() => like(post._id)}
            ></div>
            <div
              className="postActionlastdots"
              onClick={() => reShareModal(post._id)}
            >
              <span></span>
              <span></span>
            </div>
          </div>
          {!isAuth ?
            <div className="postActionItems-right">
              <div className="postActionHeartlike"></div>
              <div
                className="postActionIconSave"
                onClick={() => bookmarkPost(post._id)}
              ></div>
            </div>
          :''}
        </div>
        <Popup
          visible={visible}
          component={html}
          onClose={() => closePop()}
          title={title}
        />
      </div>
    </>
  );
};

export default connect((state) => state.auth)(TextPost);
