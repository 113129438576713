import DashboardRepository from '../api/DashboardRepository'
import { useDispatch } from 'react-redux';
import { login,logout } from '../store/auth/action';

export default function useUser() {
  const dispatch = useDispatch();
  return {
    saveUserProfile: async (data) => {
      var responseData = await DashboardRepository.SaveUserProfile(data);
      if (responseData.status === 200) {
        return true;
      }
      return false;
    },
    saveUserAccount: async (data) => {
      var responseData = await DashboardRepository.SaveUserAccount(data);
      if (responseData.status === 200) {
        return true;
      }
      return false;
    },
    getFollowersList: async (data) => {
      var responseData = await DashboardRepository.getFollowersList(data);
      if (responseData.status === 200) {
        return responseData.data;
      }
      return false;
    },
    followUser: async (data) => {
      var responseData = await DashboardRepository.followUser(data);
      if (responseData.status === 200) {
        return responseData.data;
      }
      return false;
    },
    updateEmail: async (data) => {
      var responseData = await DashboardRepository.updateEmail(data);
      if (responseData.status === 200) {
        return responseData.data;
      }
      return false;
    },
    createPlan: async (data) => {
      var responseData = await DashboardRepository.createPlan(data);
      if (responseData.status === 200) {
        return responseData.data;
      }
      return false;
    },
    getAllPlans: async () => {
      var responseData = await DashboardRepository.getAllPlans();
      if (responseData.status === 200) {
        return responseData.data;
      }
      return false;
    },
    addPaymentMethods: async (data) => {
      var responseData = await DashboardRepository.addPaymentMethods(data);
      if (responseData.status === 200) {
        return responseData.data;
      }
      return false;
    },
    getPaymentHistory: async (data) => {
      var responseData = await DashboardRepository.getPaymentHistory(data);
      if (responseData.status === 200) {
        return responseData.data;
      }
      return false;
    },
    getSubscriptionHistory: async (data) => {
      var responseData = await DashboardRepository.getSubscriptionHistory(data);
      if (responseData.status === 200) {
        return responseData.data;
      }
      return false;
    },
    requestWithDraw: async (data) => {
      var responseData = await DashboardRepository.requestWithDraw(data);
      if (responseData.status === 200) {
        return responseData.data;
      }
      return false;
    },
    getDashBoardDetails: async () => {
      var responseData = await DashboardRepository.getDashBoardDetails();
      if (responseData.status === 200) {
        return responseData.data;
      }
      return false;
    },
  };
}