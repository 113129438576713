import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import "./PremiumPosts.css";
import AdminLeftMenu from "../adminLeftSideMenu/adminLeftMenuComponent";
import useAdminDashboard from "../../../hooks/useAdminDashboard";
import useUser from "../../../hooks/useUser";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Button from "@material-ui/core/Button";
import Popup from '../../Comman/Popup';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const PremiumPostsComponent = (props) => {
  const { id } = useParams();
  const { auth } = useSelector((state) => state);
  const [allPrimiumPostData, setAllPrimiumPostData] = useState([]);
  const [visible, setVisible] = useState(false)
  const [html, setHtml] = useState('')
  const [title, setTitle] = useState('')
  const [countPost, setPosts] = useState({});

  const userDetail = auth.auth;
  let history = useHistory();
  const { isLogin } = useUser();
  const {
    getAllPrimiumPostData,
    updatePostData,
    postDeleteData,
    posActiveDeactiveData,
    getAllPostCount,
  } = useAdminDashboard();

  useEffect(async () => {
    await authCheck();
    var allPremiumPost = await getAllPrimiumPostData();
    await setAllPrimiumPostData(allPremiumPost.premiumPost.posts);
    
    var allPost = await getAllPostCount();
    await setPosts(allPost);

  }, [history]);


  const authCheck = async () => {
  var response = await isLogin();

    if (parseInt(response.status) === 401) {
      history.push("/login");
    } else {
      if (props) {
        return;
      }
      history.push("/");
    }
  };

  const getTime = (dt) => {
    const calendarStrings = {
        lastDay: '[Yesterday at] LT',
        sameDay: '[Today at] LT',
        nextDay: '[Tomorrow at] LT',
        lastWeek: '[last] dddd [at] LT',
        nextWeek: 'dddd [at] LT',
        sameElse: 'L'
    };

    return (
        ''
    );
}

  const getOwnerName = (Owner)=>{
    return Owner.postOwner ? Owner.postOwner.name:''
  }
  const closePop = async () => {
    await setVisible(false)
  }

  const postDelete = async (id) => {
    var postDelete = await postDeleteData(id);
    await getAllPrimiumPostData()
  } 
  const posActiveDeactive = async (id,status) => {
    var posActiveDeactive = await posActiveDeactiveData(id,status);
  } 
  const updatePost = async (event,id) => {
    event.preventDefault()
    var data = {
      postText:event.target.postText.value
    }
    var updatePost = await updatePostData(data,id);
    await setVisible(false)
  }

  const postEdit = async(post) => {
    await setVisible(false)
    setVisible(true)
    setTitle('Edit / Approve / Decline')
    var html = (
      <div>
        <span className="ownerName">{getOwnerName(post)}</span>
        <span className="time">{getTime(post.createdAt)}</span>
            <form onSubmit={((event) => updatePost(event,post._id))} >
                <div className="row">
                    <div className="col-md-4 form-group error-div">
                        <div className="formField">
                            <input 
                                id="site_name"  
                                name="siteName"
                                type="text"
                                placeholder="Write somthing about the post"
                            />
                        </div>
                    </div>
                    <div className="col-md-4 form-group error-div">
                        <div className="formField">
                            <input 
                                id="site_name"  
                                name="siteName"
                                type="text"
                                placeholder="Money"
                            />
                        </div>
                    </div> 
                    <div className="col-md-4 form-group error-div">
                            <div className="formLabel">
                                <leble for="siteNamelbl">Add a note to the post owner (optional)</leble>
                            </div>
                        <div className="formField">
                            <input 
                                id="site_name"  
                                name="siteName"
                                type="text"
                                placeholder="Write your note! (Optional)"
                            />
                        </div>
                    </div>  
                    <div className="col-md-4 form-group error-div">
                        <img src={post.postFile} />
                    </div>
                    <div className="col-md-4 form-group error-div">
                        <div className="formField">
                            <Button variant="outlined" color="error" onClick={(()=>posActiveDeactive(post._id,1))}> {'Active'}</Button>
                        </div>
                        <span>Before you mark it, be sure to carefully check the post. When you Approve, you do not need to write a note to the person. Because a approved notification is sent to him.</span>
                    </div>
                    <div className="col-md-4 form-group error-div">
                        <div className="formField">
                            <Button variant="outlined" color="error" onClick={(()=>posActiveDeactive(post._id,0))}>{ 'Reject'}</Button>
                        </div>
                        <span>Write a note telling you why you reject the post. (Recommended)</span>
                    </div>
                    <div className="col-md-4 form-group error-div">
                        <div className="formField">
                            <Button variant="outlined" color="error" onClick={(()=>posActiveDeactive(post._id,2))}> {'Decline'}</Button>
                        </div>
                        <span>Write a note telling you why you declined the post. (Recommended)</span>
                    </div>
                </div>
                <div className="col-md-4 form-group error-div">
                    <div className="formField">
                        <input type='submit' value="Save" name="Save"/>
                    </div>
                </div>
            </form>
      </div>
      
    )
    setHtml(html)
  }
  console.log(allPrimiumPostData)
  return (
    <>
      <nav>
        <div style={{ display: "flex" }}>
          <div className="sideBar">
            <AdminLeftMenu />
          </div>
          <div style={{ width: "100%" }}>
            <div className="post-count">
              <span>Posts()</span>
            </div>
            <Box sx={{ flexGrow: 1 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                <TableRow>
                  <TableCell>S.No.</TableCell>
                  <TableCell>Post Owner</TableCell>
                  <TableCell>Post Type</TableCell>
                  <TableCell>Date </TableCell>
                  <TableCell>Current Status	</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                  {allPrimiumPostData && allPrimiumPostData.map((row,i) => (
                    <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {i+1}
                    </TableCell>
                    <TableCell >{getOwnerName(row)}</TableCell>
                    <TableCell >{row.whoCanSee}</TableCell>
                    <TableCell >{getTime(row.createdAt)}</TableCell>
                    <TableCell >
                      <Button variant="outlined" color="error"> {row.postStatus == 1 ? 'Active' : 'Deactive'}</Button>
                    </TableCell>
                    <TableCell >
                      <Button variant="outlined" color="error" onClick={(()=>postDelete(row._id))}> Delete</Button>
                      <Button variant="contained" color="success" onClick={(()=>postEdit(row))}>Edit </Button>
                    </TableCell>
                  </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </Box>
          </div>

        </div>
      </nav>
    </>
  );
};

export default PremiumPostsComponent;
