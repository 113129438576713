import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useUser from "../../hooks/useUser";
import useHome from "../../hooks/useHome";
import ProfileLeftMenu from "../Home/RighSideMenu/RightSidebarComponents";
import RightSidebarComponents from "../Home/RighSideMenu/RightSidebarComponents";
import Navbar from "../Header/headerComponent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Popup from '../../component/Comman/Popup'
import LiveStreamList from "./LiveStreamList";
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DataArrayOutlined } from "@mui/icons-material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const LiveStreamingComponent = (props) => {
  const { id } = useParams();
  const { auth } = useSelector((state) => state)
  const [packages, setPackages] = useState([])
  const [subscription, setSubscription] = useState({});
  const [showProfile, setShowProfile] = useState(false);
  const [profileInfo, setProfileInfo] = useState([])
  const [visible, setVisible] = useState(false)
  const [html, setHtml] = useState('')
  const [title, setTitle] = useState('')
  const [live,setLive] = useState('')
  const [streamList,setStream] = useState([])
  const [isPaid,setIsPaid] = useState(false)
  const [roomNameEmpty,setRoomNameEmpty] = useState(false)
  const [roomPrice,setRoomPrice] = useState(false)

  const userDetail = auth.auth
  let history = useHistory();
  const { isLogin } = useUser()
  const {getLiveStream,subscribeUsers,createLiveStream,GetPackages, getProfileInfo } = useHome()

  useEffect(async () => {
    await authCheck();
    var packageList = await GetPackages()
    await setPackages(packageList);
    var res = await subscribeUsers('subscriber')
    await setSubscription(res)
    var streamData = await getLiveStream('free')
    if(streamData.live){
      await setStream(streamData.live)
    }
    
    if (id) {
      var profileInfo = await getProfileInfo(id);
      setProfileInfo(profileInfo);
      setShowProfile(true);
    }
  }, [history,live]);
  const authCheck = async () => {
    var response = await isLogin();

    if (parseInt(response.status) === 401) {
      history.push("/login");
    } else {
      if (props) {
        return
      }
      history.push("/");
    }
  };

  const createlivestream = async (event,type) =>{
    event.preventDefault()
    
    if(event.target.room_name.value ===''){
      await setRoomNameEmpty(true)
      return
    }
    if(event.target.price && event.target.price){
      if(event.target.price.value && event.target.price.value ===''){
        await setRoomPrice(true)
        return
      }     
    }
    
    var data = {
      roomName:event.target.room_name.value,
      islocked:event.target.islocked.checked,
      type:type,
      price:event.target.price ? event.target.price.value : ''
     }

    var response =   await createLiveStream(data)
    setVisible(false)
    await setLive(response.live_url_host)
    history.push(`join_room/${response.live_name}`)
  }

  const getLivestream = async(type) => {
    var streamData = await getLiveStream(type)
    if(streamData.live){
      await setStream(streamData.live)
    }
  }
  const createFreeLiveStreamform = (type) => {
      setVisible(false)
      var html = (
        <form onSubmit={((event)=>createlivestream(event,type))}>
          <div >
            <div>
              <input className="input_field" name="room_name" placeholder="Room Name"/>
              { roomNameEmpty ? <div> {'Please enter room name!'}</div> :''}
            </div>
            <div>
              {type === 'paid' ? 
                <>
                  <input className="input_field" name="price" placeholder="Join Price"/> 
                  {roomPrice ? <div>{ 'Please enter price!'}</div> :''}
                </>  
              :''}
            </div>
          </div>
          <div>
          <FormGroup>
            <FormControlLabel name="islocked"  control={<Checkbox defaultChecked />} label="Ask before join" />
          </FormGroup>
          </div>
          <div className="stoy_Submit">
          <button>Create</button>
          </div>
        </form>
      )

      setVisible(true)
      setTitle('Free Live Stream')
      setHtml(html)
  }

  const closePop = async () => {
    await setVisible(false)
  }

  return (
    <>
      <nav>
        <div className="headerSection">
          <Navbar />
        </div>
      </nav>
      <div className="container">
        <div className="wrapHomeSection feedPostMarginTopThirty">
          <div className="leftSideMenu">
            <ProfileLeftMenu subscription={ subscription ? subscription:{}}/>
          </div>
          <div className="middleSection">           
            <div className="postArea">
            <Paper variant="outlined" style={{ height: 100, margin: 0 }}>
            <Box sx={{ width: '100%' }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={6}>
                    <Item><Button
                          sx={{ borderRadius: '50%'}}
                          type="submit"
                          className="button_live"
                          fullWidth={true}
                          variant="contained"
                          background-color="#3072e3"
                          onClick={(()=>getLivestream('free'))}
                      >
                          Free Live Stream
                      </Button></Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item><Button
                    className="button_live"
                          sx={{ borderRadius: '50%' }}
                          type="submit"
                          fullWidth={true}
                          color="#3072e3"
                          background="#3072e3"
                          onClick={(()=>createFreeLiveStreamform('free'))}
                      >
                          Create Free Live Stream
                      </Button></Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item><Button
                          sx={{ borderRadius: '50%'}}
                          type="submit"
                          className="button_live"
                          fullWidth={true}
                          variant="contained"
                          background="#3072e3"
                          onClick={(()=>getLivestream('paid'))}
                      >
                        Paid Live Stream
                      </Button></Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item><Button
                          sx={{ borderRadius: '50%' }}
                          type="submit"
                          className="button_live"
                          fullWidth={true}
                          variant="contained"
                          background="#3072e3"
                          onClick={(()=>createFreeLiveStreamform('paid'))}

                      >
                          Create Paid Live Stream
                      </Button></Item>
                  </Grid>
                </Grid>
                </Box>
                </Paper>
            </div>
            <div className="inner_part">
            
             {/* {live !=='' ?  <iframe allow="camera;microphone" style={{height: '578px', width:'100%'}} src={live}></iframe>:''} */}

              {streamList.length > 0 ? streamList.map((value,key)=>(
                <LiveStreamList auth={auth} liveStream={value}/>
              )):''}
             
            </div>
          </div>
          <div className="leftSideMenu">
            <RightSidebarComponents />
          </div>
          <Popup visible={visible} component={html} onClose={(() => closePop())} title={title} />

        </div>
      </div>
    </>
  );
};

export default LiveStreamingComponent;
