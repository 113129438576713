import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { isEmpty } from 'lodash'
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import useDashboard from '../../../hooks/useDashboard'
import Navbar from '../../Header/headerComponent';
import useUser from "../../../hooks/useUser";
import LeftMenu from "../LeftMenu";
import { useHistory } from "react-router-dom";
import { Button, notification } from 'antd';
import { SmileOutlined, CloseOutlined } from '@ant-design/icons';
import '../settings.css'
import Card from "./Card";


const Followers = () => {
    const { auth } = useSelector((state) => state.auth);
    const { isLogin } = useUser()
    const { getFollowersList, followUser } = useDashboard()
    const [followerData, setfollowerData] = useState([]);
    const [page, setpage] = useState(1);
    const [reload, setReload] = useState(false);
    let history = useHistory();

    useEffect(async () => {
        await authCheck();
        let res = await getFollowersList(`flwr?page=${page}`);
        setfollowerData(res.data);
    }, [page])

    const authCheck = async () => {
        var response = await isLogin();
        if (parseInt(response.status) === 401) {
            history.push("/login");
        }
    };

    const followFriends = async (data) => {
        var res = await followUser(data);
        if (res) {
            notification.open({
                message: 'Update',
                description: res.message,
                icon: <SmileOutlined style={{ color: '#108ee9' }} />,
            });
            setReload(!reload);
        }
        else {
            notification.open({
                message: 'Error',
                description: 'Something went wrong...!',
                icon: <CloseOutlined style={{ color: '#f4364c' }} />,
            });
            setReload(!reload);
        }
    }
    return (
        <>
            <nav>
                <div className="headerSection">
                    <Navbar />
                </div>
            </nav>
            <div className="container">
                <div className="wrapHomeSection">
                    <div className="leftSideMenu">
                        <LeftMenu />
                    </div>
                    <div className="settingArea">
                        <div className="settingArea">
                            <Grid container style={{ width: "90%", margin: "0 auto" }}>
                                <Grid item xs={12}>
                                    <Paper variant="outlined" style={{ height: 500, margin: 0 }}>
                                        <div>
                                            {!isEmpty(auth) && !isEmpty(followerData) ?
                                                <Card
                                                    list={followerData}
                                                    action={((data) => followFriends(data))}
                                                    userDetails={auth}
                                                    pageSet={setpage}
                                                /> : ''}

                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect((state) => state)(Followers);



