import React, { useEffect, useState } from "react";
import { Avatar } from "@material-ui/core";
import Grid from "@mui/material/Grid";
// import Stories from 'react-insta-stories';
import image from "../images/story_bg.png";
import iconsvg from "../images/Vector.svg";
import nextsvg from "../Icon/nexticon.svg";
import useStory from "../../hooks/useStory";
import ImageCroper from "../Comman/ImageCroper";
import { notification } from "antd";
import { SmileOutlined, CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import AswModal from "react-awesome-modal";
import { imageUrl } from "../../api/impUrl";
import "./Storycomponent.css";
import Loader from "../Comman/StoryLoader";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import "./Storycomponent.css";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ backgroundImage: `url(${nextsvg})` }}
      onClick={onClick}
    />
  );
}
function Story({ profileSrc, title }) {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    className: "slider variable-width",
    nextArrow: <SampleNextArrow />,
  };
  const { StoryCreate, GetStory } = useStory();
  const [visible, setVisible] = useState(false);
  const [storyVisible, setStoryVisible] = useState(false);
  const [html, setHtml] = useState("");
  const [uploadImage, setFileUpload] = useState(null);
  const [showImage, setShowIImage] = useState(null);
  const [imgAndUrl, setImgAndUrl] = useState(null);
  const [src, setSrc] = useState(null);
  const [allStory, setAllStory] = useState(false);
  const [showStory, setShowStory] = useState([]);
  const [croperVisible, setCroperVisible] = useState(false);

  useEffect(async () => {
    var res = await GetStory();
    if (res) {
      await setAllStory(res.stories);
    }
  }, []);
  const Input = styled("input")({
    display: "none",
  });

  const createStory = async () => {
    const formData = new FormData();
    formData.append("storyFile", imgAndUrl.image);
    var res = await StoryCreate(formData);
    if (res) {
      await setVisible(false);
      notification.open({
        message: "Success",
        description: "Story Successfully Created...!",
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
    } else {
      notification.open({
        message: "Error",
        description: "Something went wrong...!",
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
    }
  };

  const onFileChange = async (event) => {
    await setFileUpload(event.target.files[0]);

    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSrc(reader.result));
      reader.readAsDataURL(event.target.files[0]);
    }
    await setCroperVisible(true);
  };

  const openStoryCreateModal = () => {
    setVisible(false);
    setVisible(true);
  };

  const getCropImage = async (data) => {
    await setShowIImage(data.url);
    await setImgAndUrl(data);
  };

  const closePop = async () => {
    await setVisible(false);
  };

  const closeCropPop = async () => {
    await setCroperVisible(false);
  };

  const deleteCropImage = async (data) => {
    await setShowIImage(null);
  };

  const showCropImage = () => {
    return (
      <>
        <div>
          <button className="input_field" onClick={() => deleteCropImage()}>
            Delete
          </button>
          <img style={{ width: "250px", height: "250px" }} src={showImage} />
        </div>
      </>
    );
  };

  const storyFormateModal = async (data) => {
    var withUrl = [];
    data.map((file, key) => {
      withUrl.push(imageUrl + file);
    });
    await setShowStory(withUrl);
    // await setStoryVisible(true)
  };

  const addBaseUrl = (st) => {
    // var withUrl = []
    // st.map((file,key)=>{
    //   withUrl.push(imageUrl+file)
    // })
    console.log(st);
    return imageUrl + st;
  };

  const endStory = () => {
    console.log("end ho gai story");
  };

  const storiesPopup = () => {
    return (
      <>
        {/* <Stories
            stories={showStory}
            defaultInterval={2500}
            width={'100%'}
            height={500}
            loop={true}
            onStoryEnd={(()=>endStory())}
          /> */}
      </>
    );
  };

  const closeModal = async () => {
    await setStoryVisible(false);
  };

  return (
    <div class="story_wrp">
      <Slider {...settings}>
        <div>
          <div className="story" style={{ backgroundImage: `url(${image})` }}>
            <div class="story_wrp_icon">
              <Avatar
                onClick={() => openStoryCreateModal()}
                className="story__avatar"
                src={iconsvg}
              />
              <h4>TRAILER</h4>
            </div>
          </div>
        </div>

        {allStory.length > 0 ? (
          allStory.map((value, key) => (
            <div>
              <div
                onClick={() => storyFormateModal(value.storyFile)}
                style={{
                  backgroundImage: `url(${imageUrl + value.storyFile[0]})`,
                }}
                className={`story ${imageUrl + value.storyFile[0]}`}
              >
                <Avatar
                  className="story__avatar"
                  src={imageUrl + value?.storyOwner?.profile_image}
                />
                <h4>{value?.storyOwner?.name}</h4>
              </div>
            </div>
          ))
        ) : (
          <Loader />
        )}
      </Slider>
      <Modal
        title={"Add Story"}
        visible={visible}
        onOk={"handleOk"}
        footer={null}
        onCancel={() => closePop()}
      >
        <div className="popup_model">
          <div></div>
          <div className="popup_model">
            <input className="input_field" name="post" />
          </div>
          <div className="popup_model_but">
            {/*<input type="file" accept="image/*" onChange={((event) => onFileChange(event))} />*/}
            <label htmlFor="contained-button-file">
              <Input
                accept="image/*"
                id="contained-button-file"
                onChange={(event) => onFileChange(event)}
                multiple
                type="file"
              />
              <Button variant="contained" component="span">
                Upload
              </Button>
            </label>
            <div>{showImage ? showCropImage() : ""}</div>
            <div className="stoy_Submit">
              <button onClick={() => createStory(uploadImage)}>Submit</button>
            </div>
          </div>
        </div>
      </Modal>
      {src && (
        <ImageCroper
          src={src}
          visible={croperVisible}
          onClose={() => closeCropPop()}
          getCropImage={(data) => getCropImage(data)}
        />
      )}
      {/* <Popup visible={visible} component={html} onClose={(() => closePop())} title={popupTitle} /> */}
    </div>
  );
}

export default Story;
