import HomeRepository from '../api/HomeRepository'
import { useDispatch } from 'react-redux';
import PostRepository from '../api/PostRepository';
import { DataArraySharp } from '@mui/icons-material';

export default function useUser() {
    const dispatch = useDispatch();

    return {
        subscribeUsers:async(data)=>{
            var responseData = await HomeRepository.GetSubscribeUsers(data);
            if(responseData.status === 200){               
                return responseData.data.data
            }
            return false;
        },
        follwersUsers:async(data)=>{
            var responseData = await HomeRepository.GetFollwersUsers(data);
            if(responseData.status === 200){               
                return responseData.data.data
            }
            return false;
        },
        dashboardData:async(data)=>{
            var responseData = await HomeRepository.GetDashboardData(data);
            if(responseData.status === 200){  
                return responseData.data
            }
            return false;
        },
        getPost:async(page)=>{
            var responseData = await HomeRepository.GetPost(page);
            if(responseData.status === 200){  
                return responseData.data.data
            }
            return false;
        },
        CreatePost:async(data)=>{
            var responseData = await PostRepository.CreatePost(data);
            console.log('created post',responseData)
            if(responseData.status === 201){  
                return responseData.data.data
            }
            return false;
        },
        EditPost:async(data,id)=>{
            var responseData = await PostRepository.EditPost(data,id);
            if(responseData.status === 200){  
                return responseData.data.data
            }
            return false;
        },
        DeletePost:async(id)=>{
            var responseData = await PostRepository.DeletePost(id);
            if(responseData.status === 200){  
                return true
            }
            return false;
        },
        GetPackages:async()=>{
            var responseData = await PostRepository.getPackaes();
            if(responseData.status === 200){  
                return responseData.data.data;
            }
            return false;
        },
        GetUserPackages:async(userid)=>{
            var responseData = await PostRepository.getUserPackaes(userid);
            if(responseData.status === 200){  
                return responseData.data.data;
            }
            return false;
        },
        ChangeWhoCanSee:async(data)=>{
            var responseData = await PostRepository.changeWhoCanSee(data);           
            if(responseData.status === 201){  
                return responseData.data.data;
            }
            return false;
        },
        SaveComment:async(data)=>{
            var responseData = await PostRepository.saveComment(data);           
            if(responseData.status === 201){  
                return responseData.data.data;
            }
            return false;
        },
        EditComment:async(data,commentId)=>{
            var responseData = await PostRepository.editComment(data,commentId);           
            if(responseData.status === 200){  
                return responseData.data.data;
            }
            return false;
        },
        DeleteComment:async(commentId)=>{
            var responseData = await PostRepository.deleteComment(commentId);           
            if(responseData.status === 200){  
                return responseData.data.data;
            }
            return false;
        },
        LikePost:async(postId)=>{
            var responseData = await PostRepository.likePost(postId);           
            if(responseData.status === 200){  
                return responseData.data.data;
            }
            return false;
        },
        getProfileInfo:async(id)=>{
            var responseData = await PostRepository.getProfileInfo(id);           
            if(responseData.status === 200){  
                return responseData.data.data;
            }
            return false;
        },
        PinPost:async(postId)=>{
            var responseData = await PostRepository.pinPost(postId);           
            if(responseData.status === 200){  
                return responseData.data.data;
            }
            return false;
        },
        LikeComment:async(commentId)=>{
            var responseData = await PostRepository.LikeComment(commentId);           
            if(responseData.status === 200){  
                return responseData.data.data;
            }
            return false;
        },
        SubscribePlane:async(data)=>{
            var responseData = await HomeRepository.SubscribePlane(data);           
            if(responseData.status === 201){  
                return responseData.data.data;
            }
            return false;
        },
        savePost:async(data)=>{
            var responseData = await PostRepository.SavePost(data);           
            if(responseData.status === 201){  
                return responseData.data.data;
            }
            return false;
        },
        getSavedPost:async()=>{
            var responseData = await PostRepository.GetSavedPost();           
            if(responseData.status === 200){  
                return responseData.data.data;
            }
            return false;
        },
        PremiumPost:async()=>{
            var responseData = await PostRepository.premiumPost();           
            if(responseData.status === 200){  
                return responseData.data.data;
            }
            return false;
        },
        createLiveStream:async(data)=>{
            var responseData = await HomeRepository.CreateLiveStream(data);           
            if(responseData.status === 201){  
                return responseData.data.data;
            }
            return false;
        },
        getLiveStream:async(type)=>{
            var responseData = await HomeRepository.GetLiveStream(type);           
            if(responseData.status === 200){  
                return responseData.data.data;
            }
            return false;
        },
        joinRoom:async(room)=>{
            var responseData = await HomeRepository.joinRoom(room);           
            if(responseData.status === 200){  
                return responseData.data;
            }
            return false;
        },
        paidRoomJoin:async(room)=>{
            var responseData = await HomeRepository.joinPaidRoom(room);           
            if(responseData.status === 200){  
                return responseData.data;
            }
            return false;
        },
        deleteRoom:async(room)=>{
            var responseData = await HomeRepository.deleteRoom(room);           
            if(responseData.status === 200){  
                return responseData.data;
            }
            return false;
        },
        disableComment:async(postID)=>{
            var responseData = await PostRepository.commentDisable(postID);           
            if(responseData.status === 200){  
                return responseData.data.data;
            }
            return false;
        },
        tipToUser:async(data)=>{
            var responseData = await PostRepository.TipToUser(data);           
            if(responseData.status === 200){  
                return responseData.data.data;
            }
            return false;
        },
        resharePost:async(data)=>{
            var responseData = await PostRepository.ResharePost(data);           
            if(responseData.status === 200){  
                return responseData.data.data;
            }
            return false;
        }, 
        getWhatsNewPost:async()=>{
            var responseData = await PostRepository.getWhatsNewPost();     
            console.log('responseData',responseData)      
            if(responseData.status === 200){  
                return responseData.data.data;
            }
            return false;
        },
        getFeaturedCrators:async()=>{
            var responseData = await PostRepository.getFeaturedCrators();           
            if(responseData.status === 200){  
                return responseData.data;
            }
            return false;
        },
        tipToPostUser:async(data)=>{
            var responseData = await PostRepository.tipToPostUser(data);           
            if(responseData.status === 200){  
                return responseData.data;
            }
            return false;
        },
        postWhoCanSee:async(data)=>{
            var responseData = await PostRepository.postWhoCanSee(data);           
            if(responseData.status === 200){  
                return responseData.data;
            }
            return false;
        }
    }
}
