import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import CreatePostComponent from '../Middle/Post/CreatePostComponent';
import useUser from "../../../hooks/useUser";
import useHome from "../../../hooks//useHome";
import ProfileLeftMenu from "../ProfileLeftMenu";
import RightSidebarComponents from "../RighSideMenu/RightSidebarComponents";
import PostComponent from "../Middle/Post/PostComponent";
import Navbar from "../../Header/headerComponent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import '../../Home/HomeComponent.css'

const BookmarkComponent = (props) => {
  const { id } = useParams();
  const { auth } = useSelector((state) => state)
  const [post, setPost] = useState([])
  const [packages, setPackages] = useState([])
  const [postCount, setPostCount] = useState(1);
  const [subscription, setSubscription] = useState({});
  const [showProfile, setShowProfile] = useState(false);
  const [profileInfo, setProfileInfo] = useState([])
  const [isBookMark,setBookMark] = useState(false)

  const userDetail = auth.auth
  let history = useHistory();
  const { isLogin } = useUser()
  const {getSavedPost, subscribeUsers,getPost, CreatePost, EditPost, DeletePost, GetPackages, ChangeWhoCanSee, getProfileInfo } = useHome()

  useEffect(async () => {
    var{match:{params}} = props
    await authCheck();
    var post = await getSavedPost();
    await setPost(post.posts)
    var packageList = await GetPackages()
    await setPackages(packageList);
    var res = await subscribeUsers('subscriber')
    await setSubscription(res)
   
    if (id) {
      var profileInfo = await getProfileInfo(id);
      setProfileInfo(profileInfo);
      setShowProfile(true);
    }
  }, [history]);
  const authCheck = async () => {
    var response = await isLogin();

    if (parseInt(response.status) === 401) {
      history.push("/login");
    } else {
      if (props) {
        return
      }
      history.push("/");
    }
  };
  const createPost = async (event,fileObj) => {
    event.preventDefault()
    const formData = new FormData();
    if(fileObj){
      formData.append('postFile', fileObj.file)
    }    
    formData.append('postText', event.target.post.value)
    formData.append('whoCanSee', event.target.who_can_see.value)
    formData.append('urlSlug', event.target.post.value.replace(' ', '-'))
    formData.append('postCreatorIp', 1234)
    formData.append('postWantStatus', 'normal')
    formData.append('postStatus', 1)
    formData.append('commentStatus', 1)
    
    var response = await CreatePost(formData)
    if (response) {
      post.unshift(response)
      await setPostCount(Math.floor(Math.random() * 100));
      await setPost(post)
    }
  }

  const updatePost = async (data) => {
    var response = await EditPost(data.name, data.id);
    if (response) {
      var updatedPost = post.filter((el) => {
        if (el._id !== data.id) {
          return el
        }
      })
      updatedPost.unshift(response)
      await setPostCount(Math.floor(Math.random() * 100));
      await setPost(updatedPost)
      return response
    }
  }

  const deletePost = async (id) => {
    var response = await DeletePost(id)
    if (response) {
      var updatedPost = post.filter((el) => {
        if (el._id !== id) {
          return el
        }
      })
      await setPostCount(Math.floor(Math.random() * 100));
      await setPost(updatedPost)
      return response
    }
  }

  const updateWhoCanSee = async (data) => {
    await ChangeWhoCanSee(data)
    
  }

  const updatePostData = async () => {
    var post = await getSavedPost();
    await setPost(post.posts)
  }

  return (
    <>
      <nav>
        <div className="headerSection">
          <Navbar />
        </div>
      </nav>
      <div className="container">
        <div className="wrapHomeSection feedPostMarginTopThirty">
          <div className="leftSideMenu">
            <ProfileLeftMenu subscription={ subscription ? subscription:{}}/>
          </div>
          <div className="middleSection">                  
            <div className="postArea">
              <Grid container style={{ width: "100%", margin: "0 auto" }} margin="dense">
                <Grid item xs={12}>
                  <Paper variant="outlined" style={{ height: 100, margin: 0 }}>
                    {post && postCount > 0 ? (
                      <PostComponent updatePostData={(() => updatePostData())} updateWhoCanSee={((data) => updateWhoCanSee(data))} packages={packages} deletePost={((id) => deletePost(id))} updatePost={((event) => updatePost(event))} posts={post} />

                    ) : (
                      "Loading..."
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="leftSideMenu">
            <RightSidebarComponents />
          </div>
        </div>
      </div>
    </>
  );
};

export default BookmarkComponent;
