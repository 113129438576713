import React , {useState,useEffect} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { notification } from 'antd';
import { SmileOutlined, CloseOutlined } from '@ant-design/icons';
import useHome from '../../../hooks/useHome';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import { connect } from 'react-redux';
import {imageUrl} from '../../../api/impUrl'
import ImageComponent from '../../Comman/ImageComponent';
import { format,addDays } from "date-fns";
import { useHistory } from "react-router-dom";


const PopupPackageDetails = ({ userpackage,userInfo,closePop,auth }) => {
    const {SubscribePlane} = useHome();
    const [showError,setError] = useState(false)
    const [selected,selectedPackage] = useState('')
    const history = useHistory()
    const purchasePlane = async(planId,planAmount,type)=>{
        var day = '';
        if(type==='weekly'){
            day = 7;
        }
        else if(type==='monthly'){
            day = 30;
        }
        else{
            day = 365;
        }

        selectedPackage(type)
        
        // var formattedDate = format(new Date(), "MMMM do, yyyy H:mma");
        if(selected !== ''){
            if(auth.walletPoints < planAmount){
                await setError(true)
                return
            }
            var startdate = new Date()
            startdate = format(startdate, "yyyy/M/dd H:mm:ss");

            var enddate = addDays(new Date(), day)
            enddate = format(enddate, "yyyy/M/dd H:mm:ss");

            var data = {
                planId:planId,
                planAmount:planAmount,
                planPeriodStart:startdate,
                planPeriodEnd:enddate,
                subscribeTo:userInfo._id
            }
            var res = await SubscribePlane(data)
            if (res) {
                notification.open({
                    message: 'Subscribed',
                    description: 'Plan successfully subscribed!',
                    icon: <SmileOutlined style={{ color: '#108ee9' }} />,
                });
                await closePop()
            }
            else {
                notification.open({
                    message: 'Error',
                    description: 'Something went wrong...!',
                    icon: <CloseOutlined style={{ color: '#f4364c' }} />,
                });
            }
        }
    }
    
    return (
        <Card sx={{ maxWidth: '100%' }}>
          <CardMedia    
            component="img"
            height="140"
            image={imageUrl+userInfo.cover_image}
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
                <ImageComponent 
                    mystyle={{width: '82px',borderRadius: '100%',height: '82px'}}
                    classname={'packageprofile'} url={userInfo.profile_image}/> 
            </Typography>
            <Typography variant="h5" component="div">
                    {userpackage.packageName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {userpackage.benefits && userpackage.benefits.map((value,key)=>(
                    <div>
                        <CheckIcon/><span>{value}</span>
                    </div>    
                ))}                    
            </Typography>
          </CardContent>
          <CardActions>
            <div>
                {selected === '' || selected === 'weekly' ?
                    <Button
                        type="submit"
                        fullWidth={true}
                        variant="contained"
                        background="#3274e4"
                        onClick={(()=>purchasePlane(userpackage._id,userpackage.weeklyPrice,'weekly'))}    
                    >
                    ${userpackage.weeklyPrice} Weekly
                    </Button>
                :''}
            </div>
            <div>
                {selected === '' || selected === 'monthly' ?
                    <Button
                        type="submit"
                        fullWidth={true}add_balance
                        variant="contained"
                        background="#3274e4"
                        onClick={(()=>purchasePlane(userpackage._id,userpackage.monthlyPrice,'monthly'))}
                        
                    >
                        ${userpackage.monthlyPrice} Monthly
                    </Button>
                :''}
            </div>
            <div>
                {selected === '' || selected === 'yearly' ?    
                    <Button
                        type="submit"
                        fullWidth={true}
                        variant="contained"
                        background="#3274e4"
                        onClick={(()=>purchasePlane(userpackage._id,userpackage.yearlyPrice,'yearly'))}

                    >
                        ${userpackage.yearlyPrice} Yearly
                    </Button>
                :''}    
            </div>
            {selected !=='' && showError ?
                <span>You don't have a sufficient fund in your wallet! <a onClick={(()=>history.push('/add_balance'))}>add balance</a></span>
            :''}
          </CardActions>
        </Card>
      );
}

export default connect((state)=>state.auth)(PopupPackageDetails);