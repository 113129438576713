import axios from "./config";
import cookies from "js-cookie";
const Api = axios

class PostRepository {

    async CreatePost(params) {
        console.log('save post',params)
        const reponse = await Api.post(
            `posts/`,params
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    }

    async EditPost(params,id) {
        const reponse = await Api.put(
            `posts/${id}`,{postText:params}
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    }

    async DeletePost(id) {
        const reponse = await Api.delete(
            `posts/${id}`
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    }
    
    async getPackaes() {
        const reponse = await Api.get(
            `user-subscription-plan/`
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    } 

    async getUserPackaes(userid) {
        const reponse = await Api.get(
            `user-subscription-plan/user-plans/${userid}`
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    }

    async changeWhoCanSee(data) {
        const reponse = await Api.post(
            `posts/change_who_can_see`,data
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    }

    async saveComment(data) {
        const reponse = await Api.post(
            `post-comments`,data
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    }

    async editComment(data,postId) {
        const reponse = await Api.put(
            `post-comments/${postId}`,data
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    }

    async deleteComment(commentId) {
        const reponse = await Api.delete(
            `post-comments/${commentId}`
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    }

    async likePost(postId) {
        const reponse = await Api.put(
            `posts/update-likes/${postId}`
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    }

    async getProfileInfo(id) {
        const reponse = await Api.get(
            `/users/get-profile/${id}`
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    }
    
    async pinPost(postId) {
        const reponse = await Api.put(
            `posts/pin-post/${postId}`
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    }
    
    async LikeComment(commentId) {
        const reponse = await Api.put(
            `post-comments/update-likes/${commentId}`
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    }
    
    async SavePost(data) {
        const reponse = await Api.post(
            `saved-post/`,data
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    }

    async GetSavedPost() {
        const reponse = await Api.get(
            `saved-post/`
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    } 
    async premiumPost() {
        const reponse = await Api.get(
            `posts/premium-post/`
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    }
    async commentDisable(postId) {
        const reponse = await Api.put(
            `posts/update-comment-status/${postId}`
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    }
    async TipToUser(data){
        const reponse = await Api.post(
            `user-payment/tip/`,data
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    }
    async ResharePost(data){
        const reponse = await Api.post(
            `posts/reshare-post`,data
        )
            .then((response) => {
                return response;
            })
            .catch((error)=>{
                console.log(error.response)
                return(error.response)
            });
        return reponse;
    }
    async getWhatsNewPost(){
        return new Promise((resolve, reject) => {
            Api.get(
                `posts/get_whats_new`
            )
            .then(async(response) => {                        
                resolve(response);
            })
            .catch((error) => ({ error: JSON.stringify(error) }));            
        });
    }
    async getFeaturedCrators(){
        const reponse = await Api.get(
            `posts/get_featured_crator`
        )
        .then((response) => {
            return response;
        })
        .catch((error)=>{
            console.log(error.response)
            return(error.response)
        });
        return reponse;
    } 
    async tipToPostUser(data){
        const reponse = await Api.post(
            `user-payment/tip_to_post_user/`,data
        )
        .then((response) => {
            return response;
        })
        .catch((error)=>{
            console.log(error.response)
            return(error.response)
        });
        return reponse;
    }
    async postWhoCanSee(data){
        const reponse = await Api.post(
            `users/who_can_see/`,data
        )
        .then((response) => {
            return response;
        })
        .catch((error)=>{
            console.log(error.response)
            return(error.response)
        });
        return reponse;
    }
}
export default new PostRepository();
