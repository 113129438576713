import React from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Dashbord from "../../Icon/dashbord.png";
const DashboardCards = ({dashboard}) => { 
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));
      
    return(
        <>     
               <Box sx={{display: 'flex', alignItems: 'flex-end' }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <Item>
            <div className="cards">
                <div>Impression</div>
                <div>{dashboard.loved}</div>
            </div>
            </Item>
            </Grid>
            <Grid item xs={6} md={3}>
            <Item>
            <div className="cards">
                    <div>Profile Visitor</div>
                    <div>{dashboard.profileVisitor}</div>
                </div>
            </Item>
            </Grid>
            <Grid item xs={6} md={3}>
            <Item>
            <div className="cards">
                    <div>Loves You</div>
                    <div>100</div>
                </div>
            </Item>
            </Grid>
            <Grid item xs={6} md={3}>
            <Item>
            <div className="cards">
                    <div>Subscriber</div>
                    <div>{dashboard.subscriber}</div>
                </div>
            </Item>
            </Grid>
        </Grid>
    </Box> 
    <Box sx={{display: 'flex', alignItems: 'flex-end' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Item>
            <div className="cards">
            <img width="568px" src={Dashbord} />
            </div>
            </Item>
            
            </Grid>
        </Grid>
    </Box> 
            
        </>
    )
}

export default DashboardCards;