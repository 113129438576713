import  React,{useState,useEffect} from 'react';
import { styled } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Modal } from 'antd';
import useHome from '../../hooks/useHome';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  

const LiveStreamList = ({ liveStream,auth }) => {
    const [visible, setVisible] = useState(false)
    const [room, setRoomName] = useState('')
    const [roomPrice, setRoomPrice] = useState('')
    const [isStreamOwner, setIsStreamOwner] = useState(false)
    const [alreadyIn, setAlready] = useState(false)
    const { paidRoomJoin } = useHome()
    const history = useHistory()

    useEffect(async()=>{     
        var picked = liveStream.paid_users.filter(x => x === auth.auth._id); 
        if(picked.length > 0){
            await setAlready(true)
        }
        if(liveStream.liveUidFk._id === auth.auth._id){           
            await setIsStreamOwner(true)
        }
    },[auth,liveStream])

    const closePop = async () => {
        await setVisible(false)
    }
    const join = (roomName)=>{
        history.push(`join_room/${roomName}`)
    }

    const joinPaidPopup = async(roomName,price)=>{
        if(alreadyIn){
            history.push(`join_room/${roomName}`) 
        }
        await setVisible(false)
        await setRoomName(roomName)
        await setRoomPrice(price)
        await setVisible(true)      
    }

    const joinPaidRoom = async() =>{
        if(await paidRoomJoin(room)){
            history.push(`join_room/${room}`) 
        }        
    }

    return (
        <Box key={liveStream._id} sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>         
                <Grid item xs={6}>
                    <Item>
                        <div>{liveStream.live_name}</div>
                        <div>Type : {liveStream.live_type === 'free' ? 'Free':'Paid'}</div>
                        <div>
                            {!alreadyIn ?
                                <>
                                    {!isStreamOwner && liveStream.live_type==='paid' ? 
                                        <button onClick={(()=>joinPaidPopup(liveStream.live_name,liveStream.live_credit))}>Join {!alreadyIn ? '$'+liveStream.live_credit:''} </button>
                                        :
                                        <button onClick={(()=>join(liveStream.live_name))}>Join</button>
                                    }
                                </>    
                            :<button onClick={(()=>join(liveStream.live_name))}>Join</button>}    
                        </div>
                    </Item>
                </Grid>      
                <Modal 
                    title={'Paid Live Stream'} 
                    visible={visible} 
                    footer={null}
                    onCancel={(()=>closePop())}
                >
                    <div>
                        <div>
                            Would you like to join this room!
                        </div>
                        <div>
                            <button onClick={(()=>joinPaidRoom())}>Join ${roomPrice}</button>
                        </div>
                    </div>
                    
                </Modal>   
            </Grid>
        </Box>
    );
}

export default LiveStreamList;