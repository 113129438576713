import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import LeftMenu from "../LeftMenu";
import Image from "../../Comman/ImageComponent";
import useDashboard from '../../../hooks/useDashboard'
import Navbar from "../../Header/headerComponent";
import useUser from "../../../hooks/useUser";
import { useHistory } from "react-router-dom";
import ProfileUpdateForm from './Form/ProfileUpdateForm';
import { notification } from 'antd';
import { SmileOutlined, CloseOutlined } from '@ant-design/icons';
import ImageCroper from '../../Comman/ImageCroper';
import '../settings.css'

const ProfileSettings = () => {
    const { auth } = useSelector((state) => state.auth);
    const { isLogin, UploaduserImage } = useUser()
    const { saveUserProfile } = useDashboard()
    const [src, setSrc] = useState(null)
    const [visible, setVisible] = useState(false)
    const [showImage, setShowIImage] = useState(null)
    const [imgAndUrl, setImgAndUrl] = useState(null)
    const [imgType, setImgtype] = useState(null)
    let history = useHistory();

    useEffect(async () => {
        await authCheck();
    }, [])

    const authCheck = async () => {
        var response = await isLogin();
        if (parseInt(response.status) === 401) {
            history.push("/login");
        }
    };

    const onSelectFile = async (e, type) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                setSrc(reader.result)
            );
            reader.readAsDataURL(e.target.files[0]);
        }
        await setVisible(true)
        await setImgtype(type)
    };

    const closePop = async () => {
        await setVisible(false)
    }

    const getCropImage = async (data) => {
        if (imgType === 'profile') {
            await setShowIImage(data.url)
        }
        await setImgAndUrl(data)
        var formData = new FormData;
        formData.append('userImage', data.image)
        formData.append('type', imgType)
        await UploaduserImage(formData)
    }

    const update = async (data) => {
        var res = await saveUserProfile(data)
        if (res) {
            notification.open({
                message: 'Update',
                description:
                    'Profile Successfully Updated...!',
                icon: <SmileOutlined style={{ color: '#108ee9' }} />,
            });
        }
        else {
            notification.open({
                message: 'Error',
                description:
                    'Something went wrong...!',
                icon: <CloseOutlined style={{ color: '#f4364c' }} />,
            });
        }
        return res;
    }
    return (
        <>
            <nav>
                <div className="headerSection">
                    <Navbar />
                </div>
            </nav>
            <div className="container">
                <div className="wrapHomeSection">
                    <div className="leftSideMenu">
                        <LeftMenu />
                    </div>
                    <div className="settingArea">
                        <Grid container>
                            <Grid item xs={12}>
                                <div className="profileImageSection">
                                    <div className="profileImageText">
                                        <h3>Profile Picture</h3>
                                        <p>Recomended size: 260 x 260px</p>
                                    </div>
                                    <label for="profileImageAvtarlbl">
                                        <div className="profileImageAvtar">
                                            {!showImage ?
                                                <Image url={auth ? auth.profile_image : ''} />
                                                : <img src={showImage} />
                                            }
                                        </div> </label>
                                    <input id="profileImageAvtarlbl" type="file" accept="image/*" onChange={((event) => onSelectFile(event, 'profile'))} />

                                </div>
                                <div className="bannerImageSection">
                                    <div className="bannerText">
                                        <h3>Profile Banner </h3>
                                        <p>Recomended size: 1728 x 352px</p>
                                    </div>
                                    <label for="profileImagebannerlbl">
                                        <div className="bannerImageManin">
                                            <Image classname={'coverImage'} url={auth ? auth.cover_image : ''} />
                                        </div>
                                    </label>
                                    <input id="profileImagebannerlbl" type="file" accept="image/*" onChange={((event) => onSelectFile(event, 'cover'))} />
                                </div>
                                <div className="settingFormSection">
                                    {auth ? <ProfileUpdateForm update={((data) => update(data))} userDetails={auth} /> : ''}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                {src && (
                    <ImageCroper
                        src={src}
                        visible={visible}
                        onClose={(() => closePop())}
                        getCropImage={((data) => getCropImage(data))}
                    />
                )}
            </div>
        </>
    );
};

export default connect((state) => state)(ProfileSettings);