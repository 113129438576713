import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { LeftMenu } from "../../utils/menuData";
import ImageComponent from '../../component/Comman/ImageComponent';
import Loader from '../Comman/UserLoader'
import "./ProfileLeftMenu.css";
import image from "../images/story_bg.png";
import useHome from '../../hooks/useHome'
import {imageUrl} from '../../api/impUrl'

const ProfileLeftMenu = () => {
  const [friend, setFriends] = useState(false)
  const history = useHistory()
  const {auth} = useSelector((state)=>state.auth)
  const { follwersUsers} = useHome()


  useEffect(async () => {
    var subscription = await follwersUsers('all')
    if(subscription){
      await setFriends(subscription.userSubscriptions)
    }
          
  }, []);
  const changeUrl = (url) =>{
    history.push(url)
  }

  const gotoUserprofile = (username) =>{
      history.push(`/profile/${username}`);
  }

  const Menu = () => {
    return (
      <>
        <div className="leftSidebar">
        <div className="left_top">
            <div className="userImage_red" style={{ backgroundImage: `url(${imageUrl+auth?.profile_image})` }}>
            </div>
            <div className="user_namewrp">
                <div className="user_nameinner">{auth?.userFullName}</div>
            </div>
        </div>
          {LeftMenu.map((value, key) => {
            return (
              
              <List sx={{ mr: 2 }}>
                
                <ListItem button
                  key={key}
                  className="row"
                  id={window.location.pathname === value.url ? "active" : ""}
                  onClick={(() =>changeUrl(value.url))}
                >
                  <div className="leftMenu-Item">
                    <div className={`svgLeftsidebar svgFor_${value.name}`} dangerouslySetInnerHTML={{ __html: value.Image }} />
                    <div id="name">{value.name}</div></div>
                </ListItem>
              </List>
            );
          })}
        </div>
        {/* <Divider className="borderblueClass" /> */}
        <div className="leftSidebarHeading leftSidebarHeadingFlex">
          <h1>My Subscriptions</h1>
          <svg width='32' height='31' viewBox='0 0 32 31' fill='none' xmlns='http://www.w3.org/2000/svg'> <path fill-rule='evenodd' clip-rule='evenodd' d='M23.7883 0.930542C21.0449 0.930542 18.6215 2.44553 17.1733 4.75397C17.1733 4.75397 16.6843 5.5589 15.9087 5.5589C15.1339 5.5589 14.6449 4.75397 14.6449 4.75397C13.196 2.44553 10.7739 0.930542 8.02984 0.930542C3.59479 0.930542 0 4.88481 0 9.7649C0 10.9172 0.205893 12.0137 0.57038 13.0229C2.8199 20.7065 12.8809 29.7398 15.9087 30.0695C18.9373 29.7398 28.9969 20.7065 31.2478 13.0229C31.6123 12.0144 31.8182 10.9172 31.8182 9.7649C31.8182 4.88481 28.2234 0.930542 23.7883 0.930542Z' fill='#C4C4C4' /> </svg>
        </div>
        <div className="SubscriptionsMenuItems">
         
              {friend ? friend.map((value, key) => (
                <div className="SubscriptionsMenuItem">
                  <a href="/m.kushwah33">
                    <ImageComponent url={value?.subscribeTo?.profile_image} />
                  </a>
                  <a onClick={(() => history.push(`/profile/${value?.subscribeTo?.username}`))}>@{value?.subscribeTo?.username}</a>
                </div>
              )):<Loader/>}
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        {Menu()}
      </div>
    </>
  );
};

export default ProfileLeftMenu;
