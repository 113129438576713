import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getIn, Field, Formik, Form, ErrorMessage } from "formik";
import { subscriptionPackageSchea } from "../../../../utils/Validations";
import InputGroup from "../../../Comman/InputComponent";

const PackageForm = ({ createPlan, planDetails }) => {
    const [formData, setFormdata] = useState([]);
    const [basicBenefit, setbasicBenefit] = useState([]);
    const [standardBenefit, setstandardBenefit] = useState([]);
    const [premiumBenefit, setpremiumBenefit] = useState([]);
    const [addMore1, setAddMore1] = useState(['basic0', 'basic1']);
    const [addMore2, setAddMore2] = useState(['std0', 'std1'])
    const [addMore3, setAddMore3] = useState(['premium0', 'premium1'])

    useEffect(async () => {
        await setFormdata(planDetails);
        await setAddMore1(planDetails[0]?.benefits?.length > 0 ? planDetails[0].benefits:[...addMore1]);
        await setAddMore2(planDetails[1]?.benefits?.length > 0 ? planDetails[1].benefits:[...addMore2]);
        await setAddMore3(planDetails[2]?.benefits?.length > 0 ? planDetails[2].benefits:[...addMore3]);
    }, [planDetails]);

    const initialValues = {
        basic: {
            packageType: "basic",
            packageName: planDetails ? planDetails[0]?.packageName : "",
            weeklyPrice: planDetails ? planDetails[0]?.weeklyPrice : "",
            monthlyPrice: planDetails ? planDetails[0]?.monthlyPrice : "",
            yearlyPrice: planDetails ? planDetails[0]?.yearlyPrice : "",
            status: planDetails ? planDetails[0]?.status : false,
            benefits: planDetails && planDetails[0]?.benefits?.length > 0 ? planDetails[0]?.benefits : basicBenefit,
            currency: "US dollar"
        },
        standard: {
            packageType: "standard",
            packageName: planDetails ? planDetails[1]?.packageName : "",
            weeklyPrice: planDetails ? planDetails[1]?.weeklyPrice : "",
            monthlyPrice: planDetails ? planDetails[1]?.monthlyPrice : "",
            yearlyPrice: planDetails ? planDetails[1]?.yearlyPrice : "",
            status: planDetails ? planDetails[1]?.status : false,
            benefits: planDetails && planDetails[1]?.benefits?.length > 0 ? planDetails[1]?.benefits : standardBenefit,
            currency: "US dollar"
        },
        premium: {
            packageType: "premium",
            packageName: planDetails ? planDetails[2]?.packageName : "",
            weeklyPrice: planDetails ? planDetails[2]?.weeklyPrice : "",
            monthlyPrice: planDetails ? planDetails[2]?.monthlyPrice : "",
            yearlyPrice: planDetails ? planDetails[2]?.yearlyPrice : "",
            status: planDetails ? planDetails[2]?.status : false,
            benefits: planDetails && planDetails[2]?.benefits?.length > 0 ? planDetails[2]?.benefits : premiumBenefit,
            currency: "US dollar"
        },
    }

    const addMoreRows = (field, value, setter) => {
        if (value.length < 5) {
            let array = [...value]

            switch (field) {
                case 'basic':
                    array.push(`basic${array.length}`)
                    break;

                case 'standard':
                    array.push(`standard${array.length}`)
                    break;

                case 'premium':
                    array.push(`premium${array.length}`)
                    break;
                default:
                    break;
            }

            setter(array)
        }
    }

    const deletRows = (number, arr, setter) => {
        if (arr.length > 2) {
            let array = [...arr];
            array.splice(number, 1)
            setter(array);
        }
    }

    const AddButton = ({ field, value, setter }) => {
        return (<button onClick={() => { addMoreRows(field, value, setter) }}>
            Add more
        </button>)
    }

    const DeleteButton = ({ number, arr, setter }) => {
        return (<button onClick={() => { deletRows(number, arr, setter) }}>
            Delete
        </button>)
    }
    
    return (
        <>
            <div>
                {formData ?
                    <Formik
                        initialValues={initialValues}
                        validationSchema={subscriptionPackageSchea}
                        enableReinitialize={true}
                        onSubmit={(values, { resetForm }) => {
                            createPlan(values)
                            resetForm()
                        }}
                    >
                        {({ errors, touched, values, setFieldValue }) => (
                            <Form className="form-group">
                                <br></br>
                                <div className="mt-5">
                                    <div className="col-md-4 form-group error-div">
                                        <InputGroup type="checkbox" label="basic status" name="basic.status" defaultChecked={values?.basic?.status} />
                                        <InputGroup name="basic.packageName" />
                                        <InputGroup type="number" name="basic.weeklyPrice" />
                                        <InputGroup type="number" name="basic.monthlyPrice" />
                                        <InputGroup type="number" name="basic.yearlyPrice" />
                                        <AddButton field='basic' value={addMore1} setter={setAddMore1} />
                                        {addMore1?.map((value, index) => {
                                            return (
                                                <>
                                                    <InputGroup
                                                        key={index}
                                                        name={`basic.benefits[${index}]`}
                                                        onChange={(e) => {
                                                            e !== "" && setbasicBenefit([
                                                                ...basicBenefit,
                                                                e
                                                            ])
                                                        }}
                                                    />
                                                    {index > 1 &&
                                                        <DeleteButton
                                                            key={index}
                                                            number={index}
                                                            arr={addMore1}
                                                            setter={setAddMore1}
                                                        />
                                                    }
                                                </>
                                            )
                                        })}

                                    </div>

                                    <div className="col-md-4 form-group error-div">
                                        <InputGroup type="checkbox" label="standard status" name="standard.status" value={values?.standard?.status} />
                                        <InputGroup name="standard.packageName" />
                                        <InputGroup type="number" name="standard.weeklyPrice" />
                                        <InputGroup type="number" name="standard.monthlyPrice" />
                                        <InputGroup type="number" name="standard.yearlyPrice" />
                                        <AddButton field='standard' value={addMore2} setter={setAddMore2} />
                                        {addMore2?.map((value, index) => {
                                            return (
                                                <>
                                                    <InputGroup
                                                        key={index}
                                                        name={`standard.benefits[${index}]`}
                                                        onChange={(e) => {
                                                            e !== "" && setstandardBenefit([
                                                                ...standardBenefit,
                                                                e
                                                            ])
                                                        }}
                                                    />
                                                    {index > 1 &&
                                                        <DeleteButton
                                                            key={index}
                                                            number={index}
                                                            arr={addMore2}
                                                            setter={setAddMore2}
                                                        />
                                                    }
                                                </>
                                            )
                                        })}
                                    </div>

                                    <div className="col-md-4 form-group error-div">
                                        <InputGroup type="checkbox" label="Premium status" name="premium.status" value={values?.premium?.status} />
                                        <InputGroup name="premium.packageName" />
                                        <InputGroup type="number" name="premium.weeklyPrice" />
                                        <InputGroup type="number" name="premium.monthlyPrice" />
                                        <InputGroup type="number" name="premium.yearlyPrice" />
                                        <AddButton field='premium' value={addMore3} setter={setAddMore3} />
                                        {addMore3?.map((value, index) => {
                                            return (
                                                <>
                                                    <InputGroup
                                                        key={index}
                                                        name={`premium.benefits[${index}]`}
                                                        onChange={(e) => {
                                                            e !== "" && setpremiumBenefit([
                                                                ...premiumBenefit,
                                                                e
                                                            ])
                                                        }}
                                                    />
                                                    {index > 1 &&
                                                        <DeleteButton
                                                            key={index}
                                                            number={index}
                                                            arr={addMore3}
                                                            setter={setAddMore3}
                                                        />
                                                    }
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="settingSubmit"><button type="submit">Save</button></div>
                            </Form>
                        )}
                    </Formik>
                    : ''}
            </div>
        </>
    );
};

export default connect((state) => state)(PackageForm);