import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import LeftMenu from "../LeftMenu";
import { connect, useSelector } from "react-redux";
import useDashboard from "../../../hooks/useDashboard";
import useUser from "../../../hooks/useUser";
import BasicTable from "../../Comman/Table";
import { useHistory } from "react-router-dom";
import "../settings.css";
import { SubscribtionPaymentColumns } from "../../../utils/constants";

function SubscriptionPayment() {
  const [rows, setrows] = useState([]);
  const [reload, setRelaod] = useState(false);
  const { auth } = useSelector((state) => state.auth);
  const { isLogin } = useUser();
  const [page, setPage] = useState(1);
  const [subscriptionHistory, setSubcriptionHistory] = useState([]);
  const { getSubscriptionHistory } = useDashboard();
  let history = useHistory();

  useEffect(async () => {
    await authCheck();
    let res = await getSubscriptionHistory(`?filter=inactive`);
    setSubcriptionHistory(res.data);
  }, [reload, page]);

  const authCheck = async () => {
    var response = await isLogin();
    if (parseInt(response.status) === 401) {
      history.push("/login");
    }
  };

  const createRows = (rows) => {
    return rows?.map((row) => {
      return {
        id: row.name,
        amount: row.cryptoAmount,
        startedAt: row.createdAt,
        finalEarning: row.earning,
        currentStatus: row.paymentStatus,
      };
    });
  };

  return (
    <div className="container">
      <div className="wrapHomeSection">
        <div className="leftSideMenu">
          <LeftMenu />
        </div>
        <div className="settingArea">
          <Grid container>
            <Grid item xs={12}>
              <div className="bannerImageSection">
                <div className="bannerText">
                  <pre>
                    You can select Your default Withdrawal Option. No Platforms
                    commission is Charged if Payout is selected in Evergrow
                    Coin.
                  </pre>
                </div>
              </div>
              <div className="settingFormSection">
                {auth && (
                  <BasicTable
                    rows={createRows(subscriptionHistory?.payments)}
                    columns={SubscribtionPaymentColumns}
                    pageData={{
                      total: subscriptionHistory?.total,
                      page: subscriptionHistory?.page,
                      pageSet: setPage,
                    }}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
export default SubscriptionPayment;
