import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import CreatePostComponent from "../Home/Middle/Post/CreatePostComponent";
import useUser from "../../hooks/useUser";
import useChat from "../../hooks/useChat";
import useHome from "../../hooks/useHome";
import PostComponent from "../Home/Middle/Post/PostComponent";
import Navbar from "../Header/headerComponent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import ShareIcon from "@mui/icons-material/Share";
import EditRoadIcon from "@mui/icons-material/EditRoad";
import ImageComponent from "../Comman/ImageComponent";
import PackageCard from "./ourprofile/PackageCard";
import Skeleton from "@mui/material/Skeleton";
import "./OurProfile.css";
import {
  onImageError,
  getFileExtention,
  isValidURL,
} from "../../utils/helpers";
import { videoFormate, docsFormate } from "../../constants/fileExtentions.js";
import Popup from "../Comman/Popup";
import Button from "@material-ui/core/Button";

const OurProfile = (props) => {
  const [post, setPost] = useState([]);
  const [packages, setPackages] = useState([]);
  const [postCount, setPostCount] = useState(1);
  const [userInfo, setUserInfo] = useState({});
  const [message, setMessage] = useState("");
  const [chatFile, setChatFile] = useState("");
  const [visible, setVisible] = useState(false);
  const [html, setHtml] = useState("");
  const [title, setTitle] = useState("");

  const { auth } = useSelector((state) => state.auth);
  let history = useHistory();
  const { isLogin, GetUserProfile } = useUser();
  const { saveUserChats } = useChat();
  const {
    getPost,
    CreatePost,
    EditPost,
    DeletePost,
    GetUserPackages,
    ChangeWhoCanSee,
  } = useHome();

  useEffect(async () => {
    await authCheck();
    if (props) {
      var { user, userPosts } = await GetUserProfile(
        props.match.params.username
      );
      await setPost(userPosts.posts);
      await authCheck(user);
    } else {
      var post = await getPost();
      await setPost(post.posts);
    }
  }, [history]);

  useEffect(() => {
    localStorage.setItem("chatMessage", message);
  }, [message]);

  const authCheck = async (user = {}) => {
    var response = await isLogin();
    if (parseInt(response.status) === 401) {
      history.push("/login");
    } else {
      if (user) {
        await setUserInfo(user);
        console.log("user", user);
        var packageList = await GetUserPackages(user._id);
        await setPackages(packageList);
        console.log("packageList", packageList);
      }
      //history.push("/");
    }
  };
  const createPost = async (event, fileObj) => {
    event.preventDefault();
    const formData = new FormData();
    if (fileObj) {
      formData.append("postFile", fileObj.file);
    }
    formData.append("postText", event.target.post.value);
    formData.append("whoCanSee", event.target.who_can_see.value);
    formData.append("urlSlug", event.target.post.value.replace(" ", "-"));
    formData.append("postCreatorIp", 1234);
    formData.append("postWantStatus", "normal");
    formData.append("postStatus", 1);
    formData.append("commentStatus", 1);

    var response = await CreatePost(formData);
    if (response) {
      post.unshift(response);
      await setPostCount(Math.floor(Math.random() * 100));
      await setPost(post);
    }
  };

  const updatePost = async (data) => {
    var response = await EditPost(data.name, data.id);
    if (response) {
      var updatedPost = post.filter((el) => {
        if (el._id !== data.id) {
          return el;
        }
      });
      updatedPost.unshift(response);
      await setPostCount(Math.floor(Math.random() * 100));
      await setPost(updatedPost);
      return response;
    }
  };

  const deletePost = async (id) => {
    var response = await DeletePost(id);
    if (response) {
      var updatedPost = post.filter((el) => {
        if (el._id !== id) {
          return el;
        }
      });
      await setPostCount(Math.floor(Math.random() * 100));
      await setPost(updatedPost);
      return response;
    }
  };

  const updateWhoCanSee = async (data) => {
    await ChangeWhoCanSee(data);
  };

  const updatePostData = async () => {
    var post = await getPost();
    await setPost(post.posts);
  };

  const saveChat = async () => {
    try {
      const text = localStorage.getItem("chatMessage");
      console.log("saveChat...", text);
      let payload;
      let multipart = false;
      const loginUserId = auth?._id;
      if (!chatFile) {
        if (text == "") {
          console.log("message is empty");
          return false;
        }
        payload = {
          sender: loginUserId,
          receiver: userInfo._id,
          message: text,
          type: isValidURL(text) ? "link" : "text",
        };
      } else {
        multipart = true;
        payload = new FormData();
        payload.append("sender", loginUserId);
        payload.append("receiver", userInfo._id);
        payload.append("message", text);
        payload.append("file", chatFile);
        const fileExt = getFileExtention(chatFile.name);
        if (videoFormate.includes(fileExt)) {
          payload.append("type", "video");
        } else if (docsFormate.includes(fileExt)) {
          payload.append("type", "file");
        } else {
          payload.append("type", "image");
        }
      }
      const res = await saveUserChats(payload, multipart);
      setMessage("");
      localStorage.setItem("chatMessage", "");
      history.push(`/chat/${res.data._id}`);
    } catch (error) {
      console.log("saveChat err:", error);
    }
  };

  const createChatPopup = async () => {
    setVisible(false);
    setVisible(true);
    setTitle("Send new message");
    var html = (
      <div>
        <div className="form">
          <form>
            <div className="col-md-4 form-group error-div">
              <div className="formLabel">
                <textarea
                  name="message"
                  className="commentTextarea"
                  placeholder="Say hii..."
                  style={{ marginTop: 0, borderRadius: 20 }}
                  onChange={(event) => setMessage(event.target.value)}
                >
                  {message}
                </textarea>
              </div>
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-md-4 form-group error-div"
            >
              <Button
                sx={{ borderRadius: "20%" }}
                fullWidth={false}
                variant="contained"
                background="#3274e4"
                onClick={saveChat}
              >
                Send
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
    setHtml(html);
  };

  const closePop = async () => {
    await setVisible(false);
  };

  return (
    <>
      <nav>
        <div className="headerSection">
          <Navbar />
        </div>
      </nav>
      <div className="ProfileBannersection">
        <ImageComponent
          url={
            userInfo
              ? userInfo.cover_image
              : "https://via.placeholder.com/920X550&text=User%20profile%20banner"
          }
        />
        <PhotoCameraIcon className="cameraIconBanner" />
      </div>
      <div className="userProfileInfoarea">
        <div className="userProfileInfocolumns">
          <div className="userProfileInfocolumn1">
            <div className="userProfileMain">
              <span className="userProfileCamera">
                <PhotoCameraIcon className="userProfileCameraIcon" />
              </span>
              <ImageComponent
                classname={"userProfileImg"}
                url={
                  userInfo
                    ? userInfo.profile_image
                    : "https://via.placeholder.com/150X150/09f/fff&text=User"
                }
              />
            </div>
            <div className="userNameDatainfo">
              <h2>{userInfo.name}</h2>
              <h3>{userInfo.username}</h3>
            </div>
          </div>
          <div className="userProfileInfocolumn2 userProfileinfo1">
            <div className="userIdActions">
              <div className="addTrailerbtn">
                <p>@Music_4Ever</p>
                <a href="javascript:">Add trailer</a>
                <a
                  style={{
                    marginLeft: 10,
                    fontSize: 12,
                  }}
                  onClick={createChatPopup}
                >
                  Send Message
                </a>
              </div>
              <div className="Profileactions">
                <div className="Profileactionsbtns">
                  <StarBorderPurple500Icon />
                  <ShareIcon />
                </div>
                <div className="ProfileactionsText">
                  <p>
                    <strong>890</strong> Subscribers
                  </p>
                  <div className="editProfilebtn">
                    <EditRoadIcon /> Edit Profile
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="greyBackgroundColcor">
        <div className="container paddingTwenty">
          <div
            className="borderProfileTop"
            style={{
              borderTop: "1px solid #535353",
              width: "600px",
              margin: "0px auto",
              height: "0px",
              marginBottom: 15,
            }}
          ></div>
          <div className="wrapHomeSection">
            <div className="leftSideMenu greyBackgroundColcor">
              <div className="sidebarMenuitems">
                <ul className="sidebarMenuitemslist sidebarMenuitemslistMyprofile">
                  <li>How to promote yourself</li>
                  <li>How to build yor trailer</li>
                  <li>How to build your profile</li>
                  <li>Helpful Resources</li>
                  <li>Crator Blog</li>
                </ul>
              </div>
              <div className="importLinkmenu my_profileImpLinks">
                <div>
                  <a>Privacy Policies</a>
                  <a>Crator© 2022</a>
                </div>
                <div>
                  <a>Terms Of Use</a>
                  <a>Cookies</a>
                </div>
              </div>
            </div>
            <div className="middleSection">
              <div>
                <CreatePostComponent
                  packages={packages}
                  createPost={(event, fileObj) => createPost(event, fileObj)}
                />
              </div>
              <div className="postArea">
                <Grid
                  container
                  style={{ width: "100%", margin: "0 auto" }}
                  margin="dense"
                >
                  <Grid item xs={12}>
                    <Paper
                      variant="outlined"
                      style={{ height: 100, margin: 0 }}
                    >
                      {post && postCount > 0 ? (
                        <PostComponent
                          updatePostData={() => updatePostData()}
                          updateWhoCanSee={(data) => updateWhoCanSee(data)}
                          packages={packages}
                          deletePost={(id) => deletePost(id)}
                          updatePost={(event) => updatePost(event)}
                          posts={post}
                        />
                      ) : (
                        "Loading..."
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="greyBackgroundColcor">
              <div className="">
                {packages.length > 0 ? (
                  packages.map((value, key) => (
                    <>
                      <PackageCard userInfo={userInfo} userpackage={value} />
                    </>
                  ))
                ) : (
                  <Skeleton
                    sx={{ bgcolor: "#fff" }}
                    variant="rectangular"
                    width={210}
                    height={118}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup
        visible={visible}
        component={html}
        onClose={() => closePop()}
        title={title}
      />
    </>
  );
};

export default connect((state) => state.auth)(OurProfile);
