import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import LeftMenu from "../LeftMenu";
import { connect, useSelector } from "react-redux";
import useDashboard from '../../../hooks/useDashboard'
import Navbar from '../../Header/headerComponent'
import useUser from "../../../hooks/useUser";
import BasicTable from '../../Comman/Table';
import { useHistory } from "react-router-dom";
import '../settings.css'


function PaymentHistory() {
    const [rows, setrows] = useState([]);
    const [reload, setRelaod] = useState(false);
    const { auth } = useSelector((state) => state.auth);
    const { isLogin } = useUser();
    const [page, setPage] = useState(1)
    const [paymentHistory, setpaymentHistory] = useState([]);
    const { getPaymentHistory } = useDashboard();
    let history = useHistory();

    useEffect(async () => {
        await authCheck();
        let res = await getPaymentHistory(`?page=${page}`);
        setpaymentHistory(res.data);
    }, [reload, page]);

    const authCheck = async () => {
        var response = await isLogin();
        if (parseInt(response.status) === 401) {
            history.push("/login");
        }
    };

    const columns = [
        { id: 'cryptoAmount', label: 'Amount', minWidth: 80 },
        { id: 'date', label: 'Date', minWidth: 80, align: 'centre', format: (value) => value.toLocaleString('en-US') },
        { id: 'method', label: 'Payment Method', minWidth: 80 },
        { id: 'type', label: 'Type', minWidth: 80 },
        { id: 'status', label: 'Current Status', minWidth: 80 },
    ];


    return (
        <>
            <nav>
                <div className="headerSection">
                    <Navbar />
                </div>
            </nav>
            <div className="container">
                <div className="wrapHomeSection">
                    <div className="leftSideMenu">
                        <LeftMenu />
                    </div>
                    <div className="settingArea">
                        <Grid container>
                            <Grid item xs={12}>
                                <div className="bannerImageSection">
                                    <div className="bannerText">
                                        <pre>You can select Your default Withdrawal Option. No Platforms commission is Charged if Payout is selected in
                                            Evergrow Coin.</pre>
                                    </div>
                                </div>
                                <div className="settingFormSection">
                                    {auth && <BasicTable
                                        rows={paymentHistory?.payments}
                                        columns={columns}
                                        pageData={{
                                            total: paymentHistory?.total,
                                            page: paymentHistory?.page,
                                            perPage: 5,
                                            pageSet: setPage
                                        }}
                                    />}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    );
}
export default PaymentHistory