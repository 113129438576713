import  React,{useState} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography'
import PopupPackageDetails from './PopupPackageDetails';
import Popup from '../../Comman/Popup';

const PackageCard = ({ userpackage,userInfo }) => {
    const [visible, setVisible] = useState(false)
    const [html, setHtml] = useState('')
    const [title, setTitle] = useState('')

    const packageDetails = (userpackage) =>{
        var html = (
            <PopupPackageDetails closePop={(()=>closePop())} userInfo={userInfo} userpackage={userpackage}/>
        );

        setVisible(true)
        setTitle('Package Details')
        setHtml(html)
    }

    const closePop = async () => {
        await setVisible(false)
    }

    return (
        <Card sx={{ minWidth: 275,mb:2,ml:2 }}>
            <CardContent>
                <Typography variant="h5" component="div">
                    {userpackage.packageName}
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    background="#3274e4"
                    onClick={(()=>packageDetails(userpackage))}
                  
                >
                    ${userpackage.weeklyPrice}
                </Button>
            </CardActions>
            <Popup visible={visible} component={html} onClose={(() => closePop())} title={title} />
        </Card>
    );
}

export default PackageCard;