import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import SettingsApplicationsSharpIcon from "@mui/icons-material/SettingsApplicationsSharp";
import DiamondRoundedIcon from "@mui/icons-material/DiamondRounded";
import BookmarksTwoToneIcon from "@mui/icons-material/BookmarksTwoTone";
import Subscriptions from "../component/Icon/Group (3).png";
import Heart from "../component/Icon/heart.png";
import Insideheart from "../component/Icon/insideheart.png";
import Bookmark from "../component/Icon/bookmark.png";
import Dashboard from "../component/Icon/dash.png";
import Setting from "../component/Icon/setting.png";
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';

export const LeftMenu = [
  {
    name: "Subscriptions",
    url: "/subscription",
    Image: "<svg width='32' height='31' viewBox='0 0 32 31' fill='none' xmlns='http://www.w3.org/2000/svg'> <path fill-rule='evenodd' clip-rule='evenodd' d='M23.7883 0.930542C21.0449 0.930542 18.6215 2.44553 17.1733 4.75397C17.1733 4.75397 16.6843 5.5589 15.9087 5.5589C15.1339 5.5589 14.6449 4.75397 14.6449 4.75397C13.196 2.44553 10.7739 0.930542 8.02984 0.930542C3.59479 0.930542 0 4.88481 0 9.7649C0 10.9172 0.205893 12.0137 0.57038 13.0229C2.8199 20.7065 12.8809 29.7398 15.9087 30.0695C18.9373 29.7398 28.9969 20.7065 31.2478 13.0229C31.6123 12.0144 31.8182 10.9172 31.8182 9.7649C31.8182 4.88481 28.2234 0.930542 23.7883 0.930542Z' fill='#C4C4C4'/> </svg>",
  },
  {
    name: "Subscribers",
    url: "/subscriber",
    Image: "<svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'> <path fill-rule='evenodd' clip-rule='evenodd' d='M0.28125 14.5C0.28125 6.65985 6.65987 0.28125 14.5 0.28125C22.3401 0.28125 28.7188 6.65985 28.7188 14.5C28.7188 22.3401 22.3401 28.7188 14.5 28.7188C6.65987 28.7188 0.28125 22.3401 0.28125 14.5ZM14.5 21.9054C16.8926 20.1943 19.0247 18.2721 19.6058 17.5639C20.9723 15.8979 21.6278 14.187 21.6087 12.3351C21.5875 10.2119 19.8847 8.48438 17.8127 8.48438C16.306 8.48438 15.252 9.32452 14.6552 10.0396L14.5 10.2275L14.3448 10.0396C13.7371 9.33203 12.6912 8.4837 11.1873 8.4837C9.11533 8.4837 7.41249 10.2111 7.3913 12.3344C7.37216 14.187 8.02704 15.8979 9.39423 17.5639C9.97528 18.2721 12.1074 20.1964 14.5 21.9054Z' fill='#C4C4C4'/> </svg>",
  },
  {
    name: "Bookmarks",
    url: "/bookmark",
    Image: "<svg width='19' height='35' viewBox='0 0 19 35' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M18.1022 0H0.895452C0.614078 0 0.383972 0.230106 0.383972 0.509095V34.4921C0.383972 34.6984 0.506775 34.882 0.701113 34.963C0.763111 34.9893 0.828685 34.9988 0.895452 34.9988C1.02779 34.9988 1.15656 34.9487 1.25671 34.8498L9.50119 26.6113L17.7433 34.8498C17.8887 34.9976 18.1034 35.0382 18.2989 34.9619C18.4908 34.8808 18.616 34.6972 18.616 34.4909V0.509095C18.6148 0.230106 18.3823 0 18.1022 0ZM13.2389 9.7205H5.75988V6.88054H13.2389V9.7205Z' fill='#C4C4C4'/> </svg>",
  },
  {
    name: "Dashboard",
    url: "/dashboard",
    Image: "<svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M31.4763 31.4014V5.20334H24.3071V31.4023H21.9186V12.1432H14.7502V31.4014H12.6624V17.5H5.49409V31.4023H2.63326V0.964478H0V31.4014V34.0347H1.48279C1.86658 34.0347 2.25037 34.0347 2.63326 34.0347C12.8855 34.0347 23.1377 34.0347 33.3917 34.0347H35V31.4014H31.4763Z' fill='#C4C4C4'/> </svg>",
  },
  {
    name: "Premium",
    url: "/premium_post",
    Image: "<svg width='35' height='33' viewBox='0 0 35 33' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M10.9759 0.364868H5.11638L0.0857544 7.11048H8.83218L10.9759 0.364868Z' fill='#C4C4C4'/> <path d='M24.5529 7.11048L22.4163 0.364868H12.5837L10.4185 7.11048H24.5529Z' fill='#C4C4C4'/> <path d='M0 8.63965L15.4206 30.9416L8.80359 8.63965H0Z' fill='#C4C4C4'/> <path d='M34.9142 7.11048L29.8836 0.364868H23.9955L26.1678 7.11048H34.9142Z' fill='#C4C4C4'/> <path d='M10.4185 8.63965L17.5 32.6352L24.5814 8.63965H10.4185Z' fill='#C4C4C4'/> <path d='M26.1964 8.63965L19.5794 30.9416L35 8.63965H26.1964Z' fill='#C4C4C4'/> </svg>",
  },
  {
    name: "Settings",
    url: "/settings",
    Image: "<svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'> <path fill-rule='evenodd' clip-rule='evenodd' d='M31.442 13.7909H32.7639C33.9988 13.7909 34.9999 14.792 35 16.0268V18.9733C35 20.2081 33.9989 21.2092 32.764 21.2092H31.4422C31.1104 22.4591 30.6156 23.6426 29.9809 24.7349L30.9167 25.6708C31.3361 26.0901 31.5717 26.6588 31.5717 27.2518C31.5717 27.8449 31.3363 28.4135 30.9167 28.8328L28.8331 30.9165C27.96 31.7896 26.5443 31.7896 25.6712 30.9165L24.7353 29.9805C23.6429 30.6152 22.4596 31.1102 21.2096 31.4419V32.7641C21.2096 33.9991 20.2086 35.0001 18.9736 35.0001H16.027C14.7921 35.0001 13.791 33.9991 13.791 32.7641V31.4419C12.5412 31.1103 11.3578 30.6153 10.2655 29.9806L9.32963 30.9165C8.45647 31.7896 7.04083 31.7896 6.16768 30.9165L4.08404 28.8328C3.21089 27.9597 3.21089 26.544 4.08404 25.6709L5.01975 24.7352C4.38509 23.6428 3.8901 22.4593 3.55836 21.2094H2.23595C1.0012 21.2094 0.00012207 20.2083 0.00012207 18.9734V16.0269C0.00012207 14.792 1.0012 13.7909 2.23595 13.7909H3.55836C3.88996 12.5409 4.38496 11.3575 5.01961 10.2651L4.08404 9.32949C3.21089 8.45636 3.21089 7.04072 4.08404 6.16755L6.16755 4.08391C7.0407 3.21061 8.45634 3.21061 9.32949 4.08378L10.2653 5.01951C11.3576 4.38498 12.541 3.88998 13.7909 3.55836V2.23596C13.7909 1.00107 14.792 0 16.0269 0H18.9735C20.2084 0 21.2095 1.00107 21.2095 2.23596V3.55836C22.4595 3.89011 23.6427 4.38498 24.7352 5.01964L25.6709 4.08391C26.544 3.21077 27.9597 3.21077 28.8328 4.08391L30.9165 6.16755C31.7896 7.04072 31.7896 8.45636 30.9165 9.32949L29.9808 10.2652C30.6154 11.3576 31.1104 12.5409 31.442 13.7909ZM9.49716 17.5C9.49716 21.9127 13.0873 25.5028 17.5001 25.5028C21.913 25.5028 25.5031 21.9127 25.5031 17.5C25.5031 13.0871 21.913 9.49703 17.5001 9.49703C13.0873 9.49703 9.49716 13.0871 9.49716 17.5Z' fill='#C4C4C4'/> </svg>",
  },
];
export const adminLeftMenu = [
  {
    name: "Dashboard",
    url: "/admin",
    Image: "<svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M31.4763 31.4014V5.20334H24.3071V31.4023H21.9186V12.1432H14.7502V31.4014H12.6624V17.5H5.49409V31.4023H2.63326V0.964478H0V31.4014V34.0347H1.48279C1.86658 34.0347 2.25037 34.0347 2.63326 34.0347C12.8855 34.0347 23.1377 34.0347 33.3917 34.0347H35V31.4014H31.4763Z' fill='#C4C4C4'/> </svg>",
  },
  {
    name: "Manage Post",
    Image: "<svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'> <path fill-rule='evenodd' clip-rule='evenodd' d='M0.28125 14.5C0.28125 6.65985 6.65987 0.28125 14.5 0.28125C22.3401 0.28125 28.7188 6.65985 28.7188 14.5C28.7188 22.3401 22.3401 28.7188 14.5 28.7188C6.65987 28.7188 0.28125 22.3401 0.28125 14.5ZM14.5 21.9054C16.8926 20.1943 19.0247 18.2721 19.6058 17.5639C20.9723 15.8979 21.6278 14.187 21.6087 12.3351C21.5875 10.2119 19.8847 8.48438 17.8127 8.48438C16.306 8.48438 15.252 9.32452 14.6552 10.0396L14.5 10.2275L14.3448 10.0396C13.7371 9.33203 12.6912 8.4837 11.1873 8.4837C9.11533 8.4837 7.41249 10.2111 7.3913 12.3344C7.37216 14.187 8.02704 15.8979 9.39423 17.5639C9.97528 18.2721 12.1074 20.1964 14.5 21.9054Z' fill='#C4C4C4'/> </svg>",
  },
      {
        name: "Post",
        url: "post_list",
      },
      {
        name: "Premium Posts",
        url: "premiumPosts",
      },
  {
    name: "Settings",
    Image: "<svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'> <path fill-rule='evenodd' clip-rule='evenodd' d='M31.442 13.7909H32.7639C33.9988 13.7909 34.9999 14.792 35 16.0268V18.9733C35 20.2081 33.9989 21.2092 32.764 21.2092H31.4422C31.1104 22.4591 30.6156 23.6426 29.9809 24.7349L30.9167 25.6708C31.3361 26.0901 31.5717 26.6588 31.5717 27.2518C31.5717 27.8449 31.3363 28.4135 30.9167 28.8328L28.8331 30.9165C27.96 31.7896 26.5443 31.7896 25.6712 30.9165L24.7353 29.9805C23.6429 30.6152 22.4596 31.1102 21.2096 31.4419V32.7641C21.2096 33.9991 20.2086 35.0001 18.9736 35.0001H16.027C14.7921 35.0001 13.791 33.9991 13.791 32.7641V31.4419C12.5412 31.1103 11.3578 30.6153 10.2655 29.9806L9.32963 30.9165C8.45647 31.7896 7.04083 31.7896 6.16768 30.9165L4.08404 28.8328C3.21089 27.9597 3.21089 26.544 4.08404 25.6709L5.01975 24.7352C4.38509 23.6428 3.8901 22.4593 3.55836 21.2094H2.23595C1.0012 21.2094 0.00012207 20.2083 0.00012207 18.9734V16.0269C0.00012207 14.792 1.0012 13.7909 2.23595 13.7909H3.55836C3.88996 12.5409 4.38496 11.3575 5.01961 10.2651L4.08404 9.32949C3.21089 8.45636 3.21089 7.04072 4.08404 6.16755L6.16755 4.08391C7.0407 3.21061 8.45634 3.21061 9.32949 4.08378L10.2653 5.01951C11.3576 4.38498 12.541 3.88998 13.7909 3.55836V2.23596C13.7909 1.00107 14.792 0 16.0269 0H18.9735C20.2084 0 21.2095 1.00107 21.2095 2.23596V3.55836C22.4595 3.89011 23.6427 4.38498 24.7352 5.01964L25.6709 4.08391C26.544 3.21077 27.9597 3.21077 28.8328 4.08391L30.9165 6.16755C31.7896 7.04072 31.7896 8.45636 30.9165 9.32949L29.9808 10.2652C30.6154 11.3576 31.1104 12.5409 31.442 13.7909ZM9.49716 17.5C9.49716 21.9127 13.0873 25.5028 17.5001 25.5028C21.913 25.5028 25.5031 21.9127 25.5031 17.5C25.5031 13.0871 21.913 9.49703 17.5001 9.49703C13.0873 9.49703 9.49716 13.0871 9.49716 17.5Z' fill='#C4C4C4'/> </svg>",
  },
  {
    name: "Website Settings",
    url: "website_setting",
  },
  {
    name: "General",
    url: "website_general_setting",
  },
  {
    name: "Limit",
    url: "website_limit",
  },
  {
    name: "Billing Informations",
    url: "billing_informations",
  },
  {
    name: "Languages",
    url: "languages",
    Image: "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' role='img'><path fill='#8a96a3' d='M22.401 4.818h-9.927L10.927 0H1.599C.72 0 .002.719.002 1.599v16.275c0 .878.72 1.597 1.597 1.597h10L13.072 24H22.4c.878 0 1.597-.707 1.597-1.572V6.39c0-.865-.72-1.572-1.597-1.572zm-15.66 8.68c-2.07 0-3.75-1.68-3.75-3.75 0-2.07 1.68-3.75 3.75-3.75 1.012 0 1.86.375 2.512.976l-.99.952a2.194 2.194 0 0 0-1.522-.584c-1.305 0-2.363 1.08-2.363 2.409S5.436 12.16 6.74 12.16c1.507 0 2.13-1.08 2.19-1.808l-2.188-.002V9.066h3.51c.05.23.09.457.09.764 0 2.147-1.434 3.669-3.602 3.669zm16.757 8.93c0 .59-.492 1.072-1.097 1.072h-8.875l3.649-4.03h.005l-.74-2.302.006-.005s.568-.488 1.277-1.24c.712.771 1.63 1.699 2.818 2.805l.771-.772c-1.272-1.154-2.204-2.07-2.89-2.805.919-1.087 1.852-2.455 2.049-3.707h2.034v.002h.002v-.94h-4.532v-1.52h-1.471v1.52H14.3l-1.672-5.21.006.022h9.767c.605 0 1.097.48 1.097 1.072v16.038zm-6.484-7.311c-.536.548-.943.873-.943.873l-.008.004-1.46-4.548h4.764c-.307 1.084-.988 2.108-1.651 2.904-1.176-1.392-1.18-1.844-1.18-1.844h-1.222s.05.678 1.7 2.61z'></path> </svg>",
  },
  {
    name: "Users",
    Image: "<svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'> <path fill-rule='evenodd' clip-rule='evenodd' d='M0.28125 14.5C0.28125 6.65985 6.65987 0.28125 14.5 0.28125C22.3401 0.28125 28.7188 6.65985 28.7188 14.5C28.7188 22.3401 22.3401 28.7188 14.5 28.7188C6.65987 28.7188 0.28125 22.3401 0.28125 14.5ZM14.5 21.9054C16.8926 20.1943 19.0247 18.2721 19.6058 17.5639C20.9723 15.8979 21.6278 14.187 21.6087 12.3351C21.5875 10.2119 19.8847 8.48438 17.8127 8.48438C16.306 8.48438 15.252 9.32452 14.6552 10.0396L14.5 10.2275L14.3448 10.0396C13.7371 9.33203 12.6912 8.4837 11.1873 8.4837C9.11533 8.4837 7.41249 10.2111 7.3913 12.3344C7.37216 14.187 8.02704 15.8979 9.39423 17.5639C9.97528 18.2721 12.1074 20.1964 14.5 21.9054Z' fill='#C4C4C4'/> </svg>",
  },
  {
    name: "Manage User",
    url: "users",
  },
  {
    name: "Manage Funds",
    Image: "<svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'> <path fill-rule='evenodd' clip-rule='evenodd' d='M0.28125 14.5C0.28125 6.65985 6.65987 0.28125 14.5 0.28125C22.3401 0.28125 28.7188 6.65985 28.7188 14.5C28.7188 22.3401 22.3401 28.7188 14.5 28.7188C6.65987 28.7188 0.28125 22.3401 0.28125 14.5ZM14.5 21.9054C16.8926 20.1943 19.0247 18.2721 19.6058 17.5639C20.9723 15.8979 21.6278 14.187 21.6087 12.3351C21.5875 10.2119 19.8847 8.48438 17.8127 8.48438C16.306 8.48438 15.252 9.32452 14.6552 10.0396L14.5 10.2275L14.3448 10.0396C13.7371 9.33203 12.6912 8.4837 11.1873 8.4837C9.11533 8.4837 7.41249 10.2111 7.3913 12.3344C7.37216 14.187 8.02704 15.8979 9.39423 17.5639C9.97528 18.2721 12.1074 20.1964 14.5 21.9054Z' fill='#C4C4C4'/> </svg>",
  },
  {
    name: "Fund Package setting",
    url: "packages"
  },
  {
    name: "Payment Methods",
    Image: "<svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'> <path fill-rule='evenodd' clip-rule='evenodd' d='M0.28125 14.5C0.28125 6.65985 6.65987 0.28125 14.5 0.28125C22.3401 0.28125 28.7188 6.65985 28.7188 14.5C28.7188 22.3401 22.3401 28.7188 14.5 28.7188C6.65987 28.7188 0.28125 22.3401 0.28125 14.5ZM14.5 21.9054C16.8926 20.1943 19.0247 18.2721 19.6058 17.5639C20.9723 15.8979 21.6278 14.187 21.6087 12.3351C21.5875 10.2119 19.8847 8.48438 17.8127 8.48438C16.306 8.48438 15.252 9.32452 14.6552 10.0396L14.5 10.2275L14.3448 10.0396C13.7371 9.33203 12.6912 8.4837 11.1873 8.4837C9.11533 8.4837 7.41249 10.2111 7.3913 12.3344C7.37216 14.187 8.02704 15.8979 9.39423 17.5639C9.97528 18.2721 12.1074 20.1964 14.5 21.9054Z' fill='#C4C4C4'/> </svg>",
  },
  {
    name: "Stripe(Subscription)",
    url: "stripe_payment"
  },
];