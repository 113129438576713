import PaymentRepository from '../api/PaymentRepository'; 
import { useDispatch } from 'react-redux';

export default function usePayment() {
    const dispatch = useDispatch();
    return {
        createPayment:async(data)=>{
            var responseData = await PaymentRepository.CreatePayment(data);
            if(responseData.status === 201){
                return true
            }
            return false;
        },
        getPointsList:async()=>{
            var responseData = await PaymentRepository.GetPackage();
            if(responseData.status === 200){
                return responseData.data.data
            }
            return false;
        },

    }
}
