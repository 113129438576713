import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { isEmpty } from 'lodash'
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import useDashboard from '../../../hooks/useDashboard'
import Navbar from '../../Header/headerComponent';
import useUser from "../../../hooks/useUser";
import LeftMenu from "../LeftMenu";
import { useHistory } from "react-router-dom";
import '../settings.css'
import Card from "./Card";


const DashBoard = () => {
  const { auth } = useSelector((state) => state.auth);
  const { isLogin } = useUser()
  const { getDashBoardDetails } = useDashboard()
  const [dashboardData, setDashBoardData] = useState([]);
  const [reload, setReload] = useState(false);
  let history = useHistory();

  useEffect(async () => {
    await authCheck();
    let res = await getDashBoardDetails();
    setDashBoardData(res.data);
  }, [])

  const authCheck = async () => {
    var response = await isLogin();
    if (parseInt(response.status) === 401) {
      history.push("/login");
    }
  };

  return (
    <>
      <nav>
        <div className="headerSection">
          <Navbar />
        </div>
      </nav>
      <div className="container">
        <div className="wrapHomeSection">
          <div className="leftSideMenu">
            <LeftMenu />
          </div>
          <div className="settingArea">
            <div className="settingArea">
              <Grid container style={{ width: "90%", margin: "0 auto" }}>
                <Grid item xs={12}>
                  <Paper variant="outlined" style={{ height: 500, margin: 0 }}>
                    <div>
                      {!isEmpty(auth) && !isEmpty(dashboardData) ?
                        <Card
                          data={dashboardData}
                        /> : ''}

                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect((state) => state)(DashBoard);



