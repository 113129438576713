"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var TwitterLoginButton_1 = require("./TwitterLoginButton");
var window_1 = require("./services/window");
var oauth1_1 = require("./services/oauth1");
var TwitterLoginComponent = (function (_super) {
    __extends(TwitterLoginComponent, _super);
    function TwitterLoginComponent(props) {
        var _this = _super.call(this, props) || this;
        _this.initializeProcess = function () {
            if (window.opener) {
                var _a = window.location.search.match(/^(?=.*oauth_token=([^&]+)|)(?=.*oauth_verifier=([^&]+)|).+$/) || [], oauthToken = _a[1], oauthVerifier = _a[2];
                window.opener.postMessage({
                    type: "authorized",
                    data: {
                        oauthToken: oauthToken,
                        oauthVerifier: oauthVerifier
                    }
                }, window.origin);
            }
            else {
                var _b = _this.props, authCallback_1 = _b.authCallback, consumerKey_1 = _b.consumerKey, consumerSecret_1 = _b.consumerSecret;
                window.onmessage = function (_a) {
                    var _b = _a.data, type = _b.type, data = _b.data;
                    return __awaiter(_this, void 0, void 0, function () {
                        var accessTokenData_1, popup_1;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    if (!(type === "authorized")) return [3, 2];
                                    return [4, oauth1_1.obtainOauthAccessToken({
                                            apiUrl: "https://api.twitter.com/oauth/access_token",
                                            consumerKey: consumerKey_1,
                                            consumerSecret: consumerSecret_1,
                                            oauthToken: data.oauthToken,
                                            oauthVerifier: data.oauthVerifier,
                                            method: "POST"
                                        })];
                                case 1:
                                    accessTokenData_1 = _c.sent();
                                    popup_1 = this.state.popup;
                                    this.setState({
                                        isCompleted: true
                                    }, function () {
                                        authCallback_1 && authCallback_1(undefined, accessTokenData_1);
                                        popup_1 && popup_1.close();
                                    });
                                    _c.label = 2;
                                case 2: return [2];
                            }
                        });
                    });
                };
            }
        };
        _this.handleLoginClick = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, consumerKey, consumerSecret, callbackUrl, popup, obtainRequestTokenConfig, requestTokenData;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, consumerKey = _a.consumerKey, consumerSecret = _a.consumerSecret, callbackUrl = _a.callbackUrl;
                        popup = window_1.openWindow({
                            url: "",
                            name: "Log in with Twitter"
                        });
                        if (callbackUrl) {
                            console.warn("DEPRECATED: \"callbackUrl\" is not supported and ignored from version 1.2.0 and higher. It's hardcoded inside the package with \"window.location.href\". More details: https://github.com/alexandrtovmach/react-twitter-login/issues/8");
                        }
                        obtainRequestTokenConfig = {
                            apiUrl: "https://api.twitter.com/oauth/request_token",
                            callbackUrl: window.location.href,
                            consumerKey: consumerKey,
                            consumerSecret: consumerSecret,
                            method: "POST"
                        };
                        return [4, oauth1_1.obtainOauthRequestToken(obtainRequestTokenConfig)];
                    case 1:
                        requestTokenData = _b.sent();
                        if (requestTokenData.oauth_callback_confirmed === "true" &&
                            popup !== null) {
                            popup.location.href = "https://api.twitter.com/oauth/authorize?oauth_token=" + requestTokenData.oauth_token;
                            if (popup) {
                                window_1.observeWindow({ popup: popup, onClose: this.handleClosingPopup });
                                this.setState({
                                    popup: popup
                                });
                            }
                        }
                        else {
                            this.handleError("Callback URL \"" + window.location.href + "\" is not confirmed. Please check that is whitelisted within the Twitter app settings.");
                        }
                        return [2];
                }
            });
        }); };
        _this.handleClosingPopup = function () {
            var authCallback = _this.props.authCallback;
            var isCompleted = _this.state.isCompleted;
            if (!isCompleted) {
                authCallback && authCallback("User closed OAuth popup");
            }
        };
        _this.handleError = function (message) {
            var authCallback = _this.props.authCallback;
            authCallback(message);
        };
        _this.state = {
            isCompleted: false
        };
        return _this;
    }
    TwitterLoginComponent.prototype.componentDidMount = function () {
        this.initializeProcess();
    };
    TwitterLoginComponent.prototype.render = function () {
        var _a = this.props, buttonTheme = _a.buttonTheme, className = _a.className, children = _a.children;
        return children ? (React.createElement("div", { onClick: this.handleLoginClick, className: className }, children)) : (React.createElement(TwitterLoginButton_1.default, { buttonTheme: buttonTheme || "light", buttonClassName: className, onClick: this.handleLoginClick }));
    };
    return TwitterLoginComponent;
}(React.Component));
exports.default = TwitterLoginComponent;
