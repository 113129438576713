import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getIn, Field, Formik, Form, ErrorMessage } from "formik";
import InputGroup from "../../../Comman/InputComponent";

const EditUserForm = ({ editUser, details }) => {
    const [formData, setFormdata] = useState([]);

    useEffect(async () => {
        await setFormdata(details)
    }, [details]);

    const initialValues = {
        userVerifiedStatus: formData?.userVerifiedStatus,
        userType: formData?.userType,
        walletPoints: formData?.walletPoints
    }

    return (
        <>
            <div>
                {formData ?
                    <Formik
                        initialValues={initialValues}
                        // validationSchema={schmea}
                        enableReinitialize={true}
                        onSubmit={(values, { resetForm }) => {
                            editUser(values)
                        }}
                    >
                        {({ errors, touched, values, setFieldValue }) => (
                            <Form className="form-group">
                                <br></br>
                                <div className="mt-5">
                                    <div className="col-md-4 form-group error-div">

                                        <label htmlFor="userVerifiedStatus" style={{ display: 'block' }}>
                                            Verified Status
                                        </label>
                                        <select
                                            name="userVerifiedStatus"
                                            value={values.userVerifiedStatus}
                                            onChange={(e) => setFieldValue('userVerifiedStatus', Number(e.target.value))}
                                            style={{ display: 'block' }}
                                        >
                                            <option value={1} label="Verified" />
                                            <option value={2} label="Verification Pending" />
                                            <option value={0} label="Not verified" />
                                        </select>
                                        {errors.userVerifiedStatus &&
                                            touched.userVerifiedStatus &&
                                            <div className="input-feedback">
                                                {errors.userVerifiedStatus}
                                            </div>
                                        }


                                        <label htmlFor="userType" style={{ display: 'block' }}>
                                            Verified Status
                                        </label>
                                        <select
                                            name="userType"
                                            value={values.userType}
                                            onChange={(e) => setFieldValue('userType', Number(e.target.value))}
                                            style={{ display: 'block' }}
                                        >
                                            <option value={1} label="Normal User" />
                                            <option value={2} label="Admin" />
                                            <option value={0} label="Moderator" />
                                        </select>
                                        {errors.userType &&
                                            touched.userType &&
                                            <div className="input-feedback">
                                                {errors.userType}
                                            </div>
                                        }
                                        <InputGroup type="text" name="walletPoints" />
                                    </div>
                                </div>
                                <div className="settingSubmit"><button type="submit">Save</button></div>
                            </Form>
                        )}
                    </Formik>
                    : ''}
            </div>
        </>
    );
};

export default connect((state) => state)(EditUserForm);