import axios from "./config";
const Api = axios
class AdminRepository {
  async GetAllUserCount() {
    const reponse = await Api.get(`admin/usersCount`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }
  async GetAllPostCount(params) {
    const reponse = await Api.get(`admin/postCount`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async getPremiumPostCount() {
    const reponse = await Api.get(`admin/premiumContent-earning`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async GetadminEarningCount(params) {
    const reponse = await Api.get(`admin/admin-earning`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async GetAdminSubscriptionEarning() {
    const reponse = await Api.get(`admin/subscription-earning`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async GetLastestUsers(params) {
    const reponse = await Api.get(`admin/latestUsers`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async GetLastestSubsriptions(params) {
    const reponse = await Api.get(`admin/latestSubscriptions`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async getUserListing() {
    const reponse = await Api.get(`admin/all-users`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async deleteAdminUser(id) {
    const reponse = await Api.delete(`admin/delete-user/${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async getUserDetails(id) {
    const reponse = await Api.get(`/admin/user-profile/${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async editUserDetails(data, id) {
    const reponse = await Api.put(`admin/all-users/${id}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async addUser(data) {
    const reponse = await Api.post(`admin/add-user`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async GetAllPostData(params) {
    const reponse = await Api.get(`admin/allPost`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }
  async updatePostData(params, id) {
    const reponse = await Api.put(`posts/${id}`, params)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }
  async postDeleteData(id) {
    const reponse = await Api.delete(`posts/${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }
  async posActiveDeactiveData(id, status) {
    const reponse = await Api.put(`posts/active_deactive/${id}`, {
      status,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }
  async webSiteSetting(data) {
    const reponse = await Api.put(`admin/website-setting/`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async webSiteSettingImg(data) {
    const reponse = await Api.post(`admin/website-setting/`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async webSiteGenSetting(data) {
    const reponse = await Api.put(`admin/general-setting/`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }
  async webLimitSetting(data) {
    const reponse = await Api.post(`admin/limit-setting/`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }
  async billingInformations(data) {
    const reponse = await Api.post(`admin/billing-info/`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }
  async createLanguagesData(data) {
    const reponse = await Api.post(`admin/add-lang/`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }
  //code for primium post.................
  async getAllPrimiumPostData() {
    const reponse = await Api.get(`admin/preimum-post`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async getAllPackages() {
    const reponse = await Api.get(`admin/all-package`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async deletePackages(id) {
    const reponse = await Api.delete(`admin/create-package/${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async editPackages(data, id) {
    const reponse = await Api.put(`admin/create-package/${id}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async createPackages(data) {
    const reponse = await Api.post(`admin/create-package`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async getPackageDetailById(id) {
    const reponse = await Api.get(`admin/create-package/${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async makeStripePayment(data) {
    const reponse = await Api.post(`admin/stripe-settings/create/`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }
}


export default new AdminRepository();
