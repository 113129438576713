import React from "react";
import {imageUrl} from '../../api/impUrl'
import {
    onImageError,
    getFileExtention,
    isValidURL,
  } from "../../utils/helpers";
const ImageComponent = ({url,classname,mystyle}) => {
    return(
        <>           
            <div>
                { <img 
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src="https://evergrowcoin.com/wp-content/uploads/2022/02/Crator_Mobile_welcome_HR-286x300.png";
                      }}
                    style={mystyle} 
                    className={classname} 
                    src={url !== '' ? imageUrl+url : 'https://evergrowcoin.com/wp-content/uploads/2022/02/Crator_Mobile_welcome_HR-286x300.png'}
                    />}
            </div>
        </>
    )
}

export default ImageComponent;