import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import LeftMenu from "../LeftMenu";
import { connect, useSelector } from "react-redux";
import useDashboard from '../../../hooks/useDashboard'
import useUser from "../../../hooks/useUser";
import BasicTable from '../../Comman/Table';
import { useHistory } from "react-router-dom";
import '../settings.css'
import { RecivedPaymentColumns } from "../../../utils/constants";


function RecivedPayment() {
    const [rows, setrows] = useState([]);
    const [reload, setRelaod] = useState(false);
    const { auth } = useSelector((state) => state.auth);
    const { isLogin } = useUser();
    const [page, setPage] = useState(1)
    const [paymentHistory, setpaymentHistory] = useState([]);
    const { getPaymentHistory } = useDashboard();
    let history = useHistory();

    useEffect(async () => {
        await authCheck();
        let res = await getPaymentHistory(`?page=${page}`);
        setpaymentHistory(res.data);
    }, [reload, page]);

    const authCheck = async () => {
        var response = await isLogin();
        if (parseInt(response.status) === 401) {
            history.push("/login");
        }
    };

    const createRows = (rows) => {
        return rows?.map((row) => {
            return {
                id: row._id,
                paidBy: row.cryptoAmount,
                PaymentType: row.createdAt,
                Date: row.paymentOption,
                Amount: row.paymentType,
                FinalEarnings: row.paymentStatus
            }
        })
    }

    return (
        <div className="container">
            <div className="wrapHomeSection">
                <div className="leftSideMenu">
                    <LeftMenu />
                </div>
                <div className="settingArea">
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="bannerImageSection">
                                <div className="bannerText">
                                    <pre>You can select Your default Withdrawal Option. No Platforms commission is Charged if Payout is selected in
                                        Evergrow Coin.</pre>
                                </div>
                            </div>
                            
                            <div className="settingFormSection">
                                {auth && <BasicTable
                                    rows={createRows(paymentHistory?.payments)}
                                    columns={RecivedPaymentColumns}
                                    pageData={{
                                        total: paymentHistory?.total,
                                        page: paymentHistory?.page,
                                        pageSet: setPage
                                    }}

                                />}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>

    );
}
export default RecivedPayment