import axios from "./config";
import cookies from "js-cookie";
var token = cookies.get("token");
// axios.defaults.headers.common["Authorization"] = "Bearer " + token;
class AuthRepository {
  async UserLogin(params) {
    console.log('mukesh in auth repo',axios)
    const reponse = await axios.post(`/users/login/`, params)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }

  async UserLoginWithGoogle(params) {
    try {
      const url = `/users/login-signup-with-google`;
      const response = await axios.post(url, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  async UserRegistration(params) {
    const reponse = await axios.post(`/users/signup/`, params)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async isLogin() {
    const token = cookies.get("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const reponse = await axios
      .get(`users/is-login/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async logout() {
    const reponse = await axios.post(`users/logout/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async uploadUserImage(data) {
    const reponse = await axios.post(`users/user-image/`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }

  async getUserPrifile(username) {
    const reponse = await axios.get(`users/get-profile/${username}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }
  async isValidUser(id) {
    const reponse = await axios.get(`users/is-valid-user/${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return reponse;
  }
  async subscriberCount() {
      const reponse = await axios.get(`friends/get-my-following/subscriber`)
          .then((response) => {
              return response;
          })
          .catch((error)=>{
              console.log(error.response)
              return(error.response)
          }); 
      return reponse    
  }
  async updateFollowerStatus(id,data) {
      const reponse = await axios.put(`friends/update-follow-status/${id}`,data)
          .then((response) => {
              return response;
          })
          .catch((error)=>{
              console.log(error.response)
              return(error.response)
          }); 
      return reponse    
  }
}
export default new AuthRepository();
