import Api, { baseUrl } from "./config";
import cookies from "js-cookie";

const token = cookies.get("token");
Api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

class NotificationRepository {
  async getAllNotifications(page = 1, limit = 20) {
    try {
      let url = `${baseUrl}notification/get-all`;
      url = `${url}?page=${page}&limit=${limit}`;
      const response = await Api.get(url);
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  async getOneNotification(id) {
    try {
      const url = `${baseUrl}notification/get-one/${id}`;
      const response = await Api.get(url);
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  async saveNotification(data) {
    try {
      const url = `${baseUrl}notification`;
      let headers = { "Content-Type": "multipart/form-data" };
      const response = await Api.post(url, data, { headers });
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  async updateNotification(id, data) {
    try {
      const url = `${baseUrl}notification/${id}`;
      const response = await Api.put(url, data);
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  async deleteNotification(id) {
    try {
      const url = `${baseUrl}notification/${id}`;
      const response = await Api.delete(url);
      return response;
    } catch (error) {
      throw error.response;
    }
  }
}

export default new NotificationRepository();
