import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import "./GeneralSettings.css";
import AdminLeftMenu from "../../adminLeftSideMenu/adminLeftMenuComponent";
import useAdminDashboard from "../../../../hooks/useAdminDashboard";
import useUser from "../../../../hooks/useUser";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        
      },
    },
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const GeneralSettings = (props) => {
  const { id } = useParams();
  const { auth } = useSelector((state) => state);
  const [allPostData, setAllPostData] = useState([]);

  const userDetail = auth.auth;
  let history = useHistory();
  const { isLogin } = useUser();
  const {
    webSiteGenSetting,
  } = useAdminDashboard();

  useEffect(async () => {
    await authCheck();

  }, [history]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const authCheck = async () => {
  var response = await isLogin();

    if (parseInt(response.status) === 401) {
      history.push("/login");
    } else {
      if (props) {
        return;
      }
      history.push("/");
    }
  };


  const onSelectLang = async (val, type) => {
   var data = {defaultLanguage:val};
   console.log(data)
      await webSiteGenSetting(data)
  };  
  const onMaintenanceMode = async (val, type) => {
      var data = { maintenanceMode:val}
      await webSiteGenSetting(data)

  };    
  const onEmailVerification = async (val, type) => {
      var data = { emailSendStatus : val}
      await webSiteGenSetting(data)

  };  
  const onSignUp = async (val, type) => {
      var data = { register:val}
      await webSiteGenSetting(data)

  };  
  const onIPLimit = async (val, type) => {
      var data = { ipLimit : val}
      await webSiteGenSetting(data)

  };  


  return (
    <>
      <nav>
        <div style={{ display: "flex" }}>
          <div className="sideBar">
            <AdminLeftMenu />
          </div>
          <div className="admin_main_wrp" style={{ width: "100%" }}>
              <div>
                  <h1>General Settings</h1>
                  <div className="col-md-4 form-group error-div">
                        <div className="formLabel">
                            <label>Main Language</label>
                        </div>
                        <div margin="dense" className="option_btn formField">
                                              <Button
                              id="demo-customized-button"
                              aria-controls={open ? 'demo-customized-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              variant="contained"
                              disableElevation
                              onClick={handleClick}
                              endIcon={<KeyboardArrowDownIcon />}
                            >
                              Select
                            </Button>
                            <StyledMenu
                              id="demo-customized-menu"
                              MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                            >
                              <MenuItem onClick={handleClose} disableRipple>
                                <MoreHorizIcon />
                                English
                              </MenuItem>
                            </StyledMenu>
                          
                        </div>
                        <select className="form-control" 
                                  name="defaultLanguage" 
                                  onChange={(val) => onSelectLang(val.target.value)} >
                              <option value="">Select</option>
                              <option value="English">English</option>
                          </select>
                  </div>
                  <div className="col-md-4 form-group error-div">
                        <div className="formLabel">
                          <leble for="MaintenanceModelbl">Maintenance Mode</leble>
                        </div>
                        <div className="formField">
                        <FormControlLabel  id="site_favicon" 
                                 type="checkbox" 
                                 name="maintenanceMode"  
                                 defaultValue={1}
                                 onClick={(val) => onMaintenanceMode(val.target.value)} 
                                 control={<Checkbox />} label="While your website is in maintenance mode, administrators and moderators, if any, can login. All other users will view the maintenance page." />
                        </div>
                  </div>
                  <div className="col-md-4 form-group error-div">
                        <div className="formLabel">
                          <leble for="emailVerificationlbl">Email Verification</leble>
                        </div>
                        <div className="formLabel">
                        <FormControlLabel
                                 id="emailVerification"  
                                 name="emailSendStatus"
                                 type="checkbox"
                                 defaultValue={1}
                                 onClick={(val) => onEmailVerification(val.target.value)}
                                 control={<Checkbox />} label="A confirmation mail is sent to newly registered users. There is a confirmation link sent by the system in the confirmation mail, when the member is clicked on this link, the user's account is activated." />
                      </div> 
                  </div> 
                  <div className="col-md-4 form-group error-div">
                        <div className="formLabel">
                          <leble for="signUplbl">Sign Up</leble>
                        </div>
                        <div className="formLabel">
                        <FormControlLabel 
                                 id="signUp"  
                                 name="register"
                                 type="checkbox"
                                 defaultValue={1}
                                 onClick={(val) => onSignUp(val.target.value)} 
                                 control={<Checkbox />} label="Allows registration of new members on your website." />
                          
                        </div>
                  </div>  
                  <div className="col-md-4 form-group error-div">
                        <div className="formLabel">
                          <leble for="iplimitlbl">IP Limit</leble>
                        </div>
                        <div className="formLabel">
                        <FormControlLabel 
                                 id="iplimit"  
                                 name="ipLimit"
                                 type="checkbox"
                                 defaultValue={1}
                                 onClick={(val) => onIPLimit(val.target.value)} 
                                 control={<Checkbox />} label="Allows registration of new members on your website." />
                          <span></span>
                        </div>
                  </div>
              </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default GeneralSettings;
