import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getIn, Field, Formik, Form, ErrorMessage } from "formik";
import InputGroup from "../../../Comman/InputComponent";
import { adminPackageSchema } from "../../../../utils/Validations";

const EditPackageForm = ({ packageUpdate, details }) => {
    const [formData, setFormdata] = useState([]);

    useEffect(async () => {
        await setFormdata(details);
    }, [details]);

    const initialValues = {
        packageKey: formData?.packageKey || "",
        packageFund: formData?.packageFund || "",
        walletAmount: formData?.walletAmount || "",
    }

    return (
        <>
            <div>
                {formData ?
                    <Formik
                        initialValues={initialValues}
                        validationSchema={adminPackageSchema}
                        enableReinitialize={true}
                        onSubmit={(values, { resetForm }) => {
                            packageUpdate(values)
                        }}
                    >
                        {({ errors, touched, values, setFieldValue }) => (
                            <Form className="form-group">
                                <br></br>
                                <div className="mt-5">
                                    <div className="col-md-4 form-group error-div">
                                        <InputGroup type="text" name="packageKey" label='Package Key' placaholder='package key' />
                                        <InputGroup type="text" name="packageFund" label='package fund' placeholder='package fund' />
                                        <InputGroup type="text" name="walletAmount" label='wallet amount' placeholder='wallet amount' />
                                    </div>
                                </div>
                                <br></br>
                                <div className="settingSubmit"><button type="submit">Save</button></div>
                            </Form>
                        )}
                    </Formik>
                    :
                    ''
                }
            </div>
        </>
    );
};

export default connect((state) => state)(EditPackageForm);