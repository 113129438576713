import { useDispatch } from 'react-redux';
import { login, logout } from '../store/auth/action';
import AdminRepository from '../api/AdminRepository';

export default function useAdmin() {
    const dispatch = useDispatch();
    return {
        getUserListing: async (data) => {
            var responseData = await AdminRepository.getUserListing(data);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },
        deleteAdminUser: async (data) => {
            var responseData = await AdminRepository.deleteAdminUser(data);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },
        getUserDetails: async (data) => {
            var responseData = await AdminRepository.getUserDetails(data);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },
        editUserDetails: async (data, id) => {
            var responseData = await AdminRepository.editUserDetails(data, id);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },
        addUser: async (data) => {
            var responseData = await AdminRepository.addUser(data);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },
        getAllPackages: async (data) => {
            var responseData = await AdminRepository.getAllPackages(data);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },
        deletePackages: async (data) => {
            var responseData = await AdminRepository.deletePackages(data);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },
        editPackages: async (data, id) => {
            var responseData = await AdminRepository.editPackages(data, id);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },
        createPackages: async (data) => {
            var responseData = await AdminRepository.createPackages(data);
            if (responseData.status === 201) {
                return responseData.data;
            }
            return false;
        },
        getPackageDetailById: async (id) => {
            var responseData = await AdminRepository.getPackageDetailById(id);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },
        MakeStripePayment: async (id) => {
            var responseData = await AdminRepository.makeStripePayment(id);
            if (responseData.status === 201) {
                return responseData.data;
            }
            return false;
        },
    }
}