import React, { useState, useEffect } from "react";
import Input from "@mui/material/Input";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Stack from "@mui/material/Stack";
import Calendericon from "../../../Icon/calendar.png";
import Calenderic from "../../../Icon/calendars.png";
import Camera from "../../../Icon/camera.png";
import Diamond from "../../../Icon/diamond.png";
import Card from "@mui/material/Card";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PublicIcon from "@mui/icons-material/Public";
import GroupsIcon from "@mui/icons-material/Groups";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ImageComponent from "../../../Comman/ImageComponent";
import ImageCroper from "../../../Comman/ImageCroper";
import { connect } from "react-redux";
import useHome from "../../../../hooks/useHome";
import { useHistory, useParams } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Picker from "emoji-picker-react";


const CreatePostComponent = ({ createPost, packages,user,auth }) => {
    const [error, setError] = useState('');
    const [visibility, setVisibility] = useState('');
    const [whoCanSeeStatus, setWhoCanSeeStatus] = useState('');
    const [src, setSrc] = useState(null)
    const [visible, setVisible] = useState(false)
    const [showImage, setShowIImage] = useState(null)
    const [imgAndUrl, setImgAndUrl] = useState(null)
    const [postVideo, setPostVideo] = useState(null)
    const [postText, setPostText] = useState('')
    const [showEmojiMenu, setShowEmojiMenu] = useState(false);
    const [chosenEmoji, setChosenEmoji] = useState(null);


    const { postWhoCanSee } = useHome()    
    let history = useHistory();
    
    useEffect(async () => {
        await setVisibility(auth.postWhoCanSee)
    }, [auth])
    const Input = styled('input')({
        display: 'none',
      });

    const submit = async(event) => {
        event.preventDefault()

        if (postText === '') {
            setError('Please enter the text')
            return
        }
        else if (event.target.who_can_see.value === '') {
            setError('Please select post Visibilty')
            return
        }
        else {
            setError('')
        }
        var data = {
            post: postText,
            type:'text',
            who_can_see:event.target.who_can_see.value,
            urlSlug: postText.replace(' ', '-')
        }

        if (imgAndUrl) {
            data.file = imgAndUrl.image
            data.type = 'image'
        }
        if (postVideo) {
            data.file = postVideo
            data.type = 'video'
        }
        
        deleteCropImage()   
        await setImgAndUrl('')
        await setSrc('')
        await setPostText('')
        document.getElementById("postForm").reset();
        createPost(event, data)
        
    }
 
  const VisibilitySelectChange = async (event) => {
    var data = {
      postWhoCanSee: event.target.value,
    };
    await postWhoCanSee(data);
    setVisibility(event.target.value);
  };

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSrc(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
    await setVisible(true);
  };

  const uploadVideo = async (event) => {
    event.preventDefault();
    await setPostVideo(event.target.files[0]);
  };

  const closePop = async () => {
    await setVisible(false);
  };

  const getCropImage = async (data) => {
    await setShowIImage(data.url);
    await setImgAndUrl(data);
  };

  const deleteCropImage = async (data) => {
    await setShowIImage(null);
  };

  const onEmojiClick = (event, emojiObject) => {
    const newMsg = `${postText}${emojiObject.emoji}`;
    setChosenEmoji(emojiObject);
    setPostText(newMsg)
    setShowEmojiMenu(false)
  };

  const showCropImage = () => {
    return (
      <>
        <div>
          <button onClick={() => deleteCropImage()}>Delete</button>
          <img style={{ width: "250px", height: "250px" }} src={showImage} />
        </div>
      </>
    );
  };
  return (
    <>
      <Card variant="outlined createPostForm" sx={{ padding: "10px 20px" }}>
        <form
          onSubmit={(event) => submit(event)}
          className="postForm"
          id="postForm"
        >
          <Box
            sx={{ display: "flex", alignItems: "flex-end" }}
            className="authorIconTextcomment"
          >
            {/* <Avatar sx={{ mr: 1, my: 0.5 }} aria-label="recipe">
                            <div className="userImage">
                                <ImageComponent sx={{ mr: 1, my: 0.5 }} url="https://evergrowcoin.com/wp-content/uploads/2022/02/Crator_Mobile_welcome_HR-286x300.png" />
                            </div>
                        </Avatar> */}
            <ImageComponent
              classname={"userImage_Post"}
              url={
                user
                  ? user.profile_image
                  : "https://evergrowcoin.com/wp-content/uploads/2022/02/Crator_Mobile_welcome_HR-286x300.png"
              }
            />
            <button onClick={() => setShowEmojiMenu(!showEmojiMenu)}>Emoji</button>
            <Input
              defaultValue={postText}
              onChange={(event) => setPostText(event.target.value)}
              className="createPostInput"
              fullWidth
              name="post"
              placeholder="Show CRATOR whats on your mind..."
            />
            {showEmojiMenu && <Picker onEmojiClick={onEmojiClick} />}
          </Box>
          <Box>{showImage ? showCropImage() : ""}</Box>
          {src && (
            <ImageCroper
              src={src}
              visible={visible}
              onClose={() => closePop()}
              getCropImage={(data) => getCropImage(data)}
            />
          )}

          <span className="errorBlocklist">{error}</span>
          <div className="postBottomBoarderline postBottomBoarderlineMarginedit"></div>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              my: 1,
              justifyContent: "space-between",
            }}
          >
            <Stack direction="row" spacing={2}>
              <Button variant="" className="uploadImageClsBtn">
                <label for="uploadImageCls">
                  <img width="31px" src={Calendericon} />
                </label>
                <input
                  id="uploadImageCls"
                  type="file"
                  accept="image/*"
                  onChange={(event) => onSelectFile(event)}
                />
              </Button>
              <Button variant="">
                <img width="31px" src={Calenderic} />
              </Button>
              <Button variant="">
                <label for="uploadImageCls">
                  <img width="31px" src={Camera} />
                </label>
                <input
                  id="uploadImageCls"
                  type="file"
                  accept="video/*"
                  onChange={(event) => uploadVideo(event)}
                />
              </Button>
              <Button variant="">
                <img width="31px" src={Diamond} />
              </Button>
            </Stack>

            <FormControl fullWidth className="whoCanSeeSelectBox">
              <Select
                name="who_can_see"
                id="who_can_see"
                value={visibility}
                onChange={VisibilitySelectChange}
                displayEmpty
              >
                <MenuItem value="">
                  <em>Who can see this?</em>
                </MenuItem>
                <MenuItem value={"everyone"}>
                  {" "}
                  <PublicIcon /> &nbsp;Everyone
                </MenuItem>
                <MenuItem value={"subscriber"}>
                  {" "}
                  <GroupsIcon /> &nbsp;Subscriber
                </MenuItem>
                <MenuItem value={"premium"}>
                  {" "}
                  <MonetizationOnIcon /> &nbsp;Premium
                </MenuItem>
                {packages &&
                  packages.map((values, key) => (
                    <MenuItem value={values.packageType}>
                      {" "}
                      <MonetizationOnIcon /> &nbsp;{values.packageName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <div>
              <Button
                sx={{ borderRadius: "50%" }}
                type="submit"
                fullWidth={true}
                variant="contained"
                background="#3274e4"
              >
                Post
              </Button>
            </div>
          </Box>
        </form>
      </Card>
    </>
  );
};

export default connect((state) => state.auth)(CreatePostComponent);
// 