export const payHistoryColumns = [
  { id: "cryptoAmount", label: "Amount", minWidth: 80 },
  { id: "date", label: "Date", minWidth: 80, align: "centre" },
  { id: "method", label: "Payment Method", minWidth: 80 },
  { id: "type", label: "Type", minWidth: 80 },
  { id: "status", label: "Current Status", minWidth: 80 },
  // format: (value) => value.toLocaleString('en-US') },
];

export const RecivedPaymentColumns = [
  { id: "Id", label: "ID", minWidth: 80 },
  { id: "paidBy", label: "Paid By", minWidth: 80, align: "centre" },
  { id: "type", label: "Payment Type", minWidth: 80 },
  { id: "date", label: "Date", minWidth: 80 },
  { id: "amount", label: "Amount", minWidth: 80 },
  { id: "earning", label: "Final Earnings", minWidth: 80 },
];

export const SubscribtionPaymentColumns = [
  { id: "name", label: "Subscriber Name", minWidth: 80 },
  { id: "amount", label: "Amount", minWidth: 80, align: "centre" },
  { id: "date", label: "Started At", minWidth: 80 },
  { id: "status", label: "Current Status", minWidth: 80 },
  { id: "earning", label: "Final Earnings", minWidth: 80 },
];

export const paginationComponentOptions = {
  selectAllRowsItem: true,
  selectAllRowsItemText: "ALL",
};

export const admin_user_columns = [
  {
    name: "Id",
    selector: (row) => row.id,
    maxWidth: "30px",
  },
  {
    name: "User",
    selector: (row) => row.userName,
  },
  {
    name: "Registered at",
    selector: (row) => row.registerAt,
  },
  {
    name: "User Creator Type",
    selector: (row) => row.creatorType,
  },
  {
    name: "Verification Status",
    selector: (row) => row.verificationStatus,
  },
  {
    name: "Actions",
    selector: (row) => row.action,
    minWidth: "200px",
  },
];

export const stripe_Currencies = [
  "USD($)",
  "AUD($)",
  "EUR(€)",
  "BRL(R$)",
  "CAD($)",
  "CZK(Kč)",
  "DKK(kr)",
  "HKD($)",
  "HUF(Ft)",
  "INR(₹)",
  "ILS(₪)",
  "JPY(¥)",
  "MYR(RM)",
  "MXN($)",
  "TWD(NT$)",
  "NZD($)",
  "NOK(kr)",
  "PHP(₱)",
  "PLN(zł)",
  "GBP(£)",
  "RUB(руб)",
  "SGD($)",
  "CHF(CHF)",
  "THB(฿)",
  "TRY(₺)",
  "ZAR(R)",
  "NGN(₦)",
  "VND(đ)",
  "BDT(৳)",
];
