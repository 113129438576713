import NotificationRepository from "../api/NotificationRepository";
import { useDispatch } from "react-redux";
import { login, logout } from "../store/auth/action";

export default function useNotification() {
  const dispatch = useDispatch();
  return {
    saveNotification: async (data) => {
      try {
        const res = await NotificationRepository.saveNotification(data);
        if (res.status === 201) {
          return res.data;
        }
      } catch (error) {
        throw error;
      }
    },
    getAllNotifications: async (page = 1, limit = 10) => {
      try {
        const res = await NotificationRepository.getAllNotifications(
          page,
          limit
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        throw error;
      }
    },
    getOneNotifications: async (id) => {
      try {
        const res = await NotificationRepository.getOneNotification(id);
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        throw error;
      }
    },
    updateNotifications: async (id, data) => {
      try {
        const res = await NotificationRepository.updateNotification(id, data);
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        throw error;
      }
    },
    deleteNotifications: async (id) => {
      try {
        const res = await NotificationRepository.deleteNotification(id);
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        throw error;
      }
    },
  };
}
