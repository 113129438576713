import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import "./WebsiteSetting.css";
import AdminLeftMenu from "../../adminLeftSideMenu/adminLeftMenuComponent";
import useAdminDashboard from "../../../../hooks/useAdminDashboard";
import useUser from "../../../../hooks/useUser";
import Grid from "@mui/material/Grid";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'
import { Formik, Form, Field } from 'formik';
import { WebSiteSettingSchema } from '../../../../utils/Validations'
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';


TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)
const Input = styled('input')({
  display: 'none',
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const WebsiteSettingComponent = (props) => {
  const { id } = useParams();
  const { auth } = useSelector((state) => state);
  const [allPostData, setAllPostData] = useState([]);
  const [srcLogo, setLogoSrc] = useState(null)
  const [srcFavicon, setSrcFavicon] = useState(null)
  const [visible, setVisible] = useState(false)
  const [imgType, setImgtype] = useState(null)
  const [webSiteSettingData, setwebSiteSettingData] = useState(null)
  const [formData, setFormdata] = useState({})

  const userDetail = auth.auth;
  let history = useHistory();
  const { isLogin } = useUser();
  const {
    webSiteSetting,
    webSiteSettingImg,
  } = useAdminDashboard();

  useEffect(async () => {
    await authCheck();

  }, [history]);


  const authCheck = async () => {
  var response = await isLogin();

    if (parseInt(response.status) === 401) {
      history.push("/login");
    } else {
      if (props) {
        return;
      }
      history.push("/");
    }
  };


const onSelectFile = async (e, type) => {
    if (e.target.files && e.target.files.length > 0) {
        if (e.target.files && e.target.files.length > 0) {
            var form = new FormData()
            form.append('siteLogo',e.target.files[0])
            await webSiteSettingImg(form)
            await setLogoSrc(e.target.files[0])
        }
    }
};  
const onSelectFileFavicon = async (e, type) => {
    alert('zczxzcx')
    // if (e.target.files && e.target.files.length > 0) {
    //     var form = new FormData()
    //     form.append('siteFavicon',e.target.files[0])
    //     await setSrcFavicon(e.target.files[0])
    // }
};

const mk = (event,id) =>{
    alert(id)
}

const createWebSiteSetting = async (event) => {
    event.preventDefault()
    const formData = new FormData();
    formData.append('siteLogo', srcLogo)
    formData.append('siteFavicon', srcFavicon)
    formData.append('siteName', event.target.siteName.value)
    formData.append('siteTitle', event.target.siteTitle.value)
    formData.append('siteKeywords', event.target.siteKeywords.value)
    formData.append('siteDescription', event.target.siteDescription.value)
// 
    // var data = {
    //     siteName:event.target.siteName.value,
    //     siteTitle:event.target.siteTitle.value,
    //     siteKeywords:event.target.siteKeywords.value,
    //     siteDescription:event.target.siteDescription.value,
    //     siteLogo:srcLogo,
    //     siteFavicon:srcFavicon,
    //   }
    var data = await webSiteSetting(formData);

    // var data = await webSiteSetting(formData);
    
}


  return (
    <>
      <nav>
        <div style={{ display: "flex" }}>
          <div className="sideBar">
            <AdminLeftMenu />
          </div>
          <div className="admin_main_wrp settingFormFields" style={{ width: "100%" }}>
                {formData ?
                    <Formik
                        initialValues={{
                            siteName: props.siteName,
                            siteTitle: props.siteTitle,
                            siteKeywords: props.siteKeywords,
                            siteDescription: props.siteDescription,
                            siteLogo: props.siteLogo,
                            siteFavicon: props.siteFavicon,
                        }}
                        validationSchema={WebSiteSettingSchema}
                        enableReinitialize
                        onSubmit={(res, {resetForm}) => {
                            createWebSiteSetting(res);
                            resetForm();
                        }}
                    >
                {({ errors, touched, values }) => (
                    <div>
                        <h1>Website Settings</h1>
                        <Form  onSubmit={((event) => createWebSiteSetting(event))}>
                            <div className="col-md-4 form-group error-div">
                                <div className="formLabel">
                                    <leble for="site_logo_lbl">Site Logo</leble>
                                </div>
                                    <div className="formField">
                                    <label htmlFor="contained-button-file">
                                        <Input accept="image/*" id="contained-button-file" type="file" 
                                        name="siteLogo" multiple onChange={((event) => onSelectFile(event, 'siteLogo'))} />
                                        <Button variant="contained" component="span">
                                        Logo
                                        </Button>
                                    </label>
                                    <label htmlFor="icon-button-file">
                                        <Input accept="image/*" id="icon-button-file" type="file" />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                        <PhotoCamera />
                                        </IconButton>
                                    </label>
                                    <span>Logo height must be 45px , recommended with 135px</span>
                                </div>
                            </div>
                            <div className="col-md-4 form-group error-div">
                                <div className="formLabel">
                                    <leble for="faviconlbl">Favicon</leble>
                                </div>
                                <div className="formField">
                                <label htmlFor="contained-button-file">
                                    <Input id="site_favicon" 
                                        type="file" 
                                        name="siteFavicon" 
                                        accept="image/*" 
                                        onChange={((event) => mk(event, 'Favicon'))} />
                                        <Button variant="contained" component="span">
                                        Favi 
                                        </Button>
                                    </label>
                                    <label htmlFor="icon-button-file">
                                        <Input accept="image/*" id="icon-button-file" type="file" />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                        <PhotoCamera />
                                        </IconButton>
                                    </label>
                                    <span>Favicon size must be 128x128px</span>
                                </div>
                            </div>
                            <div className="col-md-4 form-group error-div">
                                <div className="formLabel">
                                    <leble for="siteNamelbl">Site Name</leble>
                                </div>
                                <div className="formField">
                                    <Field 
                                        id="site_name"  
                                        name="siteName"
                                        type="text"
                                        className="form-control form-control-sm"
                                    />
                                    <p style={{ color: "red", fontSize: "14px" }}>{errors.site_name}</p>
                                </div>
                            </div>
                            <div className="col-md-4 form-group error-div">
                                <div className="formLabel">
                                    <leble for="siteTitlelbl">Site Title</leble>
                                </div>
                                <div className="formField">
                                    <Field 
                                        id="site_title"  
                                        name="siteTitle"
                                        type="text"
                                        className="form-control form-control-sm"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 form-group error-div">
                                <div className="formLabel">
                                    <leble for="siteDescriptionlbl">Site Description</leble>
                                </div>
                                <div className="formField">
                                    <Field 
                                        id="site_description"  
                                        name="siteDescription"
                                        type="text"
                                        className="form-control form-control-sm"
                                    /> 
                                </div>
                            </div>
                            <div className="col-md-4 form-group error-div">
                                <div className="formLabel">
                                    <leble for="KeywordsNamelbl">Keywords</leble>
                                </div>
                                <div className="formField">
                                    <Field 
                                        id="Keywords"  
                                        name="siteKeywords"
                                        type="text"
                                        className="form-control form-control-sm"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 form-group error-div">
                                <div className="adminSubmit submitData">
                                    <input type='submit' value="Save" name="Save"/>
                                </div>
                            </div>
                        </Form>
                    </div>
                )}
                </Formik>
            : ''}
          </div>
        </div>
      </nav>
    </>
  );
};

export default WebsiteSettingComponent;
