import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./DashboardComponent.css";
import AdminLeftMenu from "./adminLeftSideMenu/adminLeftMenuComponent";
import useAdminDashboard from "../../hooks/useAdminDashboard";
import useUser from "../../hooks/useUser";
import { VerticalAlignBottom } from "@material-ui/icons";
import { imageUrl } from "../../api/impUrl";
import Moment from "react-moment";
import Button from "@mui/material/Button";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DashboardComponent = (props) => {
  const { id } = useParams();
  const { auth } = useSelector((state) => state);
  const [userCount, setUserCount] = useState({});
  const [countPost, setPostCount] = useState({});
  const [countEarning, setEarning] = useState({});
  const [subscriptionEarning, setSubscriptionEarning] = useState({});
  const [latestUsers, setLastestUsers] = useState({});
  const [premiumPostCount, setPremiumPostCount] = useState({});
  const [latestSubs, SetLatestSubs] = useState({});

  const userDetail = auth.auth;
  let history = useHistory();
  const { isLogin } = useUser();
  const {
    getAllUserCount,
    getAllPostCount,
    getadminEarningCount,
    getLatestUsers,
    getPremiumPostCount,
    getAdminSubscriptionEarning,
    getLastestSubsriptions,
  } = useAdminDashboard();

  useEffect(async () => {
    await authCheck();
    var allCount = await getAllUserCount();
    await setUserCount(allCount);

    var allPost = await getAllPostCount();
    await setPostCount(allPost);

    var earning = await getadminEarningCount();
    await setEarning(earning);

    var subsEarning = await getAdminSubscriptionEarning();
    await setSubscriptionEarning(subsEarning);

    var premiumPost = await getPremiumPostCount();
    await setPremiumPostCount(premiumPost);

    var users = await getLatestUsers();
    await setLastestUsers(users);

    var subscribers = await getLastestSubsriptions();
    await SetLatestSubs(subscribers);
  }, [history]);

  const authCheck = async () => {
    var response = await isLogin();

    if (parseInt(response.status) === 401) {
      history.push("/login");
    } else {
      if (props) {
        return;
      }
      history.push("/");
    }
  };
  return (
    <>
      <nav>
        <div style={{ display: "flex" }}>
          <div className="sideBar">
            <AdminLeftMenu />
          </div>
          <div
            className="dash_adminSubmit admin_main_wrp"
            style={{ width: "100%" }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={3} sx={{ width: 128, minHeight: 200 }}>
                  <Item sx={{ width: 200, minHeight: 200 }}>
                    <div textAlignLeft alig className="follow_text">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="34"
                          height="35"
                          viewBox="0 0 34 35"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M17.0103 0.440918C26.3134 0.440918 33.8663 7.99384 33.8663 17.2969C33.8663 26.6 26.3134 34.1529 17.0103 34.1529C7.70719 34.1529 0.154266 26.6 0.154266 17.2969C0.154266 7.99384 7.70719 0.440918 17.0103 0.440918ZM15.4932 28.6752V26.0367C14.1153 25.9488 13.0013 25.7876 12.1512 25.553C11.301 25.3185 10.5974 25.0986 10.0404 24.8934V20.54H14.8775C14.8775 20.9798 14.9655 21.3059 15.1414 21.5184C15.3173 21.731 15.6837 21.8372 16.2407 21.8372C16.7245 21.8372 17.0689 21.764 17.2741 21.6174C17.4793 21.4708 17.5819 21.2509 17.5819 20.9578C17.5819 20.7526 17.4977 20.573 17.3291 20.4191C17.1605 20.2652 16.8197 20.0856 16.3067 19.8804C15.222 19.47 14.2729 19.0779 13.4594 18.7041C12.6459 18.3303 11.9716 17.9163 11.4366 17.4619C10.9016 17.0075 10.5022 16.4761 10.2383 15.8678C9.97447 15.2595 9.84256 14.523 9.84256 13.6581C9.84256 12.9399 9.94151 12.284 10.1394 11.6903C10.3373 11.0967 10.6561 10.5763 11.0958 10.1292C11.5355 9.68217 12.1145 9.31572 12.8328 9.02989C13.551 8.74407 14.4378 8.54985 15.4932 8.44725V5.91876H18.3735V8.40326C19.0624 8.44724 19.6707 8.51686 20.1984 8.61214C20.7261 8.70742 21.2098 8.81736 21.6495 8.94194C22.0893 9.06654 22.4923 9.19847 22.8588 9.33771C23.2252 9.47697 23.5257 9.61988 23.7602 9.76645V13.7021H19.143C19.143 13.277 19.0257 12.9875 18.7912 12.8336C18.5567 12.6797 18.2709 12.6028 17.9337 12.6028C17.45 12.6028 17.1019 12.6651 16.8894 12.7897C16.6768 12.9143 16.5706 13.1304 16.5706 13.4383C16.5706 13.6874 16.6841 13.8707 16.9113 13.9879C17.1385 14.1052 17.4793 14.2371 17.9337 14.3837C18.7839 14.6622 19.5864 14.9554 20.3413 15.2632C21.0962 15.571 21.7594 15.9521 22.3311 16.4065C22.9028 16.8609 23.3535 17.4106 23.6833 18.0555C24.0131 18.7005 24.178 19.492 24.178 20.4301C24.178 22.1157 23.687 23.424 22.7049 24.3548C21.7228 25.2855 20.279 25.8389 18.3735 26.0148V28.6752H15.4932Z"
                            fill="#3072E3"
                            stroke="#EBEBEB"
                            stroke-width="0.00125075"
                            stroke-miterlimit="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </span>
                      <h1 className="text_dash">Net Earning(admin) </h1>
                    </div>
                    <h3 className="inner_value_text">
                      {" "}
                      ${countEarning ? countEarning.adminEarn : "loading"}
                    </h3>
                    <Button variant="text">view more</Button>
                  </Item>
                </Grid>
                <Grid item xs={3} sx={{ width: 128, minHeight: 200 }}>
                  <Item sx={{ width: 200, minHeight: 200 }}>
                    <div textAlignLeft alig className="follow_text">
                      <span>
                        <svg
                          width="34"
                          height="35"
                          viewBox="0 0 29 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.28125 15C0.28125 7.15985 6.65987 0.78125 14.5 0.78125C22.3401 0.78125 28.7188 7.15985 28.7188 15C28.7188 22.8401 22.3401 29.2188 14.5 29.2188C6.65987 29.2188 0.28125 22.8401 0.28125 15ZM14.5 22.4054C16.8926 20.6943 19.0247 18.7721 19.6058 18.0639C20.9723 16.3979 21.6278 14.687 21.6087 12.8351C21.5875 10.7119 19.8847 8.98438 17.8127 8.98438C16.306 8.98438 15.252 9.82452 14.6552 10.5396L14.5 10.7275L14.3448 10.5396C13.7371 9.83203 12.6912 8.9837 11.1873 8.9837C9.11533 8.9837 7.41249 10.7111 7.3913 12.8344C7.37216 14.687 8.02704 16.3979 9.39423 18.0639C9.97528 18.7721 12.1074 20.6964 14.5 22.4054Z"
                            fill="#6F6F6F"
                          ></path>
                        </svg>
                      </span>
                      <h1 className="text_dash">Premium Post earning</h1>
                    </div>
                    <h3 className="inner_value_text">
                      {" "}
                      $
                      {premiumPostCount
                        ? premiumPostCount.permiumPaymentEarning
                        : "loading"}
                    </h3>
                    <Button variant="text">view more</Button>
                  </Item>
                </Grid>
                <Grid item xs={3}>
                  <Item sx={{ width: 200, minHeight: 200 }}>
                    <div textAlignLeft alig className="follow_text">
                      <span>
                        <svg
                          width="34"
                          height="35"
                          viewBox="0 0 29 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.28125 15C0.28125 7.15985 6.65987 0.78125 14.5 0.78125C22.3401 0.78125 28.7188 7.15985 28.7188 15C28.7188 22.8401 22.3401 29.2188 14.5 29.2188C6.65987 29.2188 0.28125 22.8401 0.28125 15ZM14.5 22.4054C16.8926 20.6943 19.0247 18.7721 19.6058 18.0639C20.9723 16.3979 21.6278 14.687 21.6087 12.8351C21.5875 10.7119 19.8847 8.98438 17.8127 8.98438C16.306 8.98438 15.252 9.82452 14.6552 10.5396L14.5 10.7275L14.3448 10.5396C13.7371 9.83203 12.6912 8.9837 11.1873 8.9837C9.11533 8.9837 7.41249 10.7111 7.3913 12.8344C7.37216 14.687 8.02704 16.3979 9.39423 18.0639C9.97528 18.7721 12.1074 20.6964 14.5 22.4054Z"
                            fill="#6F6F6F"
                          ></path>
                        </svg>
                      </span>
                      <h1 className="text_dash"> Users</h1>
                    </div>
                    <h3 className="inner_value_text">
                      {userCount ? userCount.totalUsers : "loading"}
                    </h3>
                    <Button variant="text">view more</Button>
                  </Item>
                </Grid>
                <Grid item xs={3}>
                  <Item sx={{ width: 200, minHeight: 200 }}>
                    <div textAlignLeft alig className="follow_text">
                      <span>
                        <svg
                          width="34"
                          height="35"
                          viewBox="0 0 29 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.28125 15C0.28125 7.15985 6.65987 0.78125 14.5 0.78125C22.3401 0.78125 28.7188 7.15985 28.7188 15C28.7188 22.8401 22.3401 29.2188 14.5 29.2188C6.65987 29.2188 0.28125 22.8401 0.28125 15ZM14.5 22.4054C16.8926 20.6943 19.0247 18.7721 19.6058 18.0639C20.9723 16.3979 21.6278 14.687 21.6087 12.8351C21.5875 10.7119 19.8847 8.98438 17.8127 8.98438C16.306 8.98438 15.252 9.82452 14.6552 10.5396L14.5 10.7275L14.3448 10.5396C13.7371 9.83203 12.6912 8.9837 11.1873 8.9837C9.11533 8.9837 7.41249 10.7111 7.3913 12.8344C7.37216 14.687 8.02704 16.3979 9.39423 18.0639C9.97528 18.7721 12.1074 20.6964 14.5 22.4054Z"
                            fill="#6F6F6F"
                          ></path>
                        </svg>
                      </span>
                      <h1 className="text_dash">Posts</h1>
                    </div>
                    <h3 className="inner_value_text">
                      {countPost ? countPost.totalPost : "loading"}
                    </h3>
                    <Button variant="text">view more</Button>
                  </Item>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Item>
                    <h3>NEW REGISTERED USERS</h3>
                    {latestUsers?.latestUsers?.map((user) => {
                      return (
                        <div className="SubscriptionsMenuItem" key={user._id}>
                          <a href="#">
                            <img
                              src={imageUrl + user?.profile_image}
                              alt={user?.username}
                            />
                          </a>
                          <h5>{user?.userFullName}</h5>
                          <a href="#">@ {user?.username}</a>
                        </div>
                      );
                    })}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <h3>LATEST SUBSCRIPTIONS</h3>
                    {latestSubs?.friends?.map((friend) => {
                      return (
                        <div className="SubscriptionsMenuItem" key={friend._id}>
                          <a href="#">
                            <img
                              src={imageUrl + friend?.subscribedBy?.profile_image}
                              alt="user image"
                            />
                          </a>
                          <h5>
                            {friend?.subscribedBy?.username} <b>subscibed to</b>{" "}
                            {friend?.subscribedTo?.username}
                          </h5>
                          <h6>
                            <Moment format="DD-MMM-YYYY">
                              {friend?.updatedAt}
                            </Moment>
                          </h6>
                        </div>
                      );
                    })}
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </nav>
    </>
  );
};

export default DashboardComponent;
