import React from "react";
import { getIn, Field, Formik, Form, ErrorMessage } from "formik";

function InputGroup({
  name,
  label,
  type,
  info,
  required,
  defaultChecked,
  placeholder,
}) {
  return (
    <div className="col-md-4 form-group error-div">
      <div className={name}>
        <label>{label}</label>
        {required && "*"}
      </div>

      <Field name={name}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => (
          <div>
            <input
              type={type}
              defaultChecked={defaultChecked}
              placeholder={placeholder}
              {...field}
            />
            {meta.touched && meta.error && (
              <div className="error">{meta.error}</div>
            )}
          </div>
        )}
      </Field>
    </div>
  );
}

export default InputGroup;
