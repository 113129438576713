import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getIn, Field, Formik, Form, ErrorMessage } from "formik";
import InputGroup from "../../../Comman/InputComponent";
import { adminAddUserSchema } from "../../../../utils/Validations";

const AddUserForm = ({ addNewUser }) => {
    
    const initialValues = {
        gender: 'male',
        name: "",
        username: "",
        email: "",
        password: "",
        location: ""
    }

    return (
        <>
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={adminAddUserSchema}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => {
                        addNewUser(values);
                        resetForm();
                    }}
                >
                    {({ errors, touched, values, setFieldValue }) => (
                        <Form className="form-group">
                            <br></br>
                            <h1>Add Users</h1>
                            <div className="mt-5">
                                <div className="col-md-4 form-group error-div">
                                    <label htmlFor="userVerifiedStatus" style={{ display: 'block' }}>
                                        You are
                                    </label>
                                    <div role="group" aria-labelledby="my-radio-group">
                                        <label>
                                            <Field type="radio" name="gender" value="male" />
                                            Male
                                        </label>
                                        <label>
                                            <Field type="radio" name="gender" value="female" />
                                            Female
                                        </label>
                                    </div>



                                    <InputGroup type="text" name="name" label="Display Name" />
                                    <InputGroup type="text" name="username" label="User Name" />
                                    <InputGroup type="text" name="email" label="Your Email Address" />
                                    <InputGroup type="text" name="password" label="Password" />
                                    <InputGroup type="text" name="location" label="Location" />

                                </div>
                            </div>
                            <div className="settingSubmit"><button type="submit">Save</button></div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default connect((state) => state)(AddUserForm);