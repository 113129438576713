import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import LeftMenu from "../LeftMenu";
import useDashboard from '../../../hooks/useDashboard'
import Navbar from '../../Header/headerComponent'
import useUser from "../../../hooks/useUser";
import { useHistory } from "react-router-dom";
import { notification } from 'antd';
import { SmileOutlined, CloseOutlined } from '@ant-design/icons';
import '../settings.css'
import PackageForms from "./Form/PackageForms";

const SubscriptionPackage = () => {
    const [plans, setPlans] = useState([])
    const [reload, setRelaod] = useState(false);
    const { auth } = useSelector((state) => state.auth);
    const { isLogin } = useUser()
    const { createPlan, getAllPlans } = useDashboard();
    let history = useHistory();

    useEffect(async () => {
        await authCheck();
        var plans = await getAllPlans();
        await setPlans(plans.data);
    }, [reload])

    const authCheck = async () => {
        var response = await isLogin();
        if (parseInt(response.status) === 401) {
            history.push("/login");
        }
    };

    const createPlans = async (data) => {
        var res = await createPlan(data)
        if (res) {
            notification.open({
                message: 'Update',
                description: res.message,
                icon: <SmileOutlined style={{ color: '#108ee9' }} />,
            });
        }
        else {
            notification.open({
                message: 'Error',
                description: 'Something went wrong...!',
                icon: <CloseOutlined style={{ color: '#f4364c' }} />,
            });
        }
        setRelaod(!reload)
        return res;
    }
    return (
        <>
            <nav>
                <div className="headerSection">
                    <Navbar />
                </div>
            </nav>
            <div className="container">
                <div className="wrapHomeSection">
                    <div className="leftSideMenu">
                        <LeftMenu />
                    </div>
                    <div className="settingArea">
                        <Grid container>
                            <Grid item xs={12}>
                                <div className="bannerImageSection">
                                    <div className="bannerText">
                                        <pre>Profile Banner </pre>
                                        Setup Subscribers Packages
                                        Set fees for people who want to subscribe to your profile. Pricing can be made in
                                        <b>Different ways: Basic(Weekly, Monthly, Yearly), Standard(Weekly, Monthly, Yearly), Premium(Weekly, Monthly, Yearly).</b>
                                        <br></br>
                                        These charges will be renewed automatically when they expire.
                                        <br></br>
                                        *If you block your subscribers or if they block you, subscriptions will be automatically canceled.
                                        <br></br>
                                        *You can activate or deactivate your subscription pricing. After the fees you earn while active, become passive, your subscriptions continue until your member cancels.
                                        <br></br>
                                        *Changing the fee will not change the price of previous subscribers.
                                        <br></br>
                                        *1 fund in your subscription earnings earns you $1. So if you want 100 fund for the subscription, this is equivalent to $100. Our website's intermediary fee is deducted in the calculations. In other words, 5% of your $100 earnings will be deducted from your earnings as commission, so your net income will be $95

                                    </div>
                                </div>
                                <div className="settingFormSection">
                                    {auth ? <PackageForms createPlan={(data) => { createPlans(data) }} planDetails={plans} /> : ''}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect((state) => state)(SubscriptionPackage);