import * as React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Skeleton from '@mui/material/Skeleton';

function Media(props) {
  const { loading = false } = props;

  return (
    <Card sx={{ maxWidth: '100%', m: 2,height:400 }}>
      <CardHeader
        avatar={
            <Skeleton animation="wave" variant="circular" width={60} height={60} />
        }
        action={
          loading ? null : (
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          )
        }
        title={
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
       
        }
        subheader={        
            <Skeleton animation="wave" height={10} width="40%" />         
        }
      />
        <Skeleton sx={{ height: 250 }} animation="wave" variant="rectangular" />

        <CardContent>
            <React.Fragment>
                <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                <Skeleton animation="wave" height={10} width="80%" />
            </React.Fragment>
            
        </CardContent>
    </Card>
  );
}

Media.propTypes = {
  loading: PropTypes.bool,
};

export default function Postloader() {
  return (
    <div>
      <Media loading />
    </div>
  );
}
