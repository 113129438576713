import React from "react";
import { Dropdown } from "antd";
import ImageComponent from "../../../Comman/ImageComponent";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import { useHistory } from "react-router-dom";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";

// TimeAgo.addDefaultLocale(en)
// TimeAgo.addLocale(ru)

const SharePost = ({ post }) => {
  const history = useHistory();

  const getTime = (dt) => {
    const calendarStrings = {
      lastDay: "[Yesterday at] LT",
      sameDay: "[Today at] LT",
      nextDay: "[Tomorrow at] LT",
      lastWeek: "[last] dddd [at] LT",
      nextWeek: "dddd [at] LT",
      sameElse: "L",
    };

    return <ReactTimeAgo date={dt} locale="en-US" />;
  };

  const gotoUserprofile = (username) => {
    history.push(`/profile/${username}`);
  };
  console.log('repost',post)
  return (
    <>
      <div className="postCard reshared_post" key={post.postOwner}>
        <div className="userProfileWrap">
          <div className="topPostHeader">
            <div className="userProfile">
              <div className="userImage">
                <ImageComponent url={post.postOwner.profile_image} />
                {post.pinnedByMe ? "" : ""}
              </div>
              <div className="userInformation border_bottom">
                <div className="userName">
                  {post.postOwner.userFullName
                    ? post.postOwner.userFullName
                    : post.postOwner.name}
                </div>
                <div
                  className="userNameEmail"
                  onClick={() =>
                    post.postOwner.username
                      ? gotoUserprofile(post.postOwner.username)
                      : ""
                  }
                >
                  @
                  {post.postOwner.username
                    ? post.postOwner.username
                    : post.postOwner.email.split("@")[0]}
                </div>
              </div>
            </div>
            <div className="postAction">
              <div className="postDatetimes">{getTime(post.createdAt)}</div>
            </div>
          </div>
        </div>
        <div className="feedPostText">
          <p>{post.postText}</p>
          {post?.postFile.length > 0 ?
            <ImageComponent
              classname={"postImage"}
              url={post.postFile.length > 0 ? post.postFile[0] : ""}
            />
            :''}
        </div>
      </div>
    </>
  );
};

export default SharePost;
