import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import "./BillingInformations.css";
import AdminLeftMenu from "../../adminLeftSideMenu/adminLeftMenuComponent";
import useAdminDashboard from "../../../../hooks/useAdminDashboard";
import useUser from "../../../../hooks/useUser";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const BillingInformations = (props) => {
  const { id } = useParams();
  const { auth } = useSelector((state) => state);
  const [allPostData, setAllPostData] = useState([]);

  const userDetail = auth.auth;
  let history = useHistory();
  const { isLogin } = useUser();
  const {
    billingInformations,
  } = useAdminDashboard();

  useEffect(async () => {
    await authCheck();

  }, [history]);


  const authCheck = async () => {
  var response = await isLogin();

    if (parseInt(response.status) === 401) {
      history.push("/login");
    } else {
      if (props) {
        return;
      }
      history.push("/");
    }
  };



const createBillingInformations = async (event) => {
    event.preventDefault()
    var data = {
        campany:event.target.campany.value,
        country:event.target.country.value,
        city:event.target.city.value,
        businessAddress:event.target.businessAddress.value,
        postCode:event.target.postCode.value,
        vat:event.target.vat.value,
      }
    var data = await billingInformations(data);
}


  return (
    <>
      <nav>
        <div style={{ display: "flex" }}>
          <div className="sideBar">
            <AdminLeftMenu />
          </div>
          <div className="admin_main_wrp settingFormFields"  style={{ width: "100%" }}>
              <div>
                  <h1>Billing Informations</h1>
                   <form onSubmit={((event) => createBillingInformations(event))}>
                        <div className="col-md-4 form-group error-div">
                            <div className="formLabel">
                                <leble for="campanylbl">Campany</leble>
                            </div>
                            <div className="formLabel">
                                <input 
                                    id="campany"  
                                    name="campany"
                                    type="text"
                                    className="form-control form-control-sm"
                                />
                            </div>
                        </div> 
                        <div className="col-md-4 form-group error-div">
                            <div className="formLabel">
                                <leble for="countrylbl">Country</leble>
                            </div>
                            <div className="formLabel">
                                <input 
                                    id="country"  
                                    name="country"
                                    type="text"
                                    className="form-control form-control-sm"
                                />
                            </div>
                        </div>  
                        <div className="col-md-4 form-group error-div">
                            <div className="formLabel">
                                <leble for="citylbl">City</leble>
                            </div>
                            <div className="formLabel">
                                <input 
                                    id="city"  
                                    name="city"
                                    type="text"
                                    className="form-control form-control-sm"
                                />
                            </div>
                        </div> 
                        <div className="col-md-4 form-group error-div">
                            <div className="formLabel">
                                <leble for="businessAddresslbl">Business Address</leble>
                            </div>
                            <div className="formLabel">
                                <input 
                                    id="businessAddress"  
                                    name="businessAddress"
                                    type="text"
                                    className="form-control form-control-sm"
                                />
                            </div>
                        </div>
                        <div className="col-md-4 form-group error-div">
                            <div className="formLabel">
                                <leble for="postCodelbl">Post Code</leble>
                            </div>
                            <div className="formLabel">
                                <input 
                                    id="postCode"  
                                    name="postCode"
                                    type="text"
                                    className="form-control form-control-sm"
                                />
                            </div>
                        </div> 
                        <div className="col-md-4 form-group error-div">
                            <div className="formLabel">
                                <leble for="vatlbl">Vat</leble>
                            </div>
                            <div className="formLabel">
                                <input 
                                    id="vat"  
                                    name="vat"
                                    type="text"
                                    className="form-control form-control-sm"
                                />
                            </div>
                        </div>
                        <div className="col-md-4 form-group error-div">
                            <div className="adminSubmit submitData">
                                <input type='submit' value="Save" name="Save"/>
                            </div>
                        </div>
                   </form>
              </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default BillingInformations;
