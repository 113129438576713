import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import "./PostList.css";
import AdminLeftMenu from "../adminLeftSideMenu/adminLeftMenuComponent";
import useAdminDashboard from "../../../hooks/useAdminDashboard";
import useUser from "../../../hooks/useUser";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Button from "@material-ui/core/Button";
import Popup from '../../Comman/Popup';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const PostListComponent = (props) => {
  const { id } = useParams();
  const { auth } = useSelector((state) => state);
  const [allPostData, setAllPostData] = useState([]);
  const [visible, setVisible] = useState(false)
  const [html, setHtml] = useState('')
  const [title, setTitle] = useState('')
  const [countPost, setPosts] = useState({});

  const userDetail = auth.auth;
  let history = useHistory();
  const { isLogin } = useUser();
  const {
    getAllPostData,
    updatePostData,
    postDeleteData,
    posActiveDeactiveData,
    getAllPostCount,

  } = useAdminDashboard();

  useEffect(async () => {
    await authCheck();
    var allPost = await getAllPostData();
    await setAllPostData(allPost.posts);
    var allPostCount = await getAllPostCount();
    await setPosts(allPostCount);

  }, [history]);


  const authCheck = async () => {
  var response = await isLogin();

    if (parseInt(response.status) === 401) {
      history.push("/login");
    } else {
      if (props) {
        return;
      }
      history.push("/");
    }
  };

  const getTime = (dt) => {
    const calendarStrings = {
        lastDay: '[Yesterday at] LT',
        sameDay: '[Today at] LT',
        nextDay: '[Tomorrow at] LT',
        lastWeek: '[last] dddd [at] LT',
        nextWeek: 'dddd [at] LT',
        sameElse: 'L'
    };

    return (
        ''
    );
}

  const getOwnerName = (Owner)=>{
    return Owner.postOwner ? Owner.postOwner.name:''
  }
  const closePop = async () => {
    await setVisible(false)
  }

  const postDelete = async (id,status) => {
    var postDelete = await postDeleteData(id,status);
    await getAllPostData()
  } 
  const posActiveDeactive = async (id,status) => {
    var posActiveDeactive = await posActiveDeactiveData(id,status);
  } 
  const updatePost = async (event,id) => {
    event.preventDefault()
    var data = {
      postText:event.target.postText.value
    }
    var updatePost = await updatePostData(data,id);
    await setVisible(false)
  }

  const postEdit = async(post) => {
    await setVisible(false)
    setVisible(true)
    setTitle('Update Post')
    var html = (
      <div>
        <span className="ownerName">{getOwnerName(post)}</span>
        <span className="time">{getTime(post.createdAt)}</span>
        <form onSubmit={((event) => updatePost(event,post._id))} >
          <input defaultValue={post.postText} name="postText"/>
          <input type='submit' value="Save" name="Save"/>
        </form>
      </div>
      
    )
    setHtml(html)
  }

  return (
    <>
      <nav>
        <div style={{ display: "flex" }}>
          <div className="sideBar">
            <AdminLeftMenu />
          </div>
          <div className="admin_main_wrp" style={{ width: "100%" }}>
            <div className="post-count">
              <span>Posts({countPost.totalPost})</span>
            </div>
            <Box sx={{ flexGrow: 1 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                <TableRow>
                  <TableCell>S.No.</TableCell>
                  <TableCell>Post Owner</TableCell>
                  <TableCell>Date </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                  {allPostData && allPostData.map((row,i) => (
                    <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {i+1}
                    </TableCell>
                    <TableCell >{getOwnerName(row)}</TableCell>
                    <TableCell >{getTime(row.createdAt)}</TableCell>
                     
                    <TableCell >
                      <Button variant="outlined" color="error" onClick={(()=>posActiveDeactive(row._id,row.postStatus))}> {row.postStatus == 1 ? 'Active' : 'Deactive'}</Button>
                    </TableCell>
                    <TableCell >
                      <Button variant="outlined" color="error" onClick={(()=>postDelete(row._id))}> Delete</Button>
                      <Button variant="contained" color="success" onClick={(()=>postEdit(row))}>Edit </Button>
                    </TableCell>
                  </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </Box>
          </div>

          <Popup visible={visible} component={html} onClose={(() => closePop())} title={title} />

        </div>
      </nav>
    </>
  );
};

export default PostListComponent;
