import { defaultImgUrl } from "../api/impUrl";

export const onImageError = ({ currentTarget }) => {
  currentTarget.onerror = null;
  currentTarget.src = defaultImgUrl;
};

export const getFileExtention = (fileName) => {
  return fileName.split(".").pop();
};

export const isValidURL = (string) => {
  const res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
};

export const checkAuthHeader = (error) => {
  const authHeader = error.config.headers["Authorization"];
  if (error.status == 401 && authHeader == `Bearer undefined`) {
    return true;
  }
  return false;
};
