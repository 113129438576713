import * as Yup from 'yup';

export const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .max(50, 'Too Long!')
    .required('Please Enter First Name!'),
  userFullName: Yup.string()
    .max(50, 'Too Long!')
    .required('Please Enter First Name!'),
  location: Yup.string()
    .max(50, 'Too Long!')
    .required('Please Enter First Name!'),
  website: Yup.string()
    .max(50, 'Too Long!')
    .required('Please Enter First Name!'),
  birthday: Yup.string()
    .max(50, 'Too Long!')
    .required('Please Enter First Name!'),
  uBio: Yup.string()
    .max(50, 'Too Long!')
    .required('Please Enter First Name!'),
  thanksForTip: Yup.string()
    .max(50, 'Too Long!')
    .required('Please Enter First Name!'),

});
export const ProfileSchema = Yup.object().shape({
  f_name: Yup.string()
    .max(50, 'Too Long!')
    .required('Please Enter First Name!'),
  l_name: Yup.string()
    .max(50, 'Too Long!')
    .required('Please Enter Last Name!'),
  email: Yup.string().email('Invalid email').required('Please enter email!'),
  current_password: Yup.string()
    .min(6, 'Too Short!')
    .max(15, 'Too Long!')
    .required('Please enter current password!'),
  new_password: Yup.string()
    .min(6, 'Too Short!')
    .max(15, 'Too Long!')
    .required('Please enter new password!'),
  confirm_password: Yup.string().when("password", {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    )
  }),

});
export const billingAddressForm = Yup.object().shape({
  full_name: Yup.string()
    .max(50, 'Too Long!')
    .required('Please Enter Full Name!'),
  // gender: Yup.string().required('Select the Gender!'),
  address: Yup.string().required('Please Enter Address!'),
  city: Yup.string().required('Please Select City!'),
  // district_town: Yup.string().required('Please Select District!'),
  state: Yup.string().required('Please Select State!'),
  // country: Yup.string().required('Please Select Country!'),
  email: Yup.string().email('Invalid email').required('Please enter email!'),

  phone_no: Yup.string()
    .min(10, 'Mobile Number should be 10 digits!')
    .max(12, 'Mobile Number should be 10 digits!')
    .required('Please Enter 10 digits mobile number!'),
  zip: Yup.string()
    .min(5, 'Zip Code should be 5 digits!')
    .max(10, 'Zip Code should be 10 digits!')
    .required('Please Zip Code!'),


});

export const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Please enter email!'),
  password: Yup.string()
    .min(6, 'Password length must be 8!')
    .max(15, 'Your password will hold 15 number or character!')
    .required('Please Enter Password!'),
});
export const SellerChatSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Please enter email!'),
  name: Yup.string().required('Please enter your name!'),
  message: Yup.string().required('Please enter your message!'),

});
export const CheckOutSchema = Yup.object().shape({
  address_id: Yup.string().required('Please Select Address!'),
  type_of_shiping: Yup.string().required('Please Select Payment Methode!'),

});

export const OtpSchema = Yup.object().shape({
  mobile: Yup.string()
    .min(2, 'Mobile Number length should be 10 digits!')
    .max(50, 'Too Long!')
    .required('Please Enter 10 Digits Mobile Number!'),
  otp: Yup.string()
    .min(4, 'Please Enter 4 Digits OTP!')
    .max(4, 'OTP should be 4 digits!')
    .required('Please Enter 4 Digits OTP!'),
});

export const ForgetMobileSchema = Yup.object().shape({
  emailORmobile: Yup.string()
    .min(2, 'Mobile Number length should be 10 digits!')
    .max(50, 'Too Long!')
    .required('Please Enter 10 Digits Mobile Number or email!'),
});

export const ChanePasswordSchema = Yup.object().shape({
  password: Yup.string().required("This field is required"),
  changepassword: Yup.string().when("password", {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    )
  })
});

export const profileAccountSchema = Yup.object().shape({
  linkAccountGmail: Yup.string().email(),
  linkAccountTwitter: Yup.string(),
  emailNotificationStatus: Yup.boolean(),
  anyoneCanMessage: Yup.boolean(),
  profileStatus: Yup.boolean(),
  currentPassword: Yup.string()
    .min(6, 'Too Short!')
    .max(15, 'Too Long!'),
  // .required('Please enter current password!'),
  password: Yup.string()
    .min(6, 'Too Short!')
    .max(15, 'Too Long!'),
  // .required('Please enter new password!'),
  confirmPassword: Yup.string().when("password", {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    )
  })
});

export const emailUpdateSchema = Yup.object().shape({
  email: Yup.string().email().required('email required!'),
  password: Yup.string()
    .min(6, 'Too Short!')
    .max(15, 'Too Long!')
    .required("password required!"),
})

export const paymentMethodSchema = Yup.object().shape({
  paymentMethod: Yup.string().required("Please select paymenet method"),
  paypalEmail: Yup.string()
    .email('Invalid email').required('Please enter email!'),
  RepaypalEmail: Yup.string().when("paypalEmail", {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("paypalEmail")],
      "Confirmation Email should be same as email"
    )
  }),
  cryptoAddress: Yup.string(),
  recryptoAddress: Yup.string().when("cryptoAddress", {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("cryptoAddress")],
      "Both Field should be same"
    )
  }),
  cryptoCoin:Yup.string().required("Please select coin"),
  bank: Yup.string().required("Please Enter bank detail"),
})

export const subscriptionPackageSchema = Yup.object();

export const adminAddUserSchema = Yup.object().shape({
  gender: Yup.string().required("Please select gender"),
  name: Yup.string().required("Display Name is required"),
  username: Yup.string().required("User Name is required"),
  email:Yup.string().required("Email is required"),
  password:Yup.string().required("Password is required"),
  location:Yup.string().required("Location is required")
})

export const adminPackageSchema = Yup.object().shape({
  packageKey:  Yup.string().required("Package Key must not be empty"),
  packageFund: Yup.string().required("Package fund must not be empty"),
  walletAmount:  Yup.string().required("Waller amount must not be empty"),
})

export const adminStripeSchema = Yup.object().shape({
  scretKey:  Yup.string().required("Secret Key must not be empty"),
  publicKey: Yup.string().required("Public Key must not be empty"),
})

export const WebSiteSettingSchema = Yup.object().shape({
   site_name: Yup.string()
    .max(50, 'Too Long!')
    .required('Please Enter Name!'),
    // site_title: Yup.string()
    // .max(50, 'Too Long!')
    // .required('Please Enter Title!'),
    // site_description: Yup.string()
    // .max(50, 'Too Long!')
    // .required('Please Enter Description!'),
    // Keywords: Yup.string()
    // .max(1000, 'Too Long!')
    // .required('Please Enter Keywords!'),
    // site_logo: Yup.string()
    // .max(50, 'Too Long!')
    // .required('Please Select Logo!'),
    // site_favicon: Yup.string()
    // .max(50, 'Too Long!')
    // .required('Please Select Favicon!'),

});
export const subscriptionPackageSchea = Yup.object()