import React, { useState, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import { useHistory } from "react-router-dom";
import { imageUrl1 } from "../../../api/impUrl";

function Card({ list, action, userDetails, pageSet }) {
  const history = useHistory();
  const [page, setPage] = useState(list?.page);
  const [totalPage, settotalPage] = useState(list?.total);

  useEffect(() => {
    pageSet(page);
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <div>Your Followers {totalPage}</div>
      <br></br>
      <div>
        {list?.friends.length > 0 ? (
          list?.friends?.map((obj) => {
            return (
              <div
                className="container"
                key={obj._id}
                width={"500px"}
                marginRight={"100px"}
              >
                <div>
                  <div>
                    <img
                      src={imageUrl1 + obj?.friendTwo?.profile_image}
                      style={{
                        width: "100px",
                        float: "left",
                        marginRight: "20px",
                      }}
                      alt="user image"
                    />
                  </div>
                  <div style={{ float: "left", marginRight: "20px" }}>
                    <div
                      onClick={() =>
                        history.push(`/profile/${obj?.friendTwo._id}`)
                      }
                    >
                      {obj?.friendTwo?.userFullName}
                    </div>
                    <div>@{obj?.friendTwo?.name}</div>
                  </div>
                  <button onClick={() => action(obj?._id)}>
                    {obj?.followStatus ? "UnFollow" : "Follow"}
                  </button>
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </div>
            );
          })
        ) : (
          <div>No Followers available</div>
        )}
        <div>
          <Pagination count={totalPage} page={page} onChange={handleChange} />
        </div>
      </div>
    </div>
  );
}

export default Card;
