import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import useAdmin from '../../../hooks/useAdmin';
import Box from "@mui/material/Box";
import { notification } from 'antd';
import { SmileOutlined, CloseOutlined } from '@ant-design/icons';
import AdminLeftMenu from '../../admin/adminLeftSideMenu/adminLeftMenuComponent';
import AddPackageForm from "./Forms/AddPackageForm";


const AddPackages = (props) => {
  let history = useHistory();
  const [reload, setReload] = useState(false);
  const { auth } = useSelector((state) => state.auth);
  const { isLogin } = useUser()
  const { createPackages } = useAdmin();

  useEffect(async () => {
    await authCheck();
  }, [reload])

  const authCheck = async () => {
    var response = await isLogin();
    if (parseInt(response.status) === 401) {
      history.push("/login");
    }
  }

  const addNewPackage = async (data) => {
    let res = await createPackages(data);
    if (res) {
      notification.open({
        message: 'Update',
        description: res?.message,
        icon: <SmileOutlined style={{ color: '#108ee9' }} />,
      });
    }
    else {
      notification.open({
        message: 'Error',
        description: res?.data?.message,
        icon: <CloseOutlined style={{ color: '#f4364c' }} />,
      });
    }
    history.push('/packages');
    setReload(!reload)
    return res;
  }

  return (
    <nav>
      <div style={{ display: "flex" }}>
        <div className="sideBar">
          <AdminLeftMenu />
        </div>
        <div style={{ width: "100%" }}>
          <Box sx={{ flexGrow: 1 }}>
            {auth && <AddPackageForm addPackage={(data) => addNewPackage(data)} />}
          </Box>
        </div>
      </div>
    </nav>
  )
};

export default connect((state) => state)(AddPackages);