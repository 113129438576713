import React, { useState, useEffect } from 'react';
import { Button, Card, CardContent, CardHeader, CardMedia, ToggleButton } from '@mui/material'
import Box from "@mui/material/Box";
import AdminLeftMenu from '../../admin/adminLeftSideMenu/adminLeftMenuComponent';
import AddIcon from '@mui/icons-material/Add';
import Switch from '@mui/material/Switch';
import { connect, useSelector } from 'react-redux';
import useUser from '../../../hooks/useUser';
import useAdmin from '../../../hooks/useAdmin';
import { useHistory } from "react-router-dom";
import { notification } from 'antd';
import { SmileOutlined, CloseOutlined } from '@ant-design/icons';

function PackageSetting() {
    const [pageNumber, setPageNumber] = useState(1)
    const [perPage, setPerPage] = useState(10);
    const [reload, setReload] = useState(false);
    const { auth } = useSelector((state) => state.auth);
    const { isLogin } = useUser()
    const { getAllPackages, editPackages, deletePackages } = useAdmin();
    const [packages, setPackages] = useState([])
    let history = useHistory();

    useEffect(async () => {
        await authCheck();
        setPackages(await getAllPackages());
    }, [reload])

    const authCheck = async () => {
        var response = await isLogin();
        if (parseInt(response.status) === 401) {
            history.push("/login");
        }
    }
    const handleChange = async (id, event) => {
        let checked = event.target.checked
        let body = { packageStatus: checked ? 'active' : 'inactive' }
        let res = await editPackages(body, id);
        setReload(!reload)
        if (res.status == 200) {
            notification.open({
                message: 'Update',
                description: res.message,
                icon: <SmileOutlined style={{ color: '#108ee9' }} />,
            });
        }
        else {
            notification.open({
                message: 'Error',
                description: 'Something went wrong...!',
                icon: <CloseOutlined style={{ color: '#f4364c' }} />,
            });
        }
        return res;
    };

    const editPackage = (id) => {
        history.push({
            pathname: "/package_edit",
            state: {
                packageId: id,
            },
        })
    }

    const deletePackage = async(id) => {
        let res = await deletePackages(id);
        setReload(!reload)
        if (res.status == 200) {
            notification.open({
                message: 'Update',
                description: res.message,
                icon: <SmileOutlined style={{ color: '#108ee9' }} />,
            });
        }
        else {
            notification.open({
                message: 'Error',
                description: 'Something went wrong...!',
                icon: <CloseOutlined style={{ color: '#f4364c' }} />,
            });
        }
        return res;
    }

    return (
        <nav>
            <div style={{ display: "flex" }}>
                <div className="sideBar">
                    <AdminLeftMenu />
                </div>
                <h1>Fund Packages Settings</h1>
                <Button onClick={()=>history.push('/package_add')}>
                    <AddIcon />Create a new package</Button>
                {packages && packages?.data?.map((item) => {
                    console.log(item)
                    return (
                        <div style={{ width: "15%", height: "20%" }} key={item?._id}>
                            <Box sx={{ flexGrow: 1 }}>

                                <Card>
                                    <CardHeader>ADD $100</CardHeader>
                                    <CardContent>
                                        <h1>{item?.walletAmount} $</h1>
                                        <Button>Purchase {item?.packageFund}$ for {item?.packageFund}$</Button>
                                    </CardContent>

                                    <Switch
                                        color="primary"
                                        checked={item.packageStatus == 'active'}
                                        onChange={(e) => handleChange(item._id, e)}
                                        name="checked"
                                    />

                                    <Button onClick={() => editPackage(item._id)}>Edit</Button>
                                    <Button onClick={() => deletePackage(item._id)}>Delete</Button>
                                </Card>
                            </Box>
                        </div>
                    )
                })}
            </div>
        </nav>
    )
}

export default connect((state) => state)(PackageSetting);