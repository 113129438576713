import ChatRepository from "../api/ChatRepository";
import { useDispatch } from "react-redux";
import { login, logout } from "../store/auth/action";

export default function useChat() {
  const dispatch = useDispatch();
  return {
    saveUserChats: async (data, multipart = false) => {
      try {
        const responseData = await ChatRepository.saveChats(data, multipart);
        if (responseData.status === 201) {
          return responseData.data;
        }
      } catch (error) {
        throw error;
      }
    },
    getUserConversations: async (limit = 10) => {
      try {
        const responseData = await ChatRepository.getConversations(limit);
        if (responseData.status === 200) {
          return responseData.data;
        }
      } catch (error) {
        throw error;
      }
    },
    getUserChats: async (cid, page = 1, limit = 20) => {
      try {
        const responseData = await ChatRepository.getChats(cid, page, limit);
        if (responseData.status === 200) {
          return responseData.data;
        }
      } catch (error) {
        throw error;
      }
    },
    deleteUserChat: async (id) => {
      try {
        const responseData = await ChatRepository.deleteChat(id);
        if (responseData.status === 200) {
          return responseData.data;
        }
      } catch (error) {
        throw error;
      }
    },
  };
}
