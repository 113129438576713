import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getIn, Field, Formik, Form, ErrorMessage } from "formik";
import InputGroup from "../../../Comman/InputComponent";
import { FormControlLabel } from '@material-ui/core';
import { adminStripeSchema } from "../../../../utils/Validations";
import { Switch, TextField } from 'formik-material-ui';
import { stripe_Currencies } from "../../../../utils/constants";

const StripePaymentForm = ({ makePayment }) => {

    const initialValues = {
        stripeCurrency: "USD($)",
        scretKey: "",
        publicKey: "",
        subscriptionStatus: false,
    }

    return (
        <>
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={adminStripeSchema}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => {
                        makePayment(values);
                        resetForm();
                    }}
                >
                    {({ errors, touched, values, setFieldValue }) => (
                        <Form className="form-group">
                            <br></br>
                            <h1>Add Users</h1>
                            <div className="mt-5">
                                <div className="col-md-4 form-group error-div">
                                    <FormControlLabel
                                        label="Subscription Status"
                                        control={
                                            <Field
                                                type="checkbox"
                                                label="Subscription Status"
                                                name="subscriptionStatus"
                                                component={Switch}
                                                onChange={() => setFieldValue('subscriptionStatus', !values.subscriptionStatus)}
                                                checked={values.subscriptionStatus}
                                            />
                                        }
                                    />

                                    <InputGroup type="text" name="scretKey" label="Test Secret Key"  placeholder="Secret Key"/>
                                    <InputGroup type="text" name="publicKey" label="Test Public Key" placeholder="Public Key" />

                                    <FormControlLabel
                                     label="Stripe Currency"
                                        control={
                                            <Field as="select" name="stripeCurrency" label="Stripe Currency">
                                                {stripe_Currencies.map((e, { index }) => <option key={index} value={e}>{e}</option>)}
                                            </Field>
                                        }
                                    />
                                </div>
                            </div>
                            <div className="settingSubmit"><button type="submit">Save</button></div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default connect((state) => state)(StripePaymentForm);