import AuthRepository from "../api/AuthRepository";
import { useDispatch } from "react-redux";
import { login, logout, updateUserProfile } from "../store/auth/action";
import { loading } from "../store/common/action";
import { useCookies } from "react-cookie";

export default function useUser() {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies();
  return {
    login: async (data) => {
      var responseData = await AuthRepository.UserLogin(data);
      if (responseData.data.status === 200) {
        localStorage.setItem("token", responseData.data.token);
        setCookie("token", responseData.data.token);
        setCookie("userId", responseData.data.data._id);
        dispatch(login(responseData.data));
      }
      return responseData.data;
    },

    googleLogin: async (data) => {
      var responseData = await AuthRepository.UserLoginWithGoogle(data);
      if (responseData.data.status === 200) {
        localStorage.setItem("token", responseData.data.token);
        setCookie("token", responseData.data.token);
        setCookie("userId", responseData.data.data._id);
        dispatch(login(responseData.data));
      }
      return responseData.data;
    },

    register: async (data) => {
      var responseData = await AuthRepository.UserRegistration(data);
      if (responseData.data.status === 200) {
        dispatch(login(responseData.data));
      }
      return responseData.data;
    },

    isLogin: async () => {
      // dispatch(loading(true));
      var responseData = await AuthRepository.isLogin();
      if (responseData) {
        dispatch(login(responseData));
        // dispatch(loading(false));
        return responseData;
      } else {
        return responseData;
      }
    },

    logout: async () => {
      var responseData = await AuthRepository.logout();
      removeCookie("token");
      localStorage.removeItem("token");
      dispatch(logout());
      return true;
    },

    UploaduserImage: async (data) => {
      var responseData = await AuthRepository.uploadUserImage(data);
      if (responseData.status === 200) {
        dispatch(updateUserProfile(responseData.data.data));
        return responseData.data.data;
      }
      return false;
    },

    GetUserProfile: async (username) => {
      var responseData = await AuthRepository.getUserPrifile(username);
      if (responseData.status === 200) {
        dispatch(updateUserProfile(responseData.data.data));
        return responseData.data.data;
      }
      return false;
    },

    IsValidUser: async (id) => {
      var responseData = await AuthRepository.isValidUser(id);
      if (responseData.status === 200) {
        return responseData.data;
      }
      return false;
    },

    GetUserProfile: async (username) => {
      var responseData = await AuthRepository.getUserPrifile(username);
      if (responseData.status === 200) {
        dispatch(updateUserProfile(responseData.data.data));
        return responseData.data.data;
      }
      return false;
    },

    subscriberCount: async () => {
      var responseData = await AuthRepository.subscriberCount();
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },

    updateFollowerStatus: async (id, data) => {
      var responseData = await AuthRepository.updateFollowerStatus(id, data);
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },
  };
}
