import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import { useHistory, useParams } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import { adminLeftMenu } from "../../../utils/menuData";
import ImageComponent from "../../../component/Comman/ImageComponent";
import "./adminLeftMenuComponent.css";
import Divider from '@mui/material/Divider';
import Logo from "../../images/LOGO_Shadows_Blue.png";

const AdminLeftMenu = ({ subscription }) => {
  const history = useHistory();
  const Menu = () => {
    return (
      <>
        <div className="leftSidebar _admin_panel">
          {adminLeftMenu.map((value, key) => {
            return (
              <List sx={{ mr: 2 }}>
                <ListItem
                  button
                  key={key}
                  className="row"
                  id={window.location.pathname == value.url ? "active" : ""}
                  onClick={() => {
                    history.push(value.url);
                  }}
                >
                  
                  <div className="leftMenu-Item">
                    <div
                      className={`svgLeftsidebar svgFor_${value.name}`}
                      dangerouslySetInnerHTML={{ __html: value.Image }}
                    />
                    <div id="name">{value.name}</div>
                  </div>
                </ListItem>
                <Divider />
              </List>
             
            );
          })}
           
        </div>
      </>
    );
  };

  return (
    <>
      <div>{Menu()}</div>
    </>
  );
};

export default AdminLeftMenu;
