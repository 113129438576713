import React, { useEffect, useState } from "react";
import Logo from "../images/LOGO_Shadows_Blue.png";
import "./headerComponent.css";
import {
  AppBar,
  Toolbar,
  Typography,
  // makeStyles,
  styled,
  alpha,
  InputBase,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import useUser from "../../hooks/useUser";
import { useHistory } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import useChat from "../../hooks/useChat";
import useNotification from "../../hooks/useNotification";
import { useSelector } from "react-redux";
import { imageUrl, defaultImgUrl } from "../../api/impUrl";
import { socketUrl } from "../../api/impUrl";
import { onImageError } from "../../utils/helpers";
import moment from "moment";
import "moment-timezone";
import io from "socket.io-client";
import cookies from "js-cookie";
import { checkAuthHeader } from "../../utils/helpers";
import { notification } from "antd";
import AddFundComponent from "./AddFundComponent";

let socket;

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(12),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    cursor: "pointer",
    paddingLeft: "5%",
    paddingright: "5%",
  },
  background: {
    background: "#EFEFEF",
  },
  iconcolor: { color: "#3072E3" },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(2),
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#FFFFFF",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

// const options = ["None", "DashBoard", "Login", "Logout"];

const options = ["Logout", "Admin"];

const ITEM_HEIGHT = 48;

function Navbar() {
  const defaultLimit = 10;
  const classes = useStyles();
  const { auth } = useSelector((state) => state.auth);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isVisibleMsg, setisVisibleMsg] = useState(false);
  const [isVisibleNoti, setisVisibleNoti] = useState(false);
  const [isVisiblePayment, setisVisiblePayment] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [loadingMsg, setLoadingMsg] = useState("");
  const [limit, setLimit] = useState(10);
  const [loginUserId, setLoginUserId] = useState("");
  const [token, setToken] = useState(cookies.get("token") || null);
  const [notifications, setNotifications] = useState([]);
  const [notificationPage, setNotificationPage] = useState(1);
  const [notificationLimit, setNotificationLimit] = useState(defaultLimit);
  // const token = cookies.get("token");
  // console.log("cookie token:", token);

  const open = Boolean(anchorEl);
  const { logout } = useUser();
  const { getUserConversations } = useChat();
  const { getAllNotifications } = useNotification();
  let history = useHistory();

  useEffect(() => {
    if (token) {
      getConversations();
      getNotifications();
    }
    // console.log("cookie token:", token);
  }, [token]);

  useEffect(async () => {
    const userId = auth?._id || "";
    setLoginUserId(userId);
  }, [auth]);

  useEffect(async () => {
    socket = io(socketUrl, { query: `loggeduser=${loginUserId}` });
  }, [socketUrl, loginUserId]);

  useEffect(() => {
    socket.on("message", (data) => {
      if (data.receiver._id == loginUserId) {
        console.log("chat recieved in header !");
        const updatedConvesation = conversations.filter(
          (el) => el.conversationId != data.conversationId
        );
        setConversations([data, ...updatedConvesation]);
      }
    });
    socket.on("notification", (data) => {
      if (data?.forUsers?.includes(loginUserId)) {
        console.log("notification recieved in header !", data);
        const updatedArr = [data, ...notifications];
        setNotifications(updatedArr);
      }
    });
  });

  const getConversations = async () => {
    try {
      setLoadingMsg("Loading Conversation...");
      const response = await getUserConversations(limit);
      if (response.data.length > 0) {
        console.log("conversations:", response.data);
        setConversations(response.data);
      } else {
        setLoadingMsg("Conversation not found !");
      }
    } catch (error) {
      console.log("res err:", error);
      if (checkAuthHeader(error)) {
        getConversations();
      }
      setLoadingMsg("Conversation not found !");
    }
  };

  useEffect(() => {
    console.log("notifications:", notifications);
  }, [notifications]);

  const getNotifications = async () => {
    try {
      const { data } = await getAllNotifications(
        notificationPage,
        notificationLimit
      );
      console.log("notification res:", data);
      setNotifications(data.docs);
      return data;
    } catch (error) {
      console.log("notify err:", error);
      if (checkAuthHeader(error)) {
        getNotifications();
      }
      return false;
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);
    if (await logout()) {
      setTimeout(() => {
        history.push("/login");
      }, 1000);
    }
  };

  const hideShowmessage = () => {
    setisVisibleNoti(false);
    setisVisiblePayment(false);
    if (isVisibleMsg == false) {
      setisVisibleMsg(true);
    } else {
      setisVisibleMsg(false);
    }
    return;
  };

  const hideShownotification = () => {
    setisVisibleMsg(false);
    setisVisiblePayment(false);
    if (isVisibleNoti == false) {
      setisVisibleNoti(true);
    } else {
      setisVisibleNoti(false);
    }
    return;
  };

  const hideShowPayment = () => {
    setisVisibleMsg(false);
    setisVisibleNoti(false);
    if (isVisiblePayment == false) {
      setisVisiblePayment(true);
    } else {
      setisVisiblePayment(false);
    }
    return;
  };

  const redirectToChat = (el) => {
    history.push(`chat/${el._id}`);
  };

  return (
    <Box
      className="navBarComponentWrap container"
      sx={{ flexGrow: 1 }}
      spacing={8}
    >
      <AppBar container position="static" className={classes.background}>
        <Toolbar>
          <Typography
            sx={{ display: "inline" }}
            variant="h4"
            className={classes.logo}
            style={{ flexGrow: 1 }}
          >
            <img
              onClick={() => history.push("/")}
              height="50px"
              width="50px"
              src={Logo}
            ></img>
          </Typography>
          <Search
            className="searchInputStyle"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            <SearchIconWrapper>
              <SearchIcon className={classes.iconcolor} />
            </SearchIconWrapper>
            <StyledInputBase inputProps={{ "aria-label": "search" }} />
          </Search>
          <Box
            sx={{ display: { xs: "none", md: "flex" } }}
            className="menuIconsitems"
          >
            <div className={classes.navlinks}>
              <Link to="/" className={classes.link}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                  fill="none"
                >
                  <path
                    d="M14 35H5.25V17.5H0L17.5 0L35 17.5H29.75V35H21V24.5H14V35Z"
                    fill="#3072E3"
                  />
                </svg>
                <div className="menuIconBottomborder"></div>
              </Link>
              <Link to="/live_treaming" className={classes.link}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="22"
                  viewBox="0 0 34 22"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M33.8957 3.76119C33.8957 1.94311 32.4197 0.467041 30.6016 0.467041H4.25086C2.43278 0.467041 0.956711 1.94311 0.956711 3.76119V17.9477C0.956711 19.7658 2.43278 21.2418 4.25086 21.2418H30.6016C32.4197 21.2418 33.8957 19.7658 33.8957 17.9477V3.76119ZM24.5676 10.8544L11.7884 17.244V4.46481L24.5676 10.8544Z"
                    fill="#C4C4C4"
                    stroke="#EBEBEB"
                    strokeWidth="0.00195408"
                    strokeMiterlimit="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="playIconsBorderstyle playIconsBorderstyleGrey"></span>
                <div className="menuIconBottomborder greyBorderRadious"></div>
              </Link>
            </div>
          </Box>
          <Box sx={{ flexGrow: 1, paddingRight: "5%" }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <div className={classes.navlinks}>
              <Link
                to="#"
                className={`${classes.link} dropdownPosRelative`}
                onClick={hideShowPayment}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="35"
                    viewBox="0 0 34 35"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.0103 0.440918C26.3134 0.440918 33.8663 7.99384 33.8663 17.2969C33.8663 26.6 26.3134 34.1529 17.0103 34.1529C7.70719 34.1529 0.154266 26.6 0.154266 17.2969C0.154266 7.99384 7.70719 0.440918 17.0103 0.440918ZM15.4932 28.6752V26.0367C14.1153 25.9488 13.0013 25.7876 12.1512 25.553C11.301 25.3185 10.5974 25.0986 10.0404 24.8934V20.54H14.8775C14.8775 20.9798 14.9655 21.3059 15.1414 21.5184C15.3173 21.731 15.6837 21.8372 16.2407 21.8372C16.7245 21.8372 17.0689 21.764 17.2741 21.6174C17.4793 21.4708 17.5819 21.2509 17.5819 20.9578C17.5819 20.7526 17.4977 20.573 17.3291 20.4191C17.1605 20.2652 16.8197 20.0856 16.3067 19.8804C15.222 19.47 14.2729 19.0779 13.4594 18.7041C12.6459 18.3303 11.9716 17.9163 11.4366 17.4619C10.9016 17.0075 10.5022 16.4761 10.2383 15.8678C9.97447 15.2595 9.84256 14.523 9.84256 13.6581C9.84256 12.9399 9.94151 12.284 10.1394 11.6903C10.3373 11.0967 10.6561 10.5763 11.0958 10.1292C11.5355 9.68217 12.1145 9.31572 12.8328 9.02989C13.551 8.74407 14.4378 8.54985 15.4932 8.44725V5.91876H18.3735V8.40326C19.0624 8.44724 19.6707 8.51686 20.1984 8.61214C20.7261 8.70742 21.2098 8.81736 21.6495 8.94194C22.0893 9.06654 22.4923 9.19847 22.8588 9.33771C23.2252 9.47697 23.5257 9.61988 23.7602 9.76645V13.7021H19.143C19.143 13.277 19.0257 12.9875 18.7912 12.8336C18.5567 12.6797 18.2709 12.6028 17.9337 12.6028C17.45 12.6028 17.1019 12.6651 16.8894 12.7897C16.6768 12.9143 16.5706 13.1304 16.5706 13.4383C16.5706 13.6874 16.6841 13.8707 16.9113 13.9879C17.1385 14.1052 17.4793 14.2371 17.9337 14.3837C18.7839 14.6622 19.5864 14.9554 20.3413 15.2632C21.0962 15.571 21.7594 15.9521 22.3311 16.4065C22.9028 16.8609 23.3535 17.4106 23.6833 18.0555C24.0131 18.7005 24.178 19.492 24.178 20.4301C24.178 22.1157 23.687 23.424 22.7049 24.3548C21.7228 25.2855 20.279 25.8389 18.3735 26.0148V28.6752H15.4932Z"
                      fill="#3072E3"
                      stroke="#EBEBEB"
                      strokeWidth="0.00125075"
                      strokeMiterlimit="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <div
                  className="notification_dropdown"
                  style={{
                    display: isVisiblePayment == false ? "none" : "block",
                  }}
                >
                  <AddFundComponent />
                </div>
              </Link>
              <Link
                to="#"
                className={`${classes.link} dropdownPosRelative`}
                onClick={hideShowmessage}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="31"
                    viewBox="0 0 35 31"
                    fill="none"
                  >
                    <path
                      d="M24.2871 19.3907C26.3351 19.3907 28.0009 17.7246 28.0009 15.6769V4.43348C28.0009 2.38557 26.3351 0.719238 24.2871 0.719238H3.7133C1.66574 0.719238 0 2.38557 0 4.43348V15.6769C0 17.7246 1.66574 19.3907 3.7133 19.3907H6.41584V23.2782C6.41584 23.751 6.70056 24.177 7.13717 24.3579C7.28189 24.4177 7.43367 24.4467 7.58404 24.4467C7.88808 24.4467 8.18705 24.328 8.41061 24.1044L13.1234 19.3907H24.2871V19.3907Z"
                      fill="#3072E3"
                    />
                    <path
                      d="M31.7493 10.4424H29.8703V15.6768C29.8703 18.7555 27.3658 21.2601 24.2871 21.2601H13.8977L11.7132 23.4452C11.9765 24.9756 13.312 26.1441 14.9164 26.1441H23.9624L27.756 29.9382C27.9794 30.1618 28.2785 30.2805 28.5825 30.2805C28.7329 30.2805 28.8847 30.2512 29.0294 30.1915C29.466 30.0106 29.7506 29.5847 29.7506 29.112V26.1439H31.7494C33.5419 26.1439 35.0001 24.6853 35.0001 22.8925V13.6934C35 11.9008 33.5418 10.4424 31.7493 10.4424Z"
                      fill="#3072E3"
                    />
                  </svg>
                </span>
                <div
                  className="notification_dropdown"
                  style={{ display: isVisibleMsg == false ? "none" : "block" }}
                >
                  <div className="i_general_box_notifications_container generalBox">
                    <div className="btest">
                      <div className="i_user_details">
                        <div className="i_header_others_box">
                          {/* message conversation */}
                          {conversations.length > 0 ? (
                            conversations.map((el) => {
                              let showUser = {};
                              if (el.sender._id == loginUserId) {
                                showUser = el.receiver;
                              } else {
                                showUser = el.sender;
                              }
                              return (
                                <div
                                  key={el._id}
                                  onClick={() => redirectToChat(el)}
                                  className="i_message_wrpper"
                                >
                                  <a>
                                    <div class="i_box_messages_header">
                                      Messages{" "}
                                      <div class="i_message_full_screen transition">
                                        <a href="https://betax.crator.com/chat">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18.92"
                                            height="18.958"
                                            viewBox="0 0 18.92 18.958"
                                          >
                                            <g
                                              id="_03-17"
                                              data-name="03-17"
                                              transform="translate(-138.137 -363.847)"
                                            >
                                              <path
                                                id="Path_882"
                                                data-name="Path 882"
                                                d="M277.58,505.1c-.2,0-.4,0-.59,0a.677.677,0,0,1-.67-.674.67.67,0,0,1,.666-.677c.407-.008.815-.006,1.223,0,.113,0,.142-.03.14-.141-.007-.388,0-.775,0-1.163a.677.677,0,1,1,1.351,0c0,.366.012.734,0,1.1-.007.176.056.2.211.2.365-.011.734-.005,1.1,0a.678.678,0,1,1,.006,1.353c-.387,0-.774.005-1.16,0-.119,0-.153.032-.151.151.007.387.005.775,0,1.162a.677.677,0,1,1-1.351-.006c0-.374-.007-.747,0-1.12,0-.141-.023-.2-.179-.188C277.975,505.117,277.777,505.1,277.58,505.1Z"
                                                transform="translate(-131.425 -131.102)"
                                                fill="#8a96a3"
                                              ></path>
                                              <path
                                                id="Path_883"
                                                data-name="Path 883"
                                                d="M158.676,381.757h1.287a.678.678,0,1,1-.006,1.353c-.591,0-1.181.006-1.772,0-.15,0-.2.025-.2.191.012.591.006,1.185,0,1.775a.678.678,0,1,1-1.351,0V382.5a.677.677,0,0,1,.734-.739C157.8,381.756,158.241,381.757,158.676,381.757Z"
                                                transform="translate(-18.5 -17.91)"
                                                fill="#8a96a3"
                                              ></path>
                                              <path
                                                id="Path_884"
                                                data-name="Path 884"
                                                d="M422.073,381.763h1.287a.676.676,0,0,1,.73.72q0,1.31,0,2.62a.677.677,0,1,1-1.35-.006v-.339q-.006-.812-.013-1.623c-.545-.047-1.092-.007-1.637-.02h-.316a.678.678,0,1,1-.006-1.352C421.2,381.761,421.637,381.763,422.073,381.763Z"
                                                transform="translate(-267.034 -17.915)"
                                                fill="#8a96a3"
                                              ></path>
                                              <path
                                                id="Path_885"
                                                data-name="Path 885"
                                                d="M158.586,649.75H157.3a.675.675,0,0,1-.727-.722q0-1.31,0-2.621a.674.674,0,0,1,.669-.717.685.685,0,0,1,.681.727c0,.592.006,1.185,0,1.775,0,.157.027.213.2.21.59-.013,1.181-.006,1.772,0a.679.679,0,1,1,0,1.354Z"
                                                transform="translate(-18.434 -266.947)"
                                                fill="#8a96a3"
                                              ></path>
                                              <path
                                                id="Path_886"
                                                data-name="Path 886"
                                                d="M424.061,647.715v1.31a.67.67,0,0,1-.715.714q-1.308,0-2.616,0a.678.678,0,1,1,0-1.353c.6,0,1.209-.005,1.814,0,.141,0,.173-.039.169-.175-.007-.6,0-1.2,0-1.8a.678.678,0,1,1,1.351.007C424.062,646.855,424.061,647.285,424.061,647.715Z"
                                                transform="translate(-267.005 -266.937)"
                                                fill="#8a96a3"
                                              ></path>
                                              <path
                                                id="Path_887"
                                                data-name="Path 887"
                                                d="M204.455,431.409c0-.331,0-.663,0-.993a.667.667,0,0,1,.687-.7q1-.007,2,0a.675.675,0,1,1-.007,1.349c-.38,0-.76.008-1.139,0-.149,0-.207.021-.2.19.015.372.006.747,0,1.12a.677.677,0,1,1-1.351,0C204.453,432.057,204.455,431.733,204.455,431.409Z"
                                                transform="translate(-63.615 -63.162)"
                                                fill="#8a96a3"
                                              ></path>
                                              <path
                                                id="Path_888"
                                                data-name="Path 888"
                                                d="M387.457,431.434c0,.325,0,.648,0,.972a.676.676,0,1,1-1.35-.005c0-.359-.016-.72.005-1.077.013-.214-.06-.253-.254-.243-.351.017-.7.006-1.054,0a.682.682,0,0,1-.724-.684.673.673,0,0,1,.717-.669q.97,0,1.94,0a.676.676,0,0,1,.72.73C387.461,430.786,387.457,431.11,387.457,431.434Z"
                                                transform="translate(-233.103 -63.176)"
                                                fill="#8a96a3"
                                              ></path>
                                              <path
                                                id="Path_889"
                                                data-name="Path 889"
                                                d="M206.126,613.093c-.33,0-.661,0-.991,0a.672.672,0,0,1-.692-.694q-.007-.994,0-1.987a.675.675,0,1,1,1.349,0c0,.4.006.79,0,1.185,0,.118.03.154.15.151.387-.008.774,0,1.16,0a.678.678,0,1,1,0,1.353C206.773,613.094,206.45,613.093,206.126,613.093Z"
                                                transform="translate(-63.602 -232.998)"
                                                fill="#8a96a3"
                                              ></path>
                                              <path
                                                id="Path_890"
                                                data-name="Path 890"
                                                d="M387.466,611.354c0,.331,0,.662,0,.993a.67.67,0,0,1-.69.7q-1,.008-2,0a.676.676,0,1,1,.011-1.351c.387,0,.773-.007,1.16,0,.133,0,.18-.025.175-.169-.011-.38-.006-.761,0-1.141a.677.677,0,1,1,1.351,0C387.47,610.706,387.466,611.033,387.466,611.354Z"
                                                transform="translate(-233.113 -232.95)"
                                                fill="#8a96a3"
                                              ></path>
                                            </g>
                                          </svg>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="i_message_wrapper transition">
                                      <div className="i_message_owner_avatar">
                                        <div className="i_message_avatar">
                                          {showUser.profile_image ? (
                                            <img
                                              src={
                                                imageUrl +
                                                showUser.profile_image
                                              }
                                              alt="Mobile user"
                                              onError={(e) => onImageError(e)}
                                            />
                                          ) : (
                                            <img
                                              src={defaultImgUrl}
                                              alt="Mobile user"
                                              onError={(e) => onImageError(e)}
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div className="i_message_info_container">
                                        <div className="i_message_owner_name">
                                          {showUser.name}
                                          <div className="i_plus_g">
                                            {moment(
                                              el.messageBody.createdAt
                                            ).fromNow()}
                                          </div>
                                        </div>
                                        <div className="i_message_i">
                                          {el.messageBody.message}
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              );
                            })
                          ) : (
                            <h1 style={{ textAlign: "center" }}>
                              {loadingMsg}
                            </h1>
                          )}
                        </div>
                      </div>
                      <div className="footer_container messages">
                        <Link to="/chat">See all in messenger</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                to="#"
                className={`${classes.link} dropdownPosRelative`}
                onClick={hideShownotification}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="35"
                    viewBox="0 0 29 35"
                    fill="none"
                  >
                    <path
                      d="M10.351 30.8229C10.3509 30.832 10.3503 30.8411 10.3503 30.8502C10.3503 33.1421 12.2082 35 14.5001 35C16.7919 35 18.6498 33.1421 18.6498 30.8502C18.6498 30.8411 18.6492 30.832 18.6491 30.8229H10.351Z"
                      fill="#3072E3"
                    />
                    <path
                      d="M27.7493 25.6357L24.0145 20.1493C24.0145 18.4698 24.0145 14.3661 24.0145 13.3045C24.0145 8.68839 20.7271 4.84058 16.3658 3.97346V1.86569C16.3657 0.835351 15.5304 0 14.5 0C13.4696 0 12.6343 0.835351 12.6343 1.86569V3.97354C8.273 4.84074 4.98554 8.68855 4.98554 13.3046C4.98554 15.1117 4.98554 19.102 4.98554 20.1494L1.25073 25.6357C0.857326 26.2136 0.815722 26.9616 1.14261 27.5796C1.46949 28.1976 2.11123 28.584 2.81034 28.584H26.1896C26.8888 28.584 27.5305 28.1975 27.8574 27.5796C28.1843 26.9616 28.1427 26.2135 27.7493 25.6357Z"
                      fill="#3072E3"
                    />
                  </svg>
                </span>
                <div
                  className="notification_dropdown"
                  style={{ display: isVisibleNoti == false ? "none" : "block" }}
                >
                  <div className="i_general_box_notifications_container generalBox">
                    <div className="btest">
                      <div className="i_user_details">
                        <div className="i_header_others_box">
                          {/*MESSAGE*/}
                          {notifications?.map((notify, index) => {
                            return (
                              <div
                                key={notify._id}
                                className="i_message_wrpper hidNot_319"
                              >
                                <a href="https://betax.crator.com/mukeshflow">
                                  <div className="i_message_wrapper transition">
                                    <div className="i_message_owner_avatar">
                                      <div className="i_message_not_icon flex_ tabing">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlnsXlink="http://www.w3.org/1999/xlink"
                                          version="1.1"
                                          id="Capa_2"
                                          x="0px"
                                          y="0px"
                                          viewBox="0 0 66 64"
                                          style={{
                                            enableBackground: "new 0 0 66 64",
                                          }}
                                          xmlSpace="preserve"
                                        >
                                          <path
                                            id="Subscribers_Active"
                                            d="M33,0.8C15.8,0.8,1.8,14.8,1.8,32s14,31.2,31.2,31.2s31.2-14,31.2-31.2S50.2,0.8,33,0.8z   M47.4,41.5C43.1,46.8,33,53.1,33,53.1s-5.4-3.6-12.1-9.1C14.2,38.6,7,24.6,16,17.8S33,19.9,33,19.9c1.7-2.7,10-9.2,17.7-1.2  S51.8,36.2,47.4,41.5z"
                                          />
                                        </svg>
                                      </div>
                                      <div className="i_message_avatar">
                                        <img
                                          src={`${imageUrl}${notify.notificationImg}`}
                                          alt="Mukesh kushwah flow check"
                                          onError={(e) => onImageError(e)}
                                        />
                                      </div>
                                    </div>
                                    <div className="i_message_info_container">
                                      <div className="i_message_owner_name">
                                        {notify.title}
                                      </div>
                                      <div className="i_message_i">
                                        {notify.body}
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            );
                          })}
                          {/*/MESSAGE*/}
                        </div>
                      </div>
                      <div className="footer_container messages">
                        <a href="https://betax.crator.com/notifications">
                          See all notifications
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    {/* <MoreVertIcon /> */}
                    <DragIndicatorIcon className="dragIndicatorIcons" />
                  </IconButton>
                  <Menu
                    className="subMenuforDragindicator"
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <span key={option}>
                        <MenuItem
                          key={option}
                          selected={option === "Logout"}
                          onClick={handleClose}
                        >
                          {option == "Logout" && (
                            <>
                              <LogoutIcon /> &nbsp;LogOut
                            </>
                          )}
                        </MenuItem>
                        <MenuItem
                          key={option}
                          onClick={() => history.push("admin")}
                        >
                          {option == "Admin" && <span>Admin</span>}
                        </MenuItem>
                      </span>
                    ))}
                  </Menu>
                </div>
              </Box>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
export default Navbar;
