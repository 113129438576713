import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useUser from "../../hooks/useUser";
import useHome from "../../hooks/useHome";
import Navbar from "../Header/headerComponent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import './UnsubscribedProfile.css'

const UnsubscribedProfile = () => {

    const { auth } = useSelector((state) => state)
    let history = useHistory();
    const { isLogin } = useUser()

    useEffect(async () => {
        await authCheck();
    }, [history]);

    const authCheck = async () => {
        var response = await isLogin();
        if (parseInt(response.status) === 401) {
            history.push("/login");
        } else {
            //history.push("/");
        }
    };

    return (
        <>
            <nav>
                <div className="headerSection">
                    <Navbar />
                </div>
            </nav>
            <div className="ProfileBannersection">
                <img src="https://via.placeholder.com/920X550&text=User%20profile%20banner" />
                <PhotoCameraIcon className="cameraIconBanner" />
            </div>
            <div className="userProfileInfoarea">
                <div className="userProfileInfocolumns">
                    <div className="userProfileInfocolumn1">
                        <div className="userProfileMain">
                            <span className="userProfileCamera">
                                <PhotoCameraIcon className="userProfileCameraIcon" />
                            </span>
                            <img className="userProfileImg" src="https://via.placeholder.com/150X150/09f/fff&text=User" />
                        </div>
                        <div className="userNameDatainfo">
                            <h2>Jesús Guzman A.</h2>
                            <h3>Top Djs Magazine</h3>
                        </div>
                    </div>
                    <div className="userProfileInfocolumn2">
                        <div className="userIdActions">
                            <div className="addTrailerbtn">
                                <p>@Music_4Ever</p>
                            </div>
                            <div className="Profileactions">
                                <div className="Profileactionsbtns">
                                    <StarBorderPurple500Icon />
                                    <ShareIcon />
                                </div>
                            </div>
                        </div>
                        <div className="ProfileactionsText flexAlignmiddle">
                            <p><strong>890</strong> Subscribers</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="greyBackgroundColcor">
                <div className="container paddingTwenty">
                    <div className="borderProfileTop" style={{ borderTop: '1px solid #535353', width: '600px', margin: '0px auto', height: '0px', marginBottom: 15 }}></div>
                    <div className="wrapHomeSection">
                        <div className="leftSideMenu greyBackgroundColcor">
                            <div className="importLinkmenu impLinksubscribedpage">
                                <div><a>Privacy Policies</a><a>Crator© 2022</a></div>
                                <div><a>Terms Of Use</a><a>Cookies</a></div>
                            </div>
                        </div>
                        <div className="middlePostFeedsWrap">
                            <div className="leftIconsPostsFeeds">
                                <div className="playTrailer">
                                    <span className="playIconTrailer"></span>
                                    <h4>Play Trailer</h4>
                                </div>
                                <div className="sendMessageUser">
                                    {/* <span className="sendMessageUserIcon"></span> */}
                                    <div class="postActionMessageSub"><span class="messageiconSub_1"></span><span class="messageiconSub_2"></span></div>
                                    <h4>Send a message to USER</h4>
                                </div>
                                <div className="ScheduleCallmain">
                                    <span className="ScheduleCallIcon"></span>
                                    <h4>Schedule a private video call</h4>
                                </div>
                            </div>
                            <div className="rightPostFeedsMain unsubscribed_profileMain">
                                <div className="subscribrBluehilighter"><FavoriteBorderIcon /><h4>SUBSCRIBE TO UNLOCK USER’S POSTS</h4><FavoriteBorderIcon /></div>
                                <div className="middleSection">
                                    {/* <div>
                                <CreatePostComponent packages={packages} createPost={((event) => createPost(event))} />
                            </div> */}
                                    <div className="postArea postAreaUnsubscribed">
                                        <Grid container style={{ width: "100%", margin: "0 auto" }} margin="dense">
                                            <Grid item xs={12}>
                                                <Paper variant="outlined" style={{ height: 'auto', margin: 0 }}>
                                                    {/* {post && postCount > 0 ? (
                                                        <PostComponent updatePostData={(() => updatePostData())} updateWhoCanSee={((data) => updateWhoCanSee(data))} packages={packages} deletePost={((id) => deletePost(id))} updatePost={((event) => updatePost(event))} posts={post} />

                                                    ) : (
                                                        "Loading..."
                                                    )} */}
                                                    <div className="greyBroderCTP">
                                                        <h2>Why this is so far?</h2>
                                                    </div>
                                                    <div className="unSubscribedTextContent">
                                                        <p>"Chefs don't make mistakes; they make new dishes." - Elizabeth Brigg</p>
                                                        <h4>What Is Cooking? Learn the Art of Preparing Food and All the Cooking Techniques You Should Know</h4>
                                                        <p>"We’ve been told numerous times that our cooking is 'feminine,' which people always intend as a compliment. We’re happy to be identified with femaleness, as strong and proud women, but the masculine/feminine binary has no legitimate place in culinary expression or its criticism. It’s an artificial construct we’d be happy to see done away with." - Sarah Kramer & Sarah Hymanson</p>
                                                    </div>
                                                    <div className="greyBroderCTP">
                                                        <h2>Other Hobbies</h2>
                                                    </div>
                                                    <div className="myHobbiesWrap">
                                                        <div className="myHobbiesItem">
                                                            <span className="drawIcon"></span>
                                                            <p>Draw</p>
                                                        </div>
                                                        <div className="myHobbiesItem">
                                                            <span className="VideogamesIcon"></span>
                                                            <p>Videogames</p>
                                                        </div>
                                                        <div className="myHobbiesItem">
                                                            <span className="WorkoutIcon"></span>
                                                            <p>Workout</p>
                                                        </div>
                                                        <div className="myHobbiesItem">
                                                            <span className="PartyIcon"></span>
                                                            <p>Party</p>
                                                        </div>
                                                        <div className="myHobbiesItem">
                                                            <span className="POPMusicIcon"></span>
                                                            <p>POP Music</p>
                                                        </div>
                                                        <div className="myHobbiesItem">
                                                            <span className="BikeIcon"></span>
                                                            <p>Bike</p>
                                                        </div>
                                                    </div>
                                                    <div className="greyBroderCTP">
                                                        <h2>Featured photos and more of what I love</h2>
                                                    </div>
                                                    <div className="FeaturedPhotosMain">
                                                        <div className="FeaturedPhotoesItem">
                                                            <img src="https://via.placeholder.com/290x290.png?text=Crator" />
                                                            <img src="https://via.placeholder.com/290x290.png?text=Crator" />
                                                            <img src="https://via.placeholder.com/290x290.png?text=Crator" />
                                                        </div>
                                                        <div className="FeaturedPhotoesItem">
                                                            <img src="https://via.placeholder.com/290x290.png?text=Crator" />
                                                            <img src="https://via.placeholder.com/290x290.png?text=Crator" />
                                                            <img src="https://via.placeholder.com/290x290.png?text=Crator" />
                                                        </div>
                                                    </div>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="leftSideMenu greyBackgroundColcor">
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UnsubscribedProfile;
