import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import CreatePostComponent from "../Home/Middle/Post/CreatePostComponent";
import useUser from "../../hooks/useUser";
import useHome from "../../hooks/useHome";
import ProfileLeftMenu from "./ProfileLeftMenu";
import RightSidebarComponents from "../Home/RighSideMenu/RightSidebarComponents";
import PostComponent from "./Middle/Post/PostComponent";
import Navbar from "../Header/headerComponent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Story from "./StoryComponent";
import Loading from "../Comman/PostLoader";
import "./HomeComponent.css";

const HomeComponent = (props) => {
  const { id } = useParams();
  const { auth } = useSelector((state) => state);
  const [post, setPost] = useState([]);
  const [packages, setPackages] = useState([]);
  const [postCount, setPostCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [subscription, setSubscription] = useState({});
  const [showProfile, setShowProfile] = useState(false);
  const [profileInfo, setProfileInfo] = useState([]);
  const [isBookMark, setBookMark] = useState(false);

  const userDetail = auth.auth;
  let history = useHistory();
  const { isLogin } = useUser();

  const {
    getSavedPost,
    subscribeUsers,
    getPost,
    CreatePost,
    EditPost,
    DeletePost,
    GetPackages,
    ChangeWhoCanSee,
    getProfileInfo,
  } = useHome();

  useEffect(async () => {
    var {
      match: { params },
    } = props;
    await authCheck();
    var post = await getPost(1);
    if (params.type) {
      setBookMark(true);
      var post1 = await getSavedPost();
    }
    await setPost(post.posts);
    var packageList = await GetPackages();
    await setPackages(packageList);
  
    if (id) {
      var profileInfo = await getProfileInfo(id);
      setProfileInfo(profileInfo);
      setShowProfile(true);
    }
  }, [history]);
  const authCheck = async () => {
    var response = await isLogin();
  };
  console.log('subscription',subscription)
  const createPost = async (event, data) => {
    const formData = new FormData();
    if (data.file) {
      formData.append("postFile", data.file);
    }
    formData.append("postText", data.post);
    formData.append("whoCanSee", data.who_can_see);
    formData.append("type", data.type);
    formData.append("urlSlug", data.post.replace(" ", "-"));
    formData.append("postCreatorIp", 1234);
    formData.append("postWantStatus", "normal");
    formData.append("postStatus", 1);
    formData.append("commentStatus", 1);

    var response = await CreatePost(formData);
    if (response) {
      post.unshift(response);
      await setPostCount(Math.floor(Math.random() * 100));
      await setPost(post);
    }
  };

  const updatePost = async (data) => {
    var response = await EditPost(data.name, data.id);
    if (response) {
      var updatedPost = post.filter((el) => {
        if (el._id !== data.id) {
          return el;
        }
      });
      updatedPost.unshift(response);
      await setPostCount(Math.floor(Math.random() * 100));
      await setPost(updatedPost);
      return response;
    }
  };

  const deletePost = async (id) => {
    var response = await DeletePost(id);
    if (response) {
      var updatedPost = post.filter((el) => {
        if (el._id !== id) {
          return el;
        }
      });
      await setPostCount(Math.floor(Math.random() * 100));
      await setPost(updatedPost);
      return response;
    }
  };

  const updateWhoCanSee = async (data) => {
    await ChangeWhoCanSee(data);
  };

  const updatePostData = async () => {
    var post = await getPost();
    await authCheck();
    await setPost(post.posts);
  };

  const loadMore = async (page) => {
    await setCurrentPage(page);
    var respost = await getPost(page);
    if (respost.posts.length > 0) {
      await setIsLoadMore(true);
    } else {
      await setIsLoadMore(false);
    }
    var currentPost = post;
    await setPost([...currentPost, ...respost.posts]);
  };

  return (
    <>
      <nav>
        <div className="headerSection">
          <Navbar />
        </div>
      </nav>
      <div className="container">
        <div className="main_wrp">
          <div className="leftSideMenu">
          <div className="leftSideinner">
            <ProfileLeftMenu  />
          </div>
          </div>
          <div className="middleSection">
            <div>
              <Story />
            </div>
            <div>
              {!showProfile && (
                <CreatePostComponent
                  user={userDetail}
                  packages={packages}
                  createPost={(event, fileObj) => createPost(event, fileObj)}
                />
              )}
              {showProfile && (
                <div>
                  profile section here
                  {console.log(profileInfo)}
                </div>
              )}
            </div>
            <div className="postArea">
              <Grid
                container
                style={{ width: "100%", margin: "0 auto" }}
                margin="dense"
              >
                <Grid item xs={12}>
                  {post.length > 0 ? (
                    <Paper
                      variant="outlined"
                      style={{ height: 100, margin: 0 }}
                    >
                      {post.length > 0 && postCount > 0 ? (
                        <PostComponent
                          updatePostData={() => updatePostData()}
                          updateWhoCanSee={(data) => updateWhoCanSee(data)}
                          packages={packages}
                          deletePost={(id) => deletePost(id)}
                          updatePost={(event) => updatePost(event)}
                          posts={post}
                          loadMore={(page) => loadMore(page)}
                          currentPage={currentPage}
                          isLoadMore={isLoadMore}
                        />
                      ) : (
                        <Loading />
                      )}
                    </Paper>
                  ) : (
                    <Loading />
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="rightSideMenu">
            <div className="leftSideinner">
              <RightSidebarComponents />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeComponent;
