import React, { useEffect, useState } from "react";
import TextPost from "./TextPost";
import Card from '@mui/material/Card';
import CommentCreate from './CreateComment';
import InfiniteScroll from "react-infinite-scroll-component";
import PostLoader from '../../../Comman/PostLoadLoader'

const PostComponent = ({isLoadMore,currentPage,loadMore, posts, updatePost, deletePost, packages, updateWhoCanSee, updatePostData }) => {
    const [postLlist, setPostList] = useState([]);
    const [length , setLength] = useState(10)
    const [isVisibleComments, setisVisibleComments] = useState(false);
    const onChangeMessageEvent = () => {
        if (isVisibleComments === false) {
            setisVisibleComments(true);
        } else {
            setisVisibleComments(false);
        }
    }

    const fetchMoreData = async() =>{
        console.log('currentPage',currentPage+1)
        var page = currentPage ? currentPage+1:2
        await loadMore(page)
    }
    console.log('isLoadMore',isLoadMore)
    useEffect(async () => {
        await setPostList(posts)
    }, [posts, postLlist,isLoadMore])

    const post = () => {
        return (
            <>
                <InfiniteScroll
                    dataLength={postLlist.length}
                    next={fetchMoreData}
                    hasMore={isLoadMore}
                    loader={<PostLoader/>}
                >
                    {postLlist.map((value, key) => (
                        <Card key={key} variant="outlined" className="postListmain">
                            <TextPost 
                                updatePostData={(()=>updatePostData())}
                                onChangeMessageEvent={onChangeMessageEvent} 
                                updateWhoCanSee={((data) => updateWhoCanSee(data))} 
                                packages={packages} 
                                deletePost={((id) => deletePost(id))} 
                                updatePost={((event) => updatePost(event))} 
                                key={key} 
                                post={value} 
                            />
                            <CommentCreate isVisibleComments={isVisibleComments} updatePostData={(() => updatePostData())} post={value} />
                        </Card>
                    ))}
                </InfiniteScroll>    
            </>
        )
    }

    return (
        <>
            <div>
                {post()}
            </div>
        </>
    )
}

export default PostComponent;