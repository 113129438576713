import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Formik, Form, Field } from 'formik';
import { SignupSchema } from '../../../../utils/Validations'
import * as Yup from 'yup';

const ProfileUpdateForm = ({ userDetails, update }) => {
    const [formData, setFormdata] = useState({})
    
    useEffect(async () => {
        await setFormdata(userDetails)
    }, [userDetails])

    return (
        <>
            <div>
                {formData ?
                    <Formik
                        initialValues={{
                            name: userDetails.name,
                            userFullName: userDetails.userFullName,
                            location: userDetails.location,
                            website: userDetails.website,
                            birthday: userDetails.birthday,
                            uBio: userDetails.uBio,
                            thanksForTip: userDetails.thanksForTip,
                        }}
                        validationSchema={SignupSchema}
                        enableReinitialize
                        onSubmit={(res, {resetForm}) => {
                            update(res);
                            resetForm();
                        }}
                    >
                        {({ errors, touched, values }) => (
                            <Form className="form-group">
                                <div className="settingFormFields">
                                    <div className="col-md-4 form-group error-div">
                                        <div className="formLabel">
                                            <label>Display Name</label>
                                        </div>
                                        <div className="formField">
                                            <Field
                                                type="text"
                                                name="userFullName"
                                                className="form-control form-control-sm"
                                                placeholder="Enter First Name" />
                                            <p style={{ color: "red", fontSize: "14px" }}>{errors.userFullName}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 form-group error-div">
                                        <div className="formLabel"><label>Username</label></div>
                                        <div className="formField">
                                            <Field
                                                type="text"
                                                name="name"
                                                id="name"
                                                className="form-control form-control-sm"
                                                placeholder="Enter First Name" />
                                            <p style={{ color: "red", fontSize: "14px" }}>{errors.name}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 form-group error-div">
                                        <div className="formLabel"><label>Location:</label></div>
                                        <div className="formField">
                                            <Field
                                                type="text"
                                                name="location"
                                                className="form-control form-control-sm"
                                                placeholder="location" />
                                            <p style={{ color: "red", fontSize: "14px" }}>{errors.locations}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 form-group error-div">
                                        <div className="formLabel"><label>website:</label></div>
                                        <div className="formField">
                                            <Field
                                                type="text"
                                                name="website"
                                                className="form-control form-control-sm"
                                                placeholder="Enter First name" />
                                            <p style={{ color: "red", fontSize: "14px" }}>{errors.website}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 form-group error-div">
                                        <div className="formLabel"><label>birthday:</label></div>
                                        <div className="formField">
                                            <Field
                                                type="date"
                                                name="birthday"
                                                className="form-control form-control-sm"
                                                placeholder="Enter First name" />
                                            <p style={{ color: "red", fontSize: "14px" }}>{errors.birthday}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 form-group error-div">
                                        <div className="formLabel"><label>User Bio:</label></div>
                                        <div className="formField biodata">
                                            <Field
                                                type="text"
                                                name="uBio"
                                                className="form-control form-control-sm"
                                                placeholder="Enter First name" />
                                            <p style={{ color: "red", fontSize: "14px" }}>{errors.uBio}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 form-group error-div">
                                        <div className="formLabel"><label>Thanks For Tip:</label></div>
                                        <div className="formField">
                                            <Field
                                                type="text"
                                                name="thanksForTip"
                                                className="form-control form-control-sm"
                                                placeholder="Enter First name" />
                                            <p style={{ color: "red", fontSize: "14px" }}>{errors.thanksForTip}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="settingSubmit"><button type="submit">Save</button></div>
                            </Form>
                        )}
                    </Formik>
                    : ''}
            </div>
        </>
    );
};

export default connect((state) => state)(ProfileUpdateForm);