import React, { useEffect, useState } from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { connect } from "react-redux";
import Home from "../component/Home/HomeComponent";
import { useHistory } from "react-router-dom";
import Login from "../component/Auth/Login";
import Register from "../component/Auth/RegisterCompnent";
import useUser from "../hooks/useUser";
import Subscriber from "../component/Home/LeftMenu/Subscriber";
import Dashboard from "../component/Home/LeftMenu/Dashboard";
import Followers from "../component/Settings/Account/Followers";
import Following from "../component/Settings/Account/Following";
import Subscribers from "../component/Settings/Account/Subscribers";
import Subscrptions from "../component/Settings/Account/Subscrptions";
import AccountSettings from "../component/Settings/Account/AccountSettings";
import ProfileSettings from "../component/Settings/Account/ProfileSettings";
import OurProfile from "../component/Settings/OurProfile";
import SubscribedProfile from "../component/Settings/SubscribedProfile";
import UnsubscribedProfile from "../component/Settings/UnsubscribedProfile";
import UnsubscribedProfileTrailer from "../component/Settings/UnsubscribedProfileTrailer";
import SubscriptionPackage from "../component/Settings/Creator/SubscriptionPackage";
import PaymentMethods from "../component/Settings/Creator/PaymentMethods";
import PaymentHistory from "../component/Settings/Creator/PaymentHistory";
import Payment from "../component/Payment/PaymentComponent";
import RecivedPayment from "../component/Settings/Creator/RecivedPayment";
import FinalisedEarning from "../component/Settings/Creator/FinalisedEarning";
import SubscriptionPayment from "../component/Settings/Creator/SubscrptionPayment";
import BookmarkComponent from "../component/Home/Bookmark/BookmarkComponent";
import PremiumComponent from "../component/Home/Premiumpost/PremiumPostComponent";
import WalletCredits from "../component/Settings/Account/WalletCredits";
import LiveStreamingComponent from "../component/LiveStream/LiveStreamingComponent";
import SettingDashboard from "../component/Settings/Creator/Dashboard";
import UserChat from "../component/UserChat/UserChat";
import DashboardComponent from "../component/admin/DashboardComponent";
import PostListComponent from "../component/admin/postList/PostListComponent";
import WebsiteSettingComponent from "../component/admin/settings/website/WebsiteSettingComponent";
import GeneralSettingsComponent from "../component/admin/settings/General/GeneralSettingsComponent";
import LimitSettingComponent from "../component/admin/settings/Limit/LimitSettingComponent";
import BillingInformationsComponent from "../component/admin/settings/BillingInformations/BillingInformationsComponent";
import LanguagesComponet from "../component/admin/Languages/LanguagesComponet";
import AdminPremiumPostComponent from "../component/admin/PremiumPosts/PremiumPostsComponent";

import User from "../component/admin/ManagerUser/User";
import AddUser from "../component/admin/ManagerUser/AddUser";
import PackageSetting from "../component/admin/ManageFunds/PackageSetting";
import UpdatePackage from "../component/admin/ManageFunds/UpdatePackage";
import ForgotPassword from "../component/Auth/Forgotpassword";
import Loader from "../component/Comman/Loader";
import JoinRoom from "../component/LiveStream/JoinRoom";
import EditUser from "../component/admin/ManagerUser/EditUser";
import AddPackages from "../component/admin/ManageFunds/AddPackages";
import StripeSubs from "../component/admin/paymentMethods/StripeSubs";
import FullPageLoader from "../component/Comman/FullPageLoader";

const IndexRouters = (props) => {
  const history = useHistory();
  const { isLogin } = useUser();
  const [isAuth, setIsAuth] = useState("");
  const [isCrator, setIsCrator] = useState("");
  const [checkAuth, setCheckAuth] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    // checkUser();
    authCheck();
  }, []);

  const authCheck = async () => {
    setShowLoader(true);
    await isLogin();
    setShowLoader(false);
  };

  if (showLoader) {
    return (
      <div>
        <FullPageLoader />
      </div>
    );
  }

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" children={(props) => <Home {...props} />} />
          <Route exact path="/login" children={<Login />} />

          <Route exact path="/register" children={<Register />} />
          <Route
            exact
            path="/subscription/"
            children={(props) => (
              <Subscriber type={"subscription"} {...props} />
            )}
          />
          <Route
            exact
            path="/subscriber/"
            children={(props) => <Subscriber type={"subscriber"} {...props} />}
          />
          <Route
            exact
            path="/dashboard/"
            children={(props) => <Dashboard {...props} />}
          />
          <Route
            exact
            path="/settings/"
            children={(props) => <ProfileSettings {...props} />}
          />
          <Route
            exact
            path="/account_settings"
            children={(props) => <AccountSettings {...props} />}
          />
          <Route
            exact
            path="/followers"
            children={(props) => <Followers {...props} />}
          />
          <Route
            exact
            path="/following"
            children={(props) => <Following {...props} />}
          />
          <Route
            exact
            path="/subscribers"
            children={(props) => <Subscribers {...props} />}
          />
          <Route
            exact
            path="/subscribtions"
            children={(props) => <Subscrptions {...props} />}
          />
          <Route
            exact
            path="/profile/:username"
            children={(props) => <OurProfile {...props} />}
          />
          <Route
            exact
            path="/subscribed_profile/"
            children={<SubscribedProfile />}
          />
          <Route
            exact
            path="/unsubscribed_profile/"
            children={<UnsubscribedProfile />}
          />
          <Route
            exact
            path="/unsubscribed_profile_trailer/"
            children={<UnsubscribedProfileTrailer />}
          />
          <Route
            exact
            path="/subscribtion_package/"
            children={<SubscriptionPackage />}
          />
          <Route
            exact
            path="/payment_method/"
            children={(props) =>
              !isCrator ? <Redirect to="/" /> : <PaymentMethods {...props} />
            }
          />
          <Route exact path="/payment_history/" children={<PaymentHistory />} />
          <Route exact path="/recived_payment/" children={<RecivedPayment />} />
          <Route exact path="/final_earning/" children={<FinalisedEarning />} />
          <Route
            exact
            path="/subscription_payment/"
            children={<SubscriptionPayment />}
          />
          <Route
            exact
            path="/setting_dashboard/"
            children={<SettingDashboard />}
          />
          <Route exact path="/add_balance/" children={<Payment />} />
          <Route
            exact
            path="/bookmark/"
            children={(props) => <BookmarkComponent {...props} />}
          />
          <Route
            exact
            path="/premium_post/"
            children={(props) => <PremiumComponent {...props} />}
          />
          <Route exact path="/wallet_credit/" children={<WalletCredits />} />
          <Route
            exact
            path="/live_treaming/"
            children={<LiveStreamingComponent />}
          />
          <Route exact path="/chat/:id?" children={<UserChat />} />
          <Route exact path="/users" children={<User />} />
          <Route
            exact
            path="/users_edit"
            children={(props) => <EditUser {...props} />}
          />
          <Route
            exact
            path="/users_add"
            children={(props) => <AddUser {...props} />}
          />
          <Route exact path="/admin/" children={<DashboardComponent />} />
          <Route exact path="/post_list/" children={<PostListComponent />} />
          <Route
            exact
            path="/website_setting/"
            children={<WebsiteSettingComponent />}
          />
          <Route
            exact
            path="/website_general_setting/"
            children={<GeneralSettingsComponent />}
          />
          <Route
            exact
            path="/website_limit/"
            children={<LimitSettingComponent />}
          />
          <Route
            exact
            path="/billing_informations/"
            children={<BillingInformationsComponent />}
          />
          <Route exact path="/languages/" children={<LanguagesComponet />} />
          <Route
            exact
            path="/premiumPosts/"
            children={<AdminPremiumPostComponent />}
          />
          <Route exact path="/packages/" children={<PackageSetting />} />
          <Route
            exact
            path="/package_edit"
            children={(props) => <UpdatePackage {...props} />}
          />
          <Route exact path="/package_add" children={<AddPackages />} />
          <Route
            exact
            path="/live_treaming/"
            children={<LiveStreamingComponent />}
          />
          <Route
            exact
            path="/join_room/:name"
            children={(props) => <JoinRoom {...props} />}
          />
          <Route
            exact
            path="/forgot_password/"
            children={(props) => <ForgotPassword {...props} />}
          />
          <Route
            exact
            path="/stripe_payment"
            children={(props) => <StripeSubs {...props} />}
          />
        </Switch>
      </Router>
    </>
  );
};

export default IndexRouters;
