import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CreatePostComponent from '../Home/Middle/Post/CreatePostComponent';
import useUser from "../../hooks/useUser";
import useHome from "../../hooks/useHome";
import PostComponent from '../Home/Middle/Post/PostComponent';
import Navbar from "../Header/headerComponent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import './SubscribedProfile.css'

const SubscribedProfile = () => {

    const { auth } = useSelector((state) => state)
    const [post, setPost] = useState([])
    const [packages, setPackages] = useState([])
    const [postCount, setPostCount] = useState(1)
    const userDetail = auth.auth
    let history = useHistory();
    const { isLogin } = useUser()
    const { getPost, CreatePost, EditPost, DeletePost, GetPackages, ChangeWhoCanSee } = useHome()

    useEffect(async () => {
        await authCheck();
        var post = await getPost();
        await setPost(post.posts);
        var packageList = await GetPackages()
        await setPackages(packageList)
    }, [history]);

    const authCheck = async () => {
        var response = await isLogin();
        if (parseInt(response.status) === 401) {
            history.push("/login");
        } else {
            //history.push("/");
        }
    };
    const createPost = async (event) => {
        event.preventDefault()

        var data = {
            postText: event.target.post.value,
            whoCanSee: event.target.who_can_see.value,
            urlSlug: event.target.post.value.replace(' ', '-'),
            postCreatorIp: 1254178,
            postWantStatus: 'normal',
            postStatus: 1,
            commentStatus: 1,
        }
        var response = await CreatePost(data)
        if (response) {
            post.unshift(response)
            await setPostCount(Math.floor(Math.random() * 100));
            await setPost(post)
        }
    }

    const updatePost = async (data) => {
        var response = await EditPost(data.name, data.id);
        if (response) {
            var updatedPost = post.filter((el) => {
                if (el._id !== data.id) {
                    return el
                }
            })
            updatedPost.unshift(response)
            await setPostCount(Math.floor(Math.random() * 100));
            await setPost(updatedPost)
            return response
        }
    }

    const deletePost = async (id) => {
        var response = await DeletePost(id)
        if (response) {
            var updatedPost = post.filter((el) => {
                if (el._id !== id) {
                    return el
                }
            })
            await setPostCount(Math.floor(Math.random() * 100));
            await setPost(updatedPost)
            return response
        }
    }

    const updateWhoCanSee = async (data) => {
        await ChangeWhoCanSee(data)
    }

    const updatePostData = async () => {
        var post = await getPost();
        await setPost(post.posts);
    }

    return (
        <>
            <nav>
                <div className="headerSection">
                    <Navbar />
                </div>
            </nav>
            <div className="ProfileBannersection">
                <img src="https://via.placeholder.com/920X550&text=User%20profile%20banner" />
                <PhotoCameraIcon className="cameraIconBanner" />
            </div>
            <div className="userProfileInfoarea">
                <div className="userProfileInfocolumns">
                    <div className="userProfileInfocolumn1">
                        <div className="userProfileMain">
                            <span className="userProfileCamera">
                                <PhotoCameraIcon className="userProfileCameraIcon" />
                            </span>
                            <img className="userProfileImg" src="https://via.placeholder.com/150X150/09f/fff&text=User" />
                        </div>
                        <div className="userNameDatainfo">
                            <h2>Jesús Guzman A.</h2>
                            <h3>Top Djs Magazine</h3>
                        </div>
                    </div>
                    <div className="userProfileInfocolumn2">
                        <div className="userIdActions">
                            <div className="addTrailerbtn">
                                <p>@Music_4Ever</p>
                            </div>
                            <div className="Profileactions">
                                <div className="Profileactionsbtns">
                                    <StarBorderPurple500Icon />
                                    <ShareIcon />
                                </div>
                            </div>
                        </div>
                        <div className="ProfileactionsText flexAlignmiddle">
                            <p><strong>890</strong> Subscribers</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="greyBackgroundColcor">
                <div className="container paddingTwenty">
                    <div className="borderProfileTop" style={{ borderTop: '1px solid #535353', width: '600px', margin: '0px auto', height: '0px', marginBottom: 15 }}></div>
                    <div className="wrapHomeSection">
                        <div className="leftSideMenu greyBackgroundColcor">
                            <div className="importLinkmenu impLinksubscribedpage">
                                <div><a>Privacy Policies</a><a>Crator© 2022</a></div>
                                <div><a>Terms Of Use</a><a>Cookies</a></div>
                            </div>
                        </div>
                        <div className="middlePostFeedsWrap">
                            <div className="leftIconsPostsFeeds">
                                <div className="playTrailer">
                                    <span className="playIconTrailer"></span>
                                    <h4>Play Trailer</h4>
                                </div>
                                <div className="sendMessageUser">
                                    {/* <span className="sendMessageUserIcon"></span> */}
                                    <div class="postActionMessageSub"><span class="messageiconSub_1"></span><span class="messageiconSub_2"></span></div>
                                    <h4>Send a message to USER</h4>
                                </div>
                                <div className="ScheduleCallmain">
                                    <span className="ScheduleCallIcon"></span>
                                    <h4>Schedule a private video call</h4>
                                </div>
                            </div>
                            <div className="rightPostFeedsMain">
                                <div className="subscribrBluehilighter"><FavoriteBorderIcon /><h4>Subscribed</h4><FavoriteBorderIcon /></div>
                                <div className="middleSection">
                                    {/* <div>
                                <CreatePostComponent packages={packages} createPost={((event) => createPost(event))} />
                            </div> */}
                                    <div className="postArea">
                                        <Grid container style={{ width: "100%", margin: "0 auto" }} margin="dense">
                                            <Grid item xs={12}>
                                                <Paper variant="outlined" style={{ height: 100, margin: 0 }}>
                                                    {post && postCount > 0 ? (
                                                        <PostComponent updatePostData={(() => updatePostData())} updateWhoCanSee={((data) => updateWhoCanSee(data))} packages={packages} deletePost={((id) => deletePost(id))} updatePost={((event) => updatePost(event))} posts={post} />

                                                    ) : (
                                                        "Loading..."
                                                    )}
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="leftSideMenu greyBackgroundColcor">
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubscribedProfile;
