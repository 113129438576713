import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useUser from "../../hooks/useUser";
import useChat from "../../hooks/useChat";
import Navbar from "../Header/headerComponent";
import moment from "moment";
import "moment-timezone";
import Picker from "emoji-picker-react";
import io from "socket.io-client";
import { imageUrl, defaultImgUrl, socketUrl } from "../../api/impUrl";
import {
  onImageError,
  getFileExtention,
  isValidURL,
} from "../../utils/helpers";
import { videoFormate, docsFormate } from "../../constants/fileExtentions.js";
import ReactPlayer from "react-player";
import attachmentFileImg from "../../assets/images/attached-file.svg";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cookies from "js-cookie";
import { Menu, Dropdown } from "antd";

let socket;

const UserChat = (props) => {
  let fileInput;
  let history = useHistory();
  let { id } = useParams();
  const { auth } = useSelector((state) => state.auth);
  const { isLogin } = useUser();
  const { getUserConversations, saveUserChats, getUserChats, deleteUserChat } =
    useChat();

  const [conversations, setConversations] = useState([]);
  const [loginUserId, setLoginUserId] = useState("");
  const [limit, setLimit] = useState(10);
  const [otherUser, setOtherUser] = useState(null);
  const [message, setMessage] = useState("");
  const [chats, setChats] = useState([]);
  const [chatLimit, setChatLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalChats, setTotalChats] = useState(1);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [showEmojiMenu, setShowEmojiMenu] = useState(false);
  const [chatFile, setChatFile] = useState(null);
  const [search, setSearch] = useState("");
  const [loadingMsg, setLoadingMsg] = useState("");

  const token = cookies.get("token");
  // console.log("cookie token:", token);

  useEffect(() => {
    socket = io(socketUrl, { query: `loggeduser=${loginUserId}` });
  }, [socketUrl, loginUserId]);

  useEffect(() => {
    socket.on("message", (data) => {
      if (data.receiver._id == loginUserId) {
        const updatedChats = [...chats];
        const present = updatedChats.some((el) => el._id == data._id);
        if (!present) {
          updatedChats.push(data);
          setChats(updatedChats);
        }
      }
    });
  });

  useEffect(() => {
    getConversations();
  }, []);

  useEffect(() => {
    const userId = auth?._id || "";
    setLoginUserId(userId);
  }, [auth]);

  useEffect(() => {
    authCheck();
  }, [history]);

  const getConversations = async () => {
    try {
      setLoadingMsg("Loading Conversation...");
      const { data } = await getUserConversations(limit);
      if (data.length > 0) {
        setConversations(data);
        if (id) {
          const selectedChat = data.find(({ _id }) => _id == id);
          if (selectedChat) {
            const userId = auth?._id || "";
            showChats(selectedChat);
          }
        }
      } else {
        setLoadingMsg("Conversation not found !");
      }
    } catch (error) {
      console.log("getUserConversations err:", error);
    }
  };

  const authCheck = async () => {
    const response = await isLogin();
    if (parseInt(response.status) === 401) {
      history.push("/login");
    }
  };

  const showChats = async (el) => {
    try {
      setLoadingMsg("Loading Conversation...");
      let showUser = {};
      const userId = auth?._id || "";
      console.log("loginUserId:", loginUserId, userId);
      if (el.sender._id === userId) {
        showUser = el.receiver;
      } else {
        showUser = el.sender;
      }
      showUser.conversationId = el.conversationId;
      setOtherUser(showUser);
      setChats([]);
      setPage(1);
      setChatLimit(20);
      setTotalChats(1);
      await getAllChats(el.conversationId, false);
    } catch (error) {
      setLoadingMsg("Conversation not found !");
      console.log("showChats err:", error);
    }
  };

  const getAllChats = async (cid, loadMore, chatPage = 1) => {
    try {
      const res = await getUserChats(cid, chatPage, chatLimit);
      const { docs, last_page } = res.data;
      if (loadMore) {
        const newChats = docs;
        const currentChats = [...chats];
        for (const it of newChats) {
          currentChats.unshift(it);
        }
        setChats(currentChats);
      } else {
        const chatDocs = docs.reverse();
        setChats(chatDocs);
      }
      setPage(res.data.page);
      setLastPage(last_page);
      scrollToBottom("#chatWindow");
    } catch (error) {
      console.log("showChats err:", error);
    }
  };

  const saveChat = async () => {
    try {
      let payload;
      let multipart = false;
      if (!chatFile) {
        if (message == "") {
          return false;
        }
        payload = {
          sender: loginUserId,
          receiver: otherUser._id,
          message: message,
          type: isValidURL(message) ? "link" : "text",
        };
      } else {
        multipart = true;
        payload = new FormData();
        payload.append("sender", loginUserId);
        payload.append("receiver", otherUser._id);
        payload.append("message", message);
        payload.append("file", chatFile);
        const fileExt = getFileExtention(chatFile.name);
        if (videoFormate.includes(fileExt)) {
          payload.append("type", "video");
        } else if (docsFormate.includes(fileExt)) {
          payload.append("type", "file");
        } else {
          payload.append("type", "image");
        }
      }
      const res = await saveUserChats(payload, multipart);
      const newChats = [...chats];
      newChats.push(res.data);
      setChats(newChats);
      setMessage("");
      scrollToBottom("#chatWindow");
    } catch (error) {
      console.log("saveChat err:", error);
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    const newMsg = `${message}${emojiObject.emoji}`;
    setMessage(newMsg);
    setChosenEmoji(emojiObject);
  };

  const onFileSelect = (e) => {
    e.preventDefault();
    fileInput.click();
    return false;
  };

  const onFileChange = (e) => {
    const [file] = e.target.files;
    const fileExt = getFileExtention(file.name);
    setChatFile(file);
    notify(`File selected successfully ! send your message`);
  };

  const scrollToBottom = (id) => {
    const scrollHeight = $(id)[0].scrollHeight * 100;
    $(id).stop().animate({ scrollTop: scrollHeight }, 1000);
  };

  const deleteChat = async (id) => {
    try {
      const res = await deleteUserChat(id);
      let newChats = [...chats];
      newChats = newChats.filter((el) => el._id != id);
      setChats(newChats);
    } catch (error) {
      console.log("deleteChat err:", error);
    }
  };

  const searchChat = async (e) => {
    try {
      setConversations([]);
      setSearch(e.target.value);
      const response = await getUserConversations(limit);
      const query = e.target.value.toLowerCase();
      const { data } = response;
      const matchedArr = [];
      for (const el of data) {
        let showUser = {};
        if (el.sender._id == loginUserId) {
          showUser = el.receiver;
        } else {
          showUser = el.sender;
        }
        if (showUser?.name?.toLowerCase().includes(query)) {
          matchedArr.push(el);
        } else {
          if (query == "") {
            matchedArr.push(el);
          }
        }
      }
      setConversations(matchedArr);
    } catch (error) {
      console.log("searchChat err:", error);
    }
  };

  const onScroll = async (e) => {
    const currentScrollY = e.target.scrollTop;
    if (currentScrollY == 0 && page < lastPage) {
      const chatPage = page + 1;
      setPage(chatPage);
      await getAllChats(otherUser.conversationId, true, chatPage);
      console.log("scrollTop 1:", e.target.scrollTop);
      e.target.scrollTop = 5;
      console.log("scrollTop 2:", e.target.scrollTop);
    }
  };

  const notify = (msg) => {
    toast.success(msg, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const menu = (
    <Menu>
      <Menu.Item>
        <a href="#">Delete Message</a>
      </Menu.Item>
      <Menu.Item>
        <a href="#">Restrict Mobile user</a>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <nav>
        <div className="headerSection">
          <Navbar />
        </div>
      </nav>
      <div className="chat_section">
        <div className="mt-20 min-w-full border rounded lg:grid lg:grid-cols-3">
          <div className="border-r border-gray-300 lg:col-span-1">
            <div className="mx-3 my-3">
              <div className="relative text-gray-600">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    className="w-6 h-6 text-gray-300"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </span>
                <input
                  type="search"
                  className="block w-full py-2 pl-10 bg-gray-100 rounded outline-none"
                  name="search"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => searchChat(e)}
                  required=""
                />
              </div>
            </div>
            <ul className="overflow-auto h-[83vh]">
              <h2 className="my-2 mb-2 ml-2 text-lg text-gray-600">Chats</h2>
              <li>
                {conversations.length > 0 ? (
                  conversations.map((el, index) => {
                    let showUser = {};
                    if (el.sender._id == loginUserId) {
                      showUser = el.receiver;
                    } else {
                      showUser = el.sender;
                    }
                    return (
                      <a
                        key={el._id}
                        className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none"
                        onClick={() => showChats(el)}
                      >
                        {showUser.profile_image ? (
                          <img
                            className="object-cover w-10 h-10 rounded-full"
                            src={imageUrl + showUser.profile_image}
                            alt="username"
                            onError={(e) => onImageError(e)}
                          />
                        ) : (
                          <img
                            className="object-cover w-10 h-10 rounded-full"
                            src={defaultImgUrl}
                            alt="username"
                            onError={(e) => onImageError(e)}
                          />
                        )}

                        <div className="w-full pb-2">
                          <div className="flex justify-between">
                            <span className="block ml-2 font-semibold text-gray-600">
                              {showUser.name}
                            </span>
                            <div>
                              <span className="chatOptionDots">
                                <Dropdown
                                  overlay={menu}
                                  placement="bottomLeft"
                                  arrow
                                >
                                  {/* <span>*****</span> */}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19"
                                    height="5"
                                    viewBox="0 0 19 5"
                                  >
                                    <g
                                      id="Group_15"
                                      data-name="Group 15"
                                      transform="translate(-2629 -250)"
                                    >
                                      <circle
                                        id="Ellipse_1"
                                        data-name="Ellipse 1"
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        transform="translate(2629 250)"
                                      ></circle>
                                      <circle
                                        id="Ellipse_2"
                                        data-name="Ellipse 2"
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        transform="translate(2636 250)"
                                      ></circle>
                                      <circle
                                        id="Ellipse_3"
                                        data-name="Ellipse 3"
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        transform="translate(2643 250)"
                                      ></circle>
                                    </g>
                                  </svg>
                                </Dropdown>
                              </span>
                              <span className="block ml-2 text-sm text-gray-600">
                                {/* 25 minutes */}
                                {moment(el.messageBody.createdAt).fromNow()}
                              </span>
                            </div>
                          </div>
                          <span className="block ml-2 text-sm text-gray-600">
                            {el.messageBody.message}
                          </span>
                        </div>
                      </a>
                    );
                  })
                ) : (
                  <h1 className="text-center">Loading Conversation...</h1>
                )}
              </li>
            </ul>
          </div>
          <div className="hidden lg:col-span-2 lg:block">
            {otherUser ? (
              <div className="w-full">
                <div className="relative flex items-center p-3 border-b border-gray-300">
                  <img
                    className="object-cover w-10 h-10 rounded-full"
                    src={imageUrl + otherUser.profile_image}
                    alt="username"
                    onError={(e) => onImageError(e)}
                  />
                  <span className="block ml-2 font-bold text-gray-600">
                    {otherUser.name}
                  </span>
                  <span className="chat_message">
                    <Dropdown overlay={menu} placement="bottomLeft" arrow>
                      {/* <span>*****</span> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="5"
                        viewBox="0 0 19 5"
                      >
                        <g
                          id="Group_15"
                          data-name="Group 15"
                          transform="translate(-2629 -250)"
                        >
                          <circle
                            id="Ellipse_1"
                            data-name="Ellipse 1"
                            cx="2.5"
                            cy="2.5"
                            r="2.5"
                            transform="translate(2629 250)"
                          ></circle>
                          <circle
                            id="Ellipse_2"
                            data-name="Ellipse 2"
                            cx="2.5"
                            cy="2.5"
                            r="2.5"
                            transform="translate(2636 250)"
                          ></circle>
                          <circle
                            id="Ellipse_3"
                            data-name="Ellipse 3"
                            cx="2.5"
                            cy="2.5"
                            r="2.5"
                            transform="translate(2643 250)"
                          ></circle>
                        </g>
                      </svg>
                    </Dropdown>
                  </span>
                  {/* <span className="absolute w-3 h-3 bg-green-600 rounded-full left-10 top-3"></span> */}
                </div>
                <div
                  id="chatWindow"
                  onScroll={onScroll}
                  className="relative w-full p-6 overflow-y-auto h-[83vh]"
                >
                  <ul className="space-y-4">
                    {chats.length > 0 &&
                      chats.map((chat) => {
                        let positionClassName = "";
                        if (chat.sender._id == otherUser._id) {
                          positionClassName = "flex justify-start";
                        } else {
                          positionClassName = "flex justify-end";
                        }
                        if (chat.messageBody.type == "text") {
                          return (
                            <li key={chat._id} className={positionClassName}>
                              <div className="relative max-w-xl px-4 py-2 text-gray-700 bg-gray-100 rounded shadow">
                                <span className="block">
                                  {chat.messageBody.message}
                                </span>
                                <div>
                                  <i
                                    className="fa fa-ellipsis-v"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </li>
                          );
                        } else if (chat.messageBody.type == "image") {
                          return (
                            <li key={chat._id} className={positionClassName}>
                              <div className="relative max-w-xl px-4 py-2 text-gray-700 bg-gray-100 rounded shadow">
                                <span className="block">
                                  <img
                                    className="object-cover w-30 h-30"
                                    style={{ borderRadius: 10 }}
                                    src={imageUrl + chat.messageBody.file}
                                    alt="username"
                                    onError={(e) => onImageError(e)}
                                  />
                                  {chat.messageBody.message}
                                </span>
                              </div>
                            </li>
                          );
                        } else if (chat.messageBody.type == "video") {
                          return (
                            <li key={chat._id} className={positionClassName}>
                              <div className="relative max-w-xl px-4 py-2 text-gray-700 bg-gray-100 rounded shadow">
                                <span className="block">
                                  <ReactPlayer
                                    controls
                                    width={320}
                                    height={160}
                                    url={imageUrl + chat.messageBody.file}
                                  />
                                  {chat.messageBody.message}
                                </span>
                              </div>
                            </li>
                          );
                        } else if (chat.messageBody.type == "link") {
                          return (
                            <li key={chat._id} className={positionClassName}>
                              <div className="relative max-w-xl px-4 py-2 text-gray-700 bg-gray-100 rounded shadow">
                                <span className="block">
                                  <a
                                    href={chat.messageBody.message}
                                    target="_blank"
                                  >
                                    {chat.messageBody.message}
                                  </a>
                                </span>
                              </div>
                            </li>
                          );
                        } else {
                          return (
                            <li key={chat._id} className={positionClassName}>
                              <div className="relative max-w-xl px-4 py-2 text-gray-700 bg-gray-100 rounded shadow">
                                <span className="block">
                                  <a
                                    href={imageUrl + chat.messageBody.file}
                                    target="_blank"
                                  >
                                    <img src={attachmentFileImg} />
                                  </a>
                                  {chat.messageBody.message}
                                </span>
                              </div>
                            </li>
                          );
                        }
                      })}
                  </ul>
                </div>
                <div className="bottom_text_input items-center justify-between w-full p-3 border-t border-gray-300">
                  <div className="emogy_wrp">
                    {showEmojiMenu && <Picker onEmojiClick={onEmojiClick} />}
                  </div>
                  <div className="flex ">
                    <button onClick={() => setShowEmojiMenu(!showEmojiMenu)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-gray-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </button>
                    <button onClick={(e) => onFileSelect(e)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5 text-gray-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                        />
                      </svg>
                    </button>
                    <input
                      className="form-control"
                      type="file"
                      ref={(input) => {
                        fileInput = input;
                      }}
                      hidden={true}
                      id="file"
                      name="file"
                      onChange={(e) => onFileChange(e)}
                      style={{ borderRadius: 15 }}
                      required
                    />
                    <input
                      type="text"
                      placeholder="Message"
                      className="block w-full py-2 pl-4 mx-3 bg-gray-100 rounded-full outline-none focus:text-gray-700"
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required=""
                    />
                    <button>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5 text-gray-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                        />
                      </svg>
                    </button>
                    <button type="submit" onClick={saveChat}>
                      <svg
                        className="w-5 h-5 text-gray-500 origin-center transform rotate-90"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <h1 className="chat_empty_logo text-center mt-6">
                Select a user
              </h1>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default UserChat;
