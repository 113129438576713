import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
// import Grid from "@material-ui/core/Grid";
import { isEmpty } from 'lodash'
import { connect, useSelector } from "react-redux";
import useUser from "../../../hooks/useUser";
import { useHistory } from "react-router-dom";
import Moment from 'react-moment';
import useAdmin from '../../../hooks/useAdmin';
import { notification } from 'antd';
import { SmileOutlined, CloseOutlined } from '@ant-design/icons';
import { paginationComponentOptions, admin_user_columns } from '../../../utils/constants';
import AdminLeftMenu from '../../admin/adminLeftSideMenu/adminLeftMenuComponent';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

function User() {
    const [pageNumber, setPageNumber] = useState(1)
    const [perPage, setPerPage] = useState(10);
    const [reload, setReload] = useState(false);
    const { auth } = useSelector((state) => state.auth);
    const { isLogin } = useUser()
    const { getUserListing, deleteAdminUser } = useAdmin();
    const [userList, setUserList] = useState({})

    let history = useHistory();

    useEffect(async () => {
        await authCheck();
        setUserList(await getUserListing());
    }, [reload])

    const authCheck = async () => {
        var response = await isLogin();
        if (parseInt(response.status) === 401) {
            history.push("/login");
        }
    }
    const editUser = (id) => {
        history.push({
            pathname: "/users_edit",
            state: {
                user_id: id,
            },
        })
    }

    const deleteUser = async (id) => {
        let res = await deleteAdminUser(id);
        if (res) {
            notification.open({
                message: 'Update',
                description: res?.message,
                icon: <SmileOutlined style={{ color: '#108ee9' }} />,
            });
        }
        else {
            notification.open({
                message: 'Error',
                description: 'Something went wrong...!',
                icon: <CloseOutlined style={{ color: '#f4364c' }} />,
            });
        }
        setReload(!reload)
    }

    const handlePerRowsChange = async (newPerPage) => {
        setPerPage(newPerPage);
    };

    const handlePageChange = (pageNo) => {
        setPageNumber(pageNo);
    };

    const createTable = (UserData) => {
        return UserData?.map((obj, { index }) => {
            return {
                id: obj._id,
                userName: obj.userFullName,
                registerAt: <Moment format="DD-MMM-YYYY">{obj.createdAt}</Moment>,
                creatorType: obj.userType,
                verificationStatus: obj.userVerifiedStatus,
                sortable: true,
                action: (
                    <div key={index} >
                        <button style={{ marginRight: '20px' }}>send Invitaion</button>
                        <button style={{ marginRight: '20px' }} onClick={() => editUser(obj._id)}> edit</button>
                        <button style={{ marginRight: '20px' }} onClick={() => deleteUser(obj._id)}>Delete</button>
                        <button style={{ marginRight: '20px' }} >See Profile</button>
                    </div>
                ),
                sortable: true
            }
        })
    };

    return (
        <>
            <nav>
                <div style={{ display: "flex" }}>
                    <div className="sideBar">
                        <AdminLeftMenu />
                    </div>
                    <div style={{ width: "100%" }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <h3>Manage Users{userList?.data?.length || 0}</h3>
                            <button onClick={() => { history.push('/users_add') }}>Add Users</button>
                            {!isEmpty(auth) ?
                                <DataTable
                                    columns={admin_user_columns}
                                    data={createTable(userList?.data?.latestUsers)}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={userList?.data?.total}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    noDataComponent={<span>No data found</span>}
                                    paginationComponentOptions={paginationComponentOptions}
                                />
                                : ''
                            }
                        </Box>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default connect((state) => state)(User);