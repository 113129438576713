import * as React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function Media(props) {
    const { loading = false } = props;
    const count = [1,2,3,4,5]
    return (
        <div className="WhatsNewListSkltn" style={{witdh:'80%'}}>
            {count.map((value,key)=>
                <div className="InnerList">
                    <div className="WhatsNewImage">
                        <Skeleton animation="wave" variant="circular" width={60} height={60} />
                    </div>
                    <div className="WhatsNewName">
                        <a href="#">
                        <Skeleton
                            animation="wave"
                            height={10}
                            width="100%"
                            style={{ marginBottom: 6 }}
                            />
                        </a>
                    </div>
                </div>
            )}    
        </div>    
  );
}

Media.propTypes = {
  loading: PropTypes.bool,
};

export default function Postloader() {
  return (
      <Media loading />
  );
}
