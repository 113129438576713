import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Switch, TextField } from "formik-material-ui";
import { FormControlLabel, Switch as MaterialSwitch } from "@material-ui/core";
import {
  emailUpdateSchema,
  profileAccountSchema,
} from "../../../../utils/Validations";

function AccountUpdateForm({ update, userDetails, updateEmail }) {
  const [formData, setFormdata] = useState({});
  const [showSecure, setshowSecure] = useState(false);
  const [linkAcc, setlinkAcc] = useState(false);
  const [accountForm, setAccountForm] = useState(true);

  useEffect(() => {
    setFormdata(userDetails);
  }, [userDetails]);

  let accountFormValues = {
    linkAccountGmail: userDetails?.linkAccountGmail || "",
    linkAccountTwitter: userDetails?.linkAccountTwitter || "",
    currentPassword: "",
    password: "",
    confirmPassword: "",    
    emailNotificationStatus:
      userDetails?.emailNotificationStatus == 0 ? false : true,
    anyoneCanMessage: userDetails?.anyoneCanMessage == 0 ? false : true,
    profileStatus: userDetails?.profileStatus == 0 ? false : true,
  };

  let emailFormValues = {
    email: "",
    password: "",
  };

  const mailUpdate = async (data) => {
    let res = await updateEmail(data);
    setAccountForm(true);
  };

  const handleToggle = (field, value, setter) => {
    switch (field) {
      case "email":
        setter("emailNotificationStatus", !value);
        break;

      case "msg":
        setter("anyoneCanMessage", !value);
        break;

      case "profile":
        setter("profileStatus", !value);
        break;

      default:
        break;
    }
  };

  return (
    <div>
      {formData && accountForm && (
        <Formik
          initialValues={accountFormValues}
          onSubmit={async (values, { resetForm }) => {
            let data = {
              email: formData.email,
              linkAccountGmail: values.linkAccountGmail,
              linkAccountTwitter: values.linkAccountTwitter,
              password: values.password,
              emailNotificationStatus: values.emailNotificationStatus ? 1 : 0,
              anyoneCanMessage: values.anyoneCanMessage ? 1 : 0,
              profileStatus: values.profileStatus ? 1 : 0,
            };
            update(data);
          }}
          validationSchema={profileAccountSchema}
        >
          {({ values, errors, setFieldValue }) => {
            return (
              <Form>
                {/* <div>
                                    <h3>Email Address</h3>
                                    <span onClick={() => setAccountForm(false)}>{userDetails?.email}</span>
                                </div> */}
                <div className="col-md-4 form-group error-div">
                  <div className="formLabel">
                    <label>Linked Accounts</label>
                  </div>
                  <div className="formField">
                    <Field
                      type="text"
                      name="linkAccountGmail"
                      id="linkAccountGmail"
                      className="form-control form-control-sm"
                      placeholder="Enter your gmail"
                    />
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {errors.linkAccountGmail}
                    </p>
                  </div>
                </div>
                <div className="col-md-4 form-group error-div">
                  <div className="formLabel">
                    <label>Twitter</label>
                  </div>
                  <div className="formField">
                    <Field
                      type="text"
                      name="linkAccountTwitter"
                      id="linkAccountTwitter"
                      className="form-control form-control-sm"
                      placeholder="Enter your twitter"
                    />
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {errors.linkAccountTwitter}
                    </p>
                  </div>
                </div>
                <div className="headsecurity">Security</div>
                <div className="col-md-4 form-group error-div">
                  <div className="formLabel">
                    <label>Password:</label>
                  </div>
                  <div className="formField">
                    <Field
                      type="password"
                      name="currentPassword"
                      id="currentPassword"
                      className="form-control form-control-sm"
                      placeholder="Enter First curren"
                    />
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {errors.currentPassword}
                    </p>
                  </div>
                </div>

                {/* <div className="col-md-4 form-group error-div">
                                    <div className="formLabel">
                                        <label>New Password:</label>
                                    </div>
                                    <div className="formField">
                                        <Field
                                            type="text"
                                            name="password"
                                            id="password"
                                            className="form-control form-control-sm"
                                            placeholder="Enter First curren" />
                                        <p style={{ color: "red", fontSize: "14px" }}>{errors.password}</p>
                                    </div>
                                </div> */}

                <div className="col-md-4 form-group error-div">
                  <div className="formLabel">
                    <label>2FA authentication</label>
                  </div>
                  <div className="formField">
                    <Field
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      className="form-control form-control-sm"
                      placeholder="Enter First curren"
                    />
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {errors.confirmPassword}
                    </p>
                  </div>
                </div>
                <div className="headsecurity">Privacy</div>
                <div className="privacyswitches">
                  <FormControlLabel
                    label="Email Notification"
                    control={
                      <Field
                        type="checkbox"
                        label="Email Notification"
                        name="emailNotification"
                        component={Switch}
                        onChange={() =>
                          handleToggle(
                            "email",
                            values.emailNotificationStatus,
                            setFieldValue
                          )
                        }
                        checked={values.emailNotificationStatus}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Message notification"
                    control={
                      <Field
                        type="checkbox"
                        label="Message notification"
                        name="msgNotification"
                        component={Switch}
                        onChange={() =>
                          handleToggle(
                            "msg",
                            values.anyoneCanMessage,
                            setFieldValue
                          )
                        }
                        checked={values.anyoneCanMessage}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Profile display"
                    control={
                      <Field
                        type="checkbox"
                        label="Profile display"
                        name="profileStatus"
                        component={Switch}
                        onChange={() =>
                          handleToggle(
                            "profile",
                            values.profileStatus,
                            setFieldValue
                          )
                        }
                        checked={values.profileStatus}
                      />
                    }
                  />
                </div>
                <div className="settingSubmit">
                  <button type="submit">Save</button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
      {!accountForm && (
        <Formik
          initialValues={emailFormValues}
          onSubmit={async (values) => {
            let data = {
              email: values.email,
              password: values.password,
            };
            mailUpdate(data);
          }}
          validationSchema={emailUpdateSchema}
        >
          {({ values, errors, setFieldValue }) => {
            return (
              <Form className="form-group">
                <div>
                  <h1>
                    <bold>Changing your email address</bold>
                  </h1>
                  <h3>
                    You will need to recheck your address if you change it
                  </h3>
                </div>

                <br></br>
                <div className="mt-5">
                  <div className="col-md-4 form-group error-div">
                    <Field
                      type="text"
                      name="email"
                      id="email"
                      className="form-control form-control-sm"
                      placeholder="Your new email address"
                    />
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {errors.email}
                    </p>
                  </div>

                  <div className="col-md-4 form-group error-div">
                    <Field
                      type="password"
                      name="password"
                      id="password"
                      className="form-control form-control-sm"
                      placeholder="Your current password"
                    />
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {errors.password}
                    </p>
                  </div>
                </div>
                <button type="submit">Save</button>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
}

export default AccountUpdateForm;
