import { combineReducers } from 'redux';

import Auth from './auth/reducer';
import Common from './common/reducer';


const rootReducer = combineReducers({
    auth: Auth,
    common:Common
});

export default rootReducer;