import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import "./LimitSetting.css";
import AdminLeftMenu from "../../adminLeftSideMenu/adminLeftMenuComponent";
import useAdminDashboard from "../../../../hooks/useAdminDashboard";
import useUser from "../../../../hooks/useUser";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const LimitSettings = (props) => {
  const { id } = useParams();
  const { auth } = useSelector((state) => state);
  const [allPostData, setAllPostData] = useState([]);

  const userDetail = auth.auth;
  let history = useHistory();
  const { isLogin } = useUser();
  const {
    webLimitSetting,
  } = useAdminDashboard();

  useEffect(async () => {
    await authCheck();

  }, [history]);


  const authCheck = async () => {
  var response = await isLogin();

    if (parseInt(response.status) === 401) {
      history.push("/login");
    } else {
      if (props) {
        return;
      }
      history.push("/");
    }
  };


  const onSeePostCheck = async (val, type) => {
   var data = {defaultLanguage:val};
      await webLimitSetting(data)
  };  
  const onBlockCountries = async (val, type) => {
      var data = { maintenanceMode:val}
      await webLimitSetting(data)
  };    
 
  const onInstalled = async (val, type) => {
      var data = { ipLimit : val}
      await webLimitSetting(data)
  };  


const createWebSiteSettingLimit = async (event) => {
    event.preventDefault()
    var data = {
        normalUserCanPost:event.target.normalUserCanPost.value,
        userCanBlockCountry:event.target.userCanBlockCountry.value,
        availableFileSize:event.target.availableFileSize.value,
        availableLength:event.target.availableLength.value,
        showingNumberOfPost:event.target.showingNumberOfPost.value,
        paginationLimit:event.target.paginationLimit.value,
        availableFileExtensions:event.target.availableFileExtensions.value,
        availableVerificationFileExtensions:event.target.availableVerificationFileExtensions.value,
        disallowedUsernames:event.target.disallowedUsernames.value,
        ffmpegStatus:event.target.ffmpegStatus.value,
        ffmpegPath:event.target.ffmpegPath.value,
      }
      console.log(data)
    var data = await webLimitSetting(data);
}
const uploadFileSizeLimit = [
    {'name':'1 MB'},
    {'name':'2 MB'},
    {'name':'3 MB'},
    {'name':'4 MB'},
    {'name':'5 MB'},
    {'name':'10 MB'},
    {'name':'15 MB'},
    {'name':'20 MB'},
    {'name':'25 MB'},
    {'name':'30 MB'},
    {'name':'40 MB'},
    {'name':'50 MB'},
    {'name':'100 MB'},
    {'name':'150 MB'},
    {'name':'250 MB'},
    {'name':'300 MB'},
    {'name':'500 MB'},
    {'name':'700 MB'},
    {'name':'800 MB'},
    {'name':'1 GB'},
    {'name':'2 GB'},
    {'name':'3 GB'},
    {'name':'4 GB'},
    {'name':'5 GB'},
];
const postLength= [
    {'name':'100 Character'},
    {'name':'150 Character'},
    {'name':'200 Character'},
    {'name':'250 Character'},
    {'name':'300 Character'},
    {'name':'350 Character'},
    {'name':'400 Character'},
    {'name':'450 Character'},
    {'name':'500 Character'},
    {'name':'1000 Character'},
    {'name':'2000 Character'},
    {'name':'3000 Character'},
    {'name':'4000 Character'},
    {'name':'5000 Character'},
];
const numberOfPostShow= [
    {'name':5},
    {'name':10},
    {'name':15},
    {'name':20},
    {'name':25},
    {'name':30},
    {'name':35},
    {'name':40},
    {'name':45},
];
const showPagination= [
    {'name':10},
    {'name':20},
    {'name':30},
    {'name':40},
    {'name':50},
    {'name':60},
    {'name':70},
    {'name':80},
    {'name':90},
    {'name':100},
];

  return (
    <>
      <nav>
        <div style={{ display: "flex" }}>
          <div className="sideBar">
            <AdminLeftMenu />
          </div>
          <div className="admin_main_wrp " style={{ width: "100%" }}>
              <div>
                  <h1>Limits</h1>
                   <form onSubmit={((event) => createWebSiteSettingLimit(event))}>
                    <div className="col-md-4 form-group error-div">
                        <div className="formLabel">
                            <leble for="normalUserCanPostlbl">Post Create Status</leble>
                        </div>
                        <div className="formField">
                            <div><span>Everyone Can cretae a post</span></div>
                            <FormControlLabel id="normalUserCanPost" 
                                type="checkbox" 
                                name="normalUserCanPost"  
                                defaultValue={1}
                                onClick={(val) => onSeePostCheck(val.target.value)} 
                                control={<Checkbox />}
                                label="Just CRATORS can create a post"
                                />
                            </div>
                        </div>  
                        <div className="col-md-4 form-group error-div">
                            <div className="formLabel">
                                <leble for="userCanBlockCountrylbl">Block Countries Status</leble>
                            </div>
                            <div className="formField">
                               <div> <span>CRATORS can block countries</span></div>
                            <FormControlLabel id="block_country" 
                                    type="checkbox" 
                                    name="userCanBlockCountry"  
                                    defaultValue={1}
                                    onClick={(val) => onBlockCountries(val.target.value)} 
                                    control={<Checkbox />}
                                    label="Creators can not block countries"
                                    />
                            </div>
                        </div>
                        <div className="col-md-4 form-group error-div">
                            <div className="formLabel">
                                <label>Upload File Size Limit</label>
                            </div>
                            <div className="formField">
                            <select 
                                className="form-control" 
                                name="availableFileSize"  >
                                <option value="">Select</option>
                                {uploadFileSizeLimit && uploadFileSizeLimit.map((row,i) => (
                                    <>
                                        <option value={row.name}>{row.name}</option>
                                    </>
                                ))} 
                            </select>

                            <span>IMPORTANT: Please pay attention to your server's default maximum file upload size.</span>
                            </div>
                        </div>   
                        <div className="col-md-4 form-group error-div">
                            <div className="formLabel">
                                <label>Post Length</label>
                            </div>
                            <div className="formField">
                            <select 
                                className="form-control" 
                                name="availableLength"  >
                                <option value="">Select</option>
                                 {postLength && postLength.map((row,i) => (
                                <>       
                                    <option value={row.name}>{row.name}</option>
                                </> 
                                 ))}
                            </select>
                            <span>It is the maximum character value that can be written to a post.</span>
                            </div>
                        </div>
                        <div className="col-md-4 form-group error-div">
                            <div className="formLabel">
                                <label>Number of post show</label>
                            </div>
                            <div className="formField">
                            <select 
                                className="form-control" 
                                name="showingNumberOfPost"  >
                                <option value="">Select</option>
                                {numberOfPostShow && numberOfPostShow.map((row,i) => (
                                    <>
                                        <option value={row.name}>{row.name}</option>
                                    </> 
                                ))}
                            </select>
                            <span>It is also the number of posts that will be displayed when the page is scrolled.</span>
                            </div>
                        </div>  
                        <div className="col-md-4 form-group error-div">
                            <div className="formLabel">
                                <label>Show number of pagination</label>
                            </div>
                            <div className="formField">
                            <select 
                                className="form-control" 
                                name="paginationLimit"  >
                                <option value="">Select</option>
                                {showPagination && showPagination.map((row,i) => (
                                <>
                                    <option value={row.name}>{row.name}</option>
                                </>
                                ))}
                            </select>
                            <span>It is also the number of posts that will be displayed when the page is scrolled.</span>
                            </div>
                        </div>
                        <div className="settingFormFields">
                        <div className="col-md-4 form-group error-div">
                            <div className="formLabel">
                                <leble for="availableFileExtensionslbl">Allowed file extensions</leble>
                            </div>
                            <div className="formField">
                            <input id="file_extensions" 
                                    type="text" 
                                    name="availableFileExtensions"  
                                    className="form-control form-control-sm"
                                    />
                                <span>Separated with comma,</span>
                            </div>
                        </div>
                        <div className="col-md-4 form-group error-div">
                            <div className="formLabel">
                                <leble for="available_xtensionslbl">File extensions valid for approval</leble>
                            </div>
                            <div className="formField">
                            <input 
                                id="available_xtensions"  
                                name="availableVerificationFileExtensions"
                                type="text"
                                className="form-control form-control-sm"
                            />
                            <span>Separated with comma,</span>
                        </div> 
                        </div> 
                        <div className="col-md-4 form-group error-div">
                            <div className="formLabel">
                                <leble for="disallowed_sernameslbl">Not allowed usernames</leble>
                            </div>
                            <div className="formField">
                            <input 
                                id="disallowed_usernames"  
                                name="disallowedUsernames"
                                type="text"
                                className="form-control form-control-sm"
                            />
                            <span>Separated with comma,</span>
                            </div>
                        </div>  
                        <div className="col-md-4 form-group error-div">
                            <div className="formLabel">
                                <leble for="ffmpeg_statuslbl">ffmpeg Status</leble>
                            </div>
                            <div className="formField">
                                <span>ffmpeg not Installed</span>
                            <input 
                                id="ffmpeg_status"  
                                name="ffmpegStatus"
                                type="checkbox"
                                defaultValue={1}
                                align="left"
                                className="form-control form-control-sm"
                                onClick={(val) => onInstalled(val.target.value)} 
                            />
                            <div>
                            <span>ffmpeg Installed.</span>
                            <span>Make sure your server has activated the FFMPEG feature.</span>
                            </div>
                            </div>
                        </div>      
                        <div className="col-md-4 form-group error-div">
                            <div className="formLabel">
                                <leble for="ffmpeg_pathlbl">FFMPEG path</leble>
                            </div>
                            <div className="formField">
                            <input 
                                id="ffmpeg_path"  
                                name="ffmpegPath"
                                type="text"
                                className="form-control form-control-sm"
                            />
                            <span>Make sure your server has activated the FFMPEG feature.</span>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-4 form-group error-div">
                            <div className="submitData settingSubmit">
                                <input type='submit' value="Save" name="Save"/>
                            </div>
                        </div>
                   </form>
              </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default LimitSettings;
