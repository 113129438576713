import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import LeftMenu from "../LeftMenu";
import { connect, useSelector } from "react-redux";
import useDashboard from '../../../hooks/useDashboard'
import useUser from "../../../hooks/useUser";
import BasicTable from '../../Comman/Table';
import { useHistory } from "react-router-dom";
import '../settings.css'
import { payHistoryColumns } from "../../../utils/constants";
import InputGroup from "../../Comman/InputComponent";


function PaymentHistory() {
    const history = useHistory();
    const [amount, setAmount] = useState('');
    const [error, setError] = useState('');
    const [reload, setRelaod] = useState(false);
    const { auth } = useSelector((state) => state.auth);
    const { isLogin } = useUser();
    const { requestWithDraw } = useDashboard();


    useEffect(async () => {
        await authCheck();
        console.log(auth)
    }, [reload]);

    const authCheck = async () => {
        var response = await isLogin();
        if (parseInt(response.status) === 401) {
            history.push("/login");
        }
    };

    const requestWithdrawl = async() => {
        if (amount < Number(50)) {
            setError('Minimum withdrawal amount is $50');
        } else {
            let data = {
                "amount": amount,
                "method": auth?.paymentMethod,
                "paymentType": "withdrawal",
                "status": "pending"
            }

            let response = await requestWithDraw(data);
        }
    }

    return (
        <div className="container">
            <div className="wrapHomeSection">
                <div className="leftSideMenu">
                    <LeftMenu />
                </div>
                <div className="settingArea">
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="bannerImageSection">
                                <div className="bannerText">
                                    <h1>Finalised Earnings</h1>
                                    <h2>Through this page, you can send a money transfer request to your account.</h2>
                                    <pre>
                                        Minimum withdraw amount is $50. (It takes upto 7 business days to complete transfers)
                                        <br></br>
                                        Your current default withdrawal method is crypto you can change it by
                                        <a href="/payment_method"> CLICKING HERE.</a>
                                    </pre>
                                </div>
                            </div>
                            {auth && <div className="settingFormSection">
                                <div className="form-group">
                                    <div>
                                        Balance
                                        <span>$ {auth?.walletPoints ? auth.walletPoints : 0}</span>
                                    </div>
                                    <br></br>
                                    <div className="mt-5">
                                        <div className="col-md-4 form-group error-div">
                                            <input type="text" label="Balance" name="balance" onChange={(e) => setAmount(e.target.value)} />
                                        </div>
                                        <button onClick={requestWithdrawl}>
                                            Request withdrawal
                                        </button>
                                    </div>
                                    {error && <span>{error}</span>}
                                </div>
                            </div>}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>

    );
}
export default PaymentHistory