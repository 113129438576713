import React, { useEffect, useState } from "react";
import Picker from 'emoji-picker-react';
import useHome from '../../../../hooks/useHome';
import Comments from "./CommentsComponent";

const CommentCreate = ({post,updatePostData,deleteSingleComment,isVisibleComments}) => {

    const [chosenEmoji, setChosenEmoji] = useState(null);
    const { SaveComment } = useHome()
    const [commentCount, setCommentCount] = useState(1)
    const [defaultvalue, setDefaultValue] = useState('')
    const [postComment, setPostComment] = useState([]);

    useEffect(() => {
        if (post.comments) {
            setPostComment(post.comments)
        }
    }, [post])

    const submit = async (event) => {
        event.preventDefault()
        var comment = {
            postId: post._id,
            comment: event.target.comment.value,
            sticketUrl: '',
            gifUrl: '',
            commentFile: ''
        }
        var response = await SaveComment(comment)
        var comments = postComment
        comments.push(response)
        await setPostComment(comments)
        await setCommentCount(Math.floor(Math.random() * 100));
        setDefaultValue('')
    }

    const onEmojiClick = (event) => {
        console.log(event)
    }

    const comment = () => {
        return (
            <>
                <div className={`commentFormWrapper isCommentVisibleFalse isVisible_${isVisibleComments}`}>
                    <div>
                        { commentCount > 0 ? <Comments deleteSingleComment={((commentId)=>deleteSingleComment(commentId))} updatePostData={(()=>updatePostData())} comments={postComment ? postComment:{} }/> :''}

                    </div>
                    <form onSubmit={((event) => submit(event))} className="postFeedCommentForm">
                        <div className="commentsWrap">
                            <textarea name="comment" className="commentTextarea" placeholder="Comment here..." onChange={((event) => setDefaultValue(event.target.value))} >
                                {defaultvalue}
                            </textarea>
                            {/* <input name="comment" className="commentTextarea" onChange={((event) => setDefaultValue(event.target.value))} value={defaultvalue} placeholder="Comment here..." /> */}
                            <label className="commentSubmitbtnWrap">
                                <input type="submit" /><svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="344.000000pt" height="344.000000pt" viewBox="0 0 344.000000 344.000000" preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,344.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                                        <path d="M210 3218 c0 -7 90 -299 199 -648 l197 -635 1304 -3 c807 -1 1300 1 1295 7 -9 9 -2980 1291 -2991 1291 -2 0 -4 -6 -4 -12z"></path>
                                        <path d="M404 1072 c-111 -356 -200 -648 -200 -649 3 -3 2998 1286 3004 1292 3 3 -582 5 -1299 5 l-1304 0 -201 -648z"></path>
                                    </g>
                                </svg>
                            </label>
                        </div>
                    </form>
                </div>
            </>
        )
    }

    return (
        <>
            <div>
                {comment()}
            </div>
        </>
    )
}

export default CommentCreate;