import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import useAdmin from '../../../hooks/useAdmin';
import EditUserForm from "./Forms/EditUserForm";
import { notification } from 'antd';
import { SmileOutlined, CloseOutlined } from '@ant-design/icons';
import Box from "@mui/material/Box";
import AdminLeftMenu from '../../admin/adminLeftSideMenu/adminLeftMenuComponent';


const EditUser = (props) => {
    let history = useHistory();
    const [compProp] = useState(props?.location?.state);
    const userId = compProp?.user_id;
    const [reload, setReload] = useState(false);
    const { auth } = useSelector((state) => state.auth);
    const { isLogin } = useUser()
    const { getUserDetails, editUserDetails } = useAdmin();
    const [userDetails, setUserDetails] = useState({})

    useEffect(async () => {
        await authCheck();
        setUserDetails(await getUserDetails(userId));
    }, [reload])

    const authCheck = async () => {
        var response = await isLogin();
        if (parseInt(response.status) === 401) {
            history.push("/login");
        }
    }

    const updateUserDetails = (data) => {
        const res = editUserDetails(data, userId);
        if (res) {
            notification.open({
                message: 'Update',
                description: res.message,
                icon: <SmileOutlined style={{ color: '#108ee9' }} />,
            });
        }
        else {
            notification.open({
                message: 'Error',
                description: 'Something went wrong...!',
                icon: <CloseOutlined style={{ color: '#f4364c' }} />,
            });
        }
        setReload(!reload)
        return res;
    }

    return (
        <nav>
            <div style={{ display: "flex" }}>
                <div className="sideBar">
                    <AdminLeftMenu />
                </div>
                <div style={{ width: "100%" }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <h3>Manage Users{userDetails?.data?.length || 0}</h3>
                        <button onClick={() => { history.push('/users_add') }}>Add Users</button>
                        {auth && <EditUserForm details={userDetails.data} editUser={(data) => updateUserDetails(data)} />}
                    </Box>
                </div>
            </div>
        </nav>
    );
};

export default connect((state) => state)(EditUser);