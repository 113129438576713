import React, { useEffect, useState } from "react";
import Comment from "./CommentComponent";
import useHome from '../../../../hooks/useHome';

const CommentsComponent = ({ comments, updatePostData }) => {
    const { EditComment, DeleteComment } = useHome()
    const [postComment, setPostComment] = useState([])

    useEffect(async () => {
        await setPostComment(comments)
    }, [postComment, comments])

    const edit = async (data, commentId) => {
        var response = await EditComment(data, commentId)

        var updatedComment = postComment.filter((el) => {
            if (commentId !== el._id) {
                return el
            }
        })
        updatedComment.push(response[0])
        await updatePostData()
        await setPostComment(updatedComment)
    }

    const deleteSingleComment = async (commentId) => {
        await DeleteComment(commentId)
        await updatePostData()

    }

    const comment = () => {
        return (
            <>
                <div>
                    {postComment.map((value, key) => (
                        <Comment key={key} updatePostData={(()=>updatePostData())} deleteSingleComment={((commentId) => deleteSingleComment(commentId))} edit={((data, commentId) => edit(data, commentId))} comment={value} />
                    ))}
                </div>
            </>
        )
    }

    return (
        <>
            <div>
                {comment()}
            </div>
        </>
    )
}

export default CommentsComponent;