import React,{useState} from 'react';
import { Modal } from 'antd';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'


const ImageCroper = ({visible,onClose,src,getCropImage}) =>{
    const [croppedImageUrl,setCroppedImageUrl] = useState(null)
    const [imgRef,setImgRef] = useState('')
    const [cropeImage,setCropImage] = useState('')
    const [showCropImage,setShowCropImage] = useState('')
    const [crop, setCrop] = useState({
                                      width: 260,
                                      height:260,
                                      aspect: 16 / 9
                                     })


    const onImageLoaded = (image) => {
        setImgRef(image)
    };

    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    };

    const onCropChange = async(crop, percentCrop) => {
        await setCrop(crop)
    };

    const getCroppedImg = (image, crop, fileName) =>{
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                setCropImage(blob)
                var fileUrl = window.URL.createObjectURL(blob);
                resolve(fileUrl);
                },
                'image/jpeg',
                1
            );
            });
    }

    const makeClientCrop = async(crop) => {
        if (imgRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
            imgRef,
            crop,
            'newFile.jpeg'
            );          
            setCroppedImageUrl(croppedImageUrl);
        }
    }


    const handleOk = () =>{

    }

    const getData = async() =>{
        var data={
            url:croppedImageUrl,
            image:cropeImage
        }
       await getCropImage(data)
       await onClose()
    }

        return(
            <>
                <Modal 
                        title={'Post'} 
                        visible={visible} 
                        onOk={handleOk}
                        footer={null}
                        onCancel={(()=>onClose())}
                    >
                        {src && (
                            <ReactCrop
                                src={src}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={((event)=>onImageLoaded(event))}
                                onComplete={((event)=>onCropComplete(event))}
                                onChange={((event)=>onCropChange(event))}
                            />
                        )}
                    <button onClick={(()=>getData())}>crop</button>    
                </Modal>
            </>
        )
}

export default ImageCroper;