import axios from "./config";
import cookies from "js-cookie";
const Api = axios

class PaymentRepository {

    async CreatePayment(data) {
        const reponse = await Api.post(
            `user-payment/`, data
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error.response)
                return (error.response)
            });
        return reponse;
    }

    async GetPackage() {
        const reponse = await Api.get(
            `admin/all-package/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error.response)
                return (error.response)
            });
        return reponse;
    }
}

export default new PaymentRepository();
