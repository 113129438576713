import React from "react";
import ImageComponent from '../../Comman/ImageComponent'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Loader from '../../Comman/UserLoader'
import "../../../App.css"

const Subscriber = ({subscriberList,filtelist,noUser,FilterByName}) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const filterList = async(type) =>{
      await filtelist(type)
    } 

    const LoaderFunction = () =>{
      return (
        <div style={{maxWidth:'250px'}}>
          <Loader/>
        </div>
      ) 
    }
    
    const user = (userData) =>{
        return (
            <>
                 <div className='subslistitems'>
                    <a href='#' className='subslink'>
                        <ImageComponent url={userData.profile_image}/>
                        <span className='subsusername'>@{userData.username}</span>
                    </a>
                </div>
            </>
        )
    }
    return(
        <>           
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange
                    ={handleChange} aria-label="basic tabs example">
                    <Tab label="All" onClick={(()=>filterList('all'))} />
                    <Tab label="Active" onClick={(()=>filterList('active'))}/>
                    <Tab label="Expired" onClick={(()=>filterList('expired'))}/>
                    </Tabs>
                </Box>
                {/* <TabPanel value={value} index={0}> */}
                    <div className='innertab'>
                        <div className='tabspromo'>
                            <input type="text" onChange={(event)=>FilterByName(event.target.value)} placeholder="Find new Creators based on your Subscriptions" />
                        </div>
                        <div className='subscriberslist'>
                            {noUser ? 
                              <>
                                {subscriberList.length > 0 ? subscriberList.map((value,key)=>(
                                    <>
                                        {user(value.subscribedBy)}
                                    </>
                                )):LoaderFunction()}
                              </>  
                            :'No User!'}
                        </div>
                    </div>
                {/* </TabPanel>                */}
            </Box>
        </>
    )
}

export default Subscriber;