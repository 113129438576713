import React, { useEffect, useState } from "react";
import useHome from "../../../hooks/useHome";
import ProfileLeftMenu from "../ProfileLeftMenu";
import SubscriberList from "./SubscriberList";
import DashboardCards from "./DashboardCards";
import Navbar from "../../Header/headerComponent";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const Dashboard = () => {
  const { dashboardData } = useHome();
  const [dashboard, setDashboardData] = useState({
    loved: 0,
    profileVisitor: 0,
    subscriber: 0,
  });

  useEffect(async () => {
    var response = await dashboardData();
    if (response) {
      setDashboardData({
        loved: response.loved,
        profileVisitor: response.profileVisitor,
        subscriber: response.subscriber,
      });
    }
  }, []);

  return (
    <>
      <nav>
        <div className="headerSection">
          <Navbar />
        </div>
      </nav>
      <div className="container">
        <div className="wrapHomeSection">
          <div className="leftSideMenu">
            <ProfileLeftMenu />
          </div>
          <div className="postArea">
            <Card>
              <CardContent>
                <Typography
                  sx={{ fontSize: 16 }}
                  color="text.secondary"
                  gutterBottom
                >
                  28 day Summary
                </Typography>
                <DashboardCards dashboard={dashboard} />
              </CardContent>
            </Card>
          </div>
          <div className="leftSideMenu"></div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
