import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from "@mui/material/Pagination";

function BasicTable({ columns, rows, pageData }) {
  const [rowData, setRowData] = useState(rows)
  // const [page, setPage] = React.useState(pageData.page);
  // const [rowsPerPage, setRowsPerPage] = React.useState(pageData.perPage);
  // const [totalPage, settotalPage] = useState(pageData.total);

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChange = (event, value) => {
    pageData.pageSet(value);
  };

  useEffect(() => {
    setRowData(rows);
  }, [rows])

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length > 0 ? rows?.map((row) => {
              return (
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    {row.cryptoAmount}
                  </TableCell>
                  <TableCell style={{ width: 100 }} align="centre">
                    {row.createdAt}
                  </TableCell>
                  <TableCell style={{ width: 100 }} align="centre">
                    {row.paymentOption}
                  </TableCell>
                  <TableCell style={{ width: 100 }} align="centre">
                    {row.paymentType}
                  </TableCell>
                  <TableCell style={{ width: 100 }} align="centre">
                    {row.paymentStatus}
                  </TableCell>
                </TableRow>)
            })
          : <TableRow align="centre">No Data available</TableRow>
          }

            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
        <Pagination count={pageData.total} page={pageData.page} onChange={handleChange} />
      </TableContainer>
    </>
  );
}

export default BasicTable