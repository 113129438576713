import React,{useEffect, useState} from "react";
import { Menu, Dropdown } from 'antd';
import Popup from '../../../Comman/Popup';
import useHome from '../../../../hooks/useHome';

const Comment = ({comment,edit,deleteSingleComment,updatePostData}) => {

    const [visible,setVisible] = useState(false)
    const [html,setHtml] = useState('')
    const [title,setTitle] = useState('')
    const [singleComment,setSingleComment] = useState({})
    const { LikeComment } = useHome();

    useEffect(()=>{
        setSingleComment(comment)
    },[singleComment,comment])
    

    const editPopup = (id,oldComment)=>{

        var html = (
            <>
                <form onSubmit={((event) => updateComment(event, id))}>
                    <input name="comment" defaultValue={oldComment} />
                    <div>
                        <button type="submit">Update</button>
                    </div>
                </form>
            </>
        )
        setVisible(true)
        setHtml(html)
        setTitle("Edit Comment's")
    }

    const updateComment = async (event, id) => {
        event.preventDefault()
        var data = {
            comment:event.target.comment.value
        }
        await edit(data,id)
        setVisible(false)
    }

    const closePop = () => {
        setVisible(false)
    }

    const commentLike = async(commentId)=>{
        await LikeComment(commentId);
        await updatePostData()
    }
   
    const menu = (
        <Menu>
          <Menu.Item>
            <a href="#" onClick={(()=>editPopup(singleComment._id,singleComment.comment))}>
              Edit Comment
            </a>
          </Menu.Item>
          <Menu.Item>
            <a href="#" onClick={((id)=>deleteSingleComment(singleComment._id))}>
              Delete Comment    
            </a>
          </Menu.Item>          

        </Menu>
    );
    return (
        <>
            <div className="singleComment">
                {singleComment.comment}
            </div>
            <div className="commentActions" onClick={(()=>commentLike(singleComment._id))}>
                <div className="commentlike">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        id="Like"
                        x="0px"
                        y="0px"
                        viewBox="0 0 58 64"
                        style={{ enableBackground: "new 0 0 58 64" }}
                        xmlSpace="preserve"
                    >
                        {" "}
                        <path
                            id="Like_Active"
                            d="M23.9,46.1H12c-2.9,0-5.2,2.3-5.2,5.2s2.3,5.2,5.2,5.2h11.9c2.9,0,5.2-2.3,5.2-5.2S26.8,46.1,23.9,46.1z M23.9,34.6H12c-2.9,0-5.2,2.3-5.2,5.2S9.2,45,12,45h11.9c2.9,0,5.2-2.3,5.2-5.2S26.8,34.6,23.9,34.6z M23.9,23.1H12 c-2.9,0-5.2,2.3-5.2,5.2s2.3,5.2,5.2,5.2h11.9c2.9,0,5.2-2.3,5.2-5.2S26.8,23.1,23.9,23.1z M47,23.1c0,0-0.7,0-2.3,0 c-1.6,0-2.8-0.9-2.8-2.7s0-9.4,0-9.4c0-2.9-2.3-5.2-5.2-5.2s-5.2,2.3-5.2,5.2v17.3V52c0,2.9,2.3,5.2,5.2,5.2H47 c2.9,0,5.2-2.3,5.2-5.2V28.3C52.2,25.4,49.8,23.1,47,23.1z"
                        />
                    </svg>
                    {singleComment.commentLikes ? singleComment.commentLikes.length > 0 ? singleComment.commentLikes.length :'' : ''}
                </div>

                <div className="commentaction">
                    <Dropdown overlay={menu} placement="bottomLeft" arrow>
                        {/* <span>*****</span> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="5" viewBox="0 0 19 5">
                            <g id="Group_15" data-name="Group 15" transform="translate(-2629 -250)">
                                <circle id="Ellipse_1" data-name="Ellipse 1" cx="2.5" cy="2.5" r="2.5" transform="translate(2629 250)"></circle>
                                <circle id="Ellipse_2" data-name="Ellipse 2" cx="2.5" cy="2.5" r="2.5" transform="translate(2636 250)"></circle>
                                <circle id="Ellipse_3" data-name="Ellipse 3" cx="2.5" cy="2.5" r="2.5" transform="translate(2643 250)"></circle>
                            </g>
                        </svg>
                    </Dropdown>
                </div>
            </div>    
            <Popup visible={visible} component={html} onClose={(() => closePop())} title={title} />
        </>
    )
}

export default Comment;