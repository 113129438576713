import React, { useEffect } from "react";
import LeftMenuOptions from "../../utils/menu.json";
import { useHistory } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from '@mui/material/Divider';
import LogoProfile from "../../images/LogoProfile.png"

const LeftMenu = (props) => {
  let history = useHistory();

  const changeRoute = (type) => {
    history.push(type);
  };

  const Menu = () => {
    return (
      <div className="profileLeftbar">
        <div className="profileLogo">
          <img src={LogoProfile} />
        </div>
        <div className="profileSingleLinnk">
          <List>
            <ListItem button>
              <ListItemIcon>
                <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M41.3236 18.1252H43.0609C44.6839 18.1252 45.9996 19.4409 45.9998 21.0637V24.9363C45.9998 26.5593 44.6841 27.875 43.0611 27.875H41.3238C40.8878 29.5176 40.2374 31.0732 39.4033 32.5088L40.6333 33.7387C41.1844 34.2899 41.494 35.0372 41.494 35.8167C41.494 36.5961 41.1846 37.3434 40.6333 37.8946L37.8948 40.6331C36.7472 41.7806 34.8867 41.7806 33.7391 40.6331L32.5091 39.403C31.0733 40.2371 29.5182 40.8876 27.8753 41.3237V43.0615C27.8753 44.6845 26.5596 46.0002 24.9366 46.0002H21.0639C19.4409 46.0002 18.1252 44.6845 18.1252 43.0615V41.3237C16.4825 40.8878 14.9272 40.2372 13.4916 39.4031L12.2616 40.6331C11.1141 41.7806 9.25351 41.7806 8.10593 40.6331L5.36743 37.8946C4.21986 36.747 4.21986 34.8865 5.36743 33.7389L6.59723 32.5091C5.7631 31.0733 5.11254 29.518 4.67654 27.8751H2.93852C1.3157 27.8751 0 26.5595 0 24.9365V21.0639C0 19.4409 1.3157 18.1252 2.93852 18.1252H4.67654C5.11236 16.4824 5.76292 14.927 6.59705 13.4913L5.36743 12.2616C4.21986 11.1141 4.21986 9.25352 5.36743 8.10593L8.10576 5.36742C9.25333 4.21967 11.1139 4.21967 12.2615 5.36725L13.4914 6.59707C14.927 5.76312 16.4824 5.11255 18.125 4.6767V2.93869C18.125 1.3157 19.4407 0 21.0637 0H24.9365C26.5595 0 27.8752 1.3157 27.8752 2.93869V4.6767C29.518 5.11272 31.0732 5.76312 32.5089 6.59724L33.7387 5.36742C34.8863 4.21988 36.7468 4.21988 37.8944 5.36742L40.6329 8.10593C41.7805 9.25352 41.7805 11.1141 40.6329 12.2616L39.4031 13.4914C40.2372 14.9272 40.8878 16.4824 41.3236 18.1252ZM12.4818 23C12.4818 28.7996 17.2003 33.518 23 33.518C28.7997 33.518 33.5182 28.7996 33.5182 23C33.5182 17.2003 28.7997 12.4818 23 12.4818C17.2003 12.4818 12.4818 17.2003 12.4818 23Z" fill="#6F6F6F" />
                </svg>
              </ListItemIcon>
              <a onClick={() => changeRoute('settings')}>Settings</a>
            </ListItem>
          </List>
        </div>
        <div className="sidebarStaticHeading">
          <h3>ACCOUNT</h3>
        </div>
        <div className="profileSingleLinnk">
          <List className="customDivider">
            <ListItem button>
              <ListItemIcon>
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.5C0 7.66479 7.15894 0.5 16 0.5C24.8468 0.5 32 7.66827 32 16.5C32 25.3649 24.8184 32.5 16 32.5C7.12442 32.5 0 25.3068 0 16.5ZM20.5517 17.8165C23.439 19.1729 25.5791 21.777 26.2042 24.8928C26.7904 24.1827 27.2956 23.4202 27.7162 22.617C26.6543 19.8351 24.5609 17.5324 21.8869 16.1441C22.6598 14.8466 23.0762 13.3181 23.0762 11.7244C23.0762 7.31036 19.9036 3.7767 16 3.7767C12.1041 3.7767 8.92374 7.30206 8.92374 11.7244C8.92374 13.316 9.33911 14.8426 10.1102 16.1389C9.09532 16.6651 8.15073 17.3302 7.30402 18.1251C5.94196 19.404 4.91887 20.9352 4.28137 22.6122C4.70177 23.4159 5.20679 24.1789 5.79291 24.8894C6.41142 21.7424 8.54065 19.1486 11.4347 17.8036C12.6558 18.9622 14.2513 19.6721 16 19.6721C17.7024 19.6721 19.3014 18.9967 20.5517 17.8165Z" fill="#6F6F6F" />
                </svg>
              </ListItemIcon>
              <a onClick={() => changeRoute('settings')}>User Profile</a>
            </ListItem>
          </List>
        </div>
        <div className="profileSingleLinnk">
          <List className="customDivider">
            <ListItem button>
              <ListItemIcon>
                <svg width="23" height="29" viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5141 11.9761C20.6351 11.4765 19.5368 11.847 19.061 12.7509L16.7172 17.532C16.6261 17.7198 16.4049 17.8048 16.2114 17.7262C16.0603 17.6661 15.9612 17.5196 15.9616 17.357V4.07812C15.9616 3.29025 15.2338 2.71875 14.4454 2.71875C13.6569 2.71875 12.9727 3.29025 12.9727 4.07812V12.9503C12.9767 13.2948 12.7058 13.58 12.3616 13.5938C12.1943 13.5969 12.0327 13.5327 11.9133 13.4155C11.7939 13.2983 11.7266 13.138 11.7266 12.9707V1.35938C11.7266 0.571504 11.107 0 10.3185 0C9.53009 0 8.89459 0.571504 8.89459 1.35938V12.9503C8.89854 13.2948 8.6277 13.58 8.28343 13.5938C8.11614 13.5969 7.95459 13.5327 7.83516 13.4155C7.71573 13.2983 7.64846 13.138 7.64849 12.9707V3.17188C7.64849 2.384 6.96427 1.8125 6.17584 1.8125C5.3874 1.8125 4.70318 2.44064 4.70318 3.22852V13.8566C4.70713 14.2011 4.43629 14.4862 4.09203 14.5C3.92473 14.5032 3.76319 14.439 3.64376 14.3218C3.52433 14.2046 3.45705 14.0443 3.45709 13.877V6.79688C3.45709 6.009 2.82951 5.4375 2.04107 5.4375C1.25263 5.4375 0.618257 6.01637 0.625054 6.79688V19.0312C0.625054 25.6814 4.70318 29 9.68755 29H10.5938C15.5782 29 17.1477 26.757 18.2969 24.0156L22.1887 14.4434C22.5637 13.4238 22.3926 12.4751 21.5141 11.9761Z" fill="#6F6F6F" />
                </svg>
              </ListItemIcon>
              <a onClick={() => changeRoute('account_settings')}>Account</a>
            </ListItem>
          </List>
        </div>
        <List sx={{ ml: 2, bgcolor: 'background.paper' }} className="customDivider">
          <ListItem button>
            <ListItemIcon>
              <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.77881 6.3538C4.77881 9.51853 7.35341 12.093 10.5181 12.093C13.683 12.093 16.2576 9.51853 16.2576 6.3538C16.2576 3.18908 13.683 0.61438 10.5181 0.61438C7.35341 0.61438 4.77881 3.18908 4.77881 6.3538V6.3538Z" fill="#6F6F6F" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.8836 24.4962C20.1515 24.764 20.5026 24.898 20.8536 24.898C21.2048 24.898 21.5559 24.764 21.8238 24.4961C22.3595 23.9603 22.3595 23.0916 21.8237 22.5558L20.05 20.7822L21.8239 19.0082C22.3596 18.4724 22.3596 17.6037 21.8238 17.0679C21.288 16.5322 20.4193 16.5323 19.8835 17.068L17.1396 19.812C16.8823 20.0694 16.7378 20.4184 16.7378 20.7822C16.7378 21.1461 16.8823 21.4951 17.1397 21.7524L19.8836 24.4962Z" fill="#6F6F6F" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8594 26.7271H1.37195C0.614273 26.7271 0 26.1129 0 25.3552C0 19.5554 4.71851 14.8369 10.5183 14.8369C11.2507 14.8369 11.9761 14.9139 12.6862 15.0628C14.438 12.7072 17.2419 11.1783 20.3963 11.1783C25.6917 11.1783 30 15.4866 30 20.782C30 26.0775 25.6917 30.3857 20.3963 30.3857C17.3439 30.3857 14.6197 28.9541 12.8594 26.7271ZM27.2561 20.782C27.2561 16.9995 24.1788 13.9222 20.3963 13.9222C16.6138 13.9222 13.5366 16.9995 13.5366 20.782C13.5366 24.5645 16.6139 27.6417 20.3963 27.6417C24.1788 27.6417 27.2561 24.5645 27.2561 20.782Z" fill="#6F6F6F" />
              </svg>
            </ListItemIcon>
            <a onClick={() => changeRoute('followers')}>Followers</a>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.77783 6.35229C4.77783 9.51625 7.35182 12.0901 10.5158 12.0901C13.6798 12.0901 16.2538 9.51625 16.2538 6.35229C16.2538 3.18833 13.6798 0.614258 10.5158 0.614258C7.35182 0.614258 4.77783 3.18833 4.77783 6.35229H4.77783Z" fill="#6F6F6F" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9894 17.0637C19.7216 16.7959 19.3706 16.662 19.0196 16.662C18.6686 16.662 18.3175 16.7959 18.0497 17.0638C17.5141 17.5994 17.5141 18.468 18.0498 19.0036L19.8231 20.7768L18.0496 22.5503C17.514 23.086 17.514 23.9545 18.0497 24.4902C18.5854 25.0257 19.4539 25.0256 19.9895 24.4901L22.7328 21.7467C22.99 21.4894 23.1345 21.1405 23.1345 20.7768C23.1345 20.4129 22.99 20.0641 22.7327 19.8068L19.9894 17.0637Z" fill="#6F6F6F" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8563 26.7206H1.37161C0.614105 26.7206 0 26.1065 0 25.3491C0 19.5507 4.71735 14.8333 10.5157 14.8333C11.248 14.8333 11.9732 14.9103 12.6832 15.0592C14.4344 12.7042 17.2378 11.1757 20.3914 11.1757C25.6854 11.1757 29.9927 15.4829 29.9927 20.777C29.9927 26.0712 25.6854 30.3783 20.3914 30.3783C17.3397 30.3783 14.6161 28.9471 12.8563 26.7206V26.7206ZM27.2495 20.777C27.2495 16.9954 24.1729 13.9189 20.3914 13.9189C16.6097 13.9189 13.5333 16.9954 13.5333 20.777C13.5333 24.5585 16.6098 27.6351 20.3914 27.6351C24.1729 27.6351 27.2495 24.5585 27.2495 20.777Z" fill="#6F6F6F" />
              </svg>
            </ListItemIcon>
            <a onClick={() => changeRoute('following')}>Following</a>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.28125 15C0.28125 7.15985 6.65987 0.78125 14.5 0.78125C22.3401 0.78125 28.7188 7.15985 28.7188 15C28.7188 22.8401 22.3401 29.2188 14.5 29.2188C6.65987 29.2188 0.28125 22.8401 0.28125 15ZM14.5 22.4054C16.8926 20.6943 19.0247 18.7721 19.6058 18.0639C20.9723 16.3979 21.6278 14.687 21.6087 12.8351C21.5875 10.7119 19.8847 8.98438 17.8127 8.98438C16.306 8.98438 15.252 9.82452 14.6552 10.5396L14.5 10.7275L14.3448 10.5396C13.7371 9.83203 12.6912 8.9837 11.1873 8.9837C9.11533 8.9837 7.41249 10.7111 7.3913 12.8344C7.37216 14.687 8.02704 16.3979 9.39423 18.0639C9.97528 18.7721 12.1074 20.6964 14.5 22.4054Z" fill="#6F6F6F" />
              </svg>
            </ListItemIcon>
            <a onClick={() => changeRoute('subscribers')}>Subscribers</a>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2463 0.655396C17.0267 0.655396 15.066 1.76969 13.8943 3.46758C13.8943 3.46758 13.4987 4.05962 12.8712 4.05962C12.2443 4.05962 11.8487 3.46758 11.8487 3.46758C10.6764 1.76969 8.71682 0.655396 6.49667 0.655396C2.90842 0.655396 0 3.56381 0 7.15319C0 8.00073 0.166581 8.80719 0.461475 9.54949C2.28149 15.2009 10.4215 21.845 12.8712 22.0875C15.3215 21.845 23.4604 15.2009 25.2815 9.54949C25.5764 8.80775 25.743 8.00073 25.743 7.15319C25.743 3.56381 22.8346 0.655396 19.2463 0.655396Z" fill="#6F6F6F" />
              </svg>
            </ListItemIcon>
            <a onClick={() => changeRoute('subscribtions')}>Subscriptions</a>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2463 0.655396C17.0267 0.655396 15.066 1.76969 13.8943 3.46758C13.8943 3.46758 13.4987 4.05962 12.8712 4.05962C12.2443 4.05962 11.8487 3.46758 11.8487 3.46758C10.6764 1.76969 8.71682 0.655396 6.49667 0.655396C2.90842 0.655396 0 3.56381 0 7.15319C0 8.00073 0.166581 8.80719 0.461475 9.54949C2.28149 15.2009 10.4215 21.845 12.8712 22.0875C15.3215 21.845 23.4604 15.2009 25.2815 9.54949C25.5764 8.80775 25.743 8.00073 25.743 7.15319C25.743 3.56381 22.8346 0.655396 19.2463 0.655396Z" fill="#6F6F6F" />
              </svg>
            </ListItemIcon>
            <a onClick={() => changeRoute('my_purchase')}>My Purchases</a>
          </ListItem>
        </List>

        <div className="sidebarStaticHeading">
          <h3>CREATORS SETTING</h3>
        </div>
        <div className="profileSingleLinnk">
          <List className="customDivider">
            <ListItem button>
              <ListItemIcon>
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.5C0 7.66479 7.15894 0.5 16 0.5C24.8468 0.5 32 7.66827 32 16.5C32 25.3649 24.8184 32.5 16 32.5C7.12442 32.5 0 25.3068 0 16.5ZM20.5517 17.8165C23.439 19.1729 25.5791 21.777 26.2042 24.8928C26.7904 24.1827 27.2956 23.4202 27.7162 22.617C26.6543 19.8351 24.5609 17.5324 21.8869 16.1441C22.6598 14.8466 23.0762 13.3181 23.0762 11.7244C23.0762 7.31036 19.9036 3.7767 16 3.7767C12.1041 3.7767 8.92374 7.30206 8.92374 11.7244C8.92374 13.316 9.33911 14.8426 10.1102 16.1389C9.09532 16.6651 8.15073 17.3302 7.30402 18.1251C5.94196 19.404 4.91887 20.9352 4.28137 22.6122C4.70177 23.4159 5.20679 24.1789 5.79291 24.8894C6.41142 21.7424 8.54065 19.1486 11.4347 17.8036C12.6558 18.9622 14.2513 19.6721 16 19.6721C17.7024 19.6721 19.3014 18.9967 20.5517 17.8165Z" fill="#6F6F6F" />
                </svg>
              </ListItemIcon>
              <a onClick={() => changeRoute('setting_dashboard')}>Dashboard</a>
            </ListItem>
          </List>
        </div>
        <div className="profileSingleLinnk">
          <List className="customDivider">
            <ListItem button>
              <ListItemIcon>
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.5C0 7.66479 7.15894 0.5 16 0.5C24.8468 0.5 32 7.66827 32 16.5C32 25.3649 24.8184 32.5 16 32.5C7.12442 32.5 0 25.3068 0 16.5ZM20.5517 17.8165C23.439 19.1729 25.5791 21.777 26.2042 24.8928C26.7904 24.1827 27.2956 23.4202 27.7162 22.617C26.6543 19.8351 24.5609 17.5324 21.8869 16.1441C22.6598 14.8466 23.0762 13.3181 23.0762 11.7244C23.0762 7.31036 19.9036 3.7767 16 3.7767C12.1041 3.7767 8.92374 7.30206 8.92374 11.7244C8.92374 13.316 9.33911 14.8426 10.1102 16.1389C9.09532 16.6651 8.15073 17.3302 7.30402 18.1251C5.94196 19.404 4.91887 20.9352 4.28137 22.6122C4.70177 23.4159 5.20679 24.1789 5.79291 24.8894C6.41142 21.7424 8.54065 19.1486 11.4347 17.8036C12.6558 18.9622 14.2513 19.6721 16 19.6721C17.7024 19.6721 19.3014 18.9967 20.5517 17.8165Z" fill="#6F6F6F" />
                </svg>
              </ListItemIcon>
              <a onClick={() => changeRoute('payment_history')}>Payout History</a>
            </ListItem>
          </List>
        </div>
        <div className="profileSingleLinnk">
          <List className="customDivider">
            <ListItem button>
              <ListItemIcon>
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.5C0 7.66479 7.15894 0.5 16 0.5C24.8468 0.5 32 7.66827 32 16.5C32 25.3649 24.8184 32.5 16 32.5C7.12442 32.5 0 25.3068 0 16.5ZM20.5517 17.8165C23.439 19.1729 25.5791 21.777 26.2042 24.8928C26.7904 24.1827 27.2956 23.4202 27.7162 22.617C26.6543 19.8351 24.5609 17.5324 21.8869 16.1441C22.6598 14.8466 23.0762 13.3181 23.0762 11.7244C23.0762 7.31036 19.9036 3.7767 16 3.7767C12.1041 3.7767 8.92374 7.30206 8.92374 11.7244C8.92374 13.316 9.33911 14.8426 10.1102 16.1389C9.09532 16.6651 8.15073 17.3302 7.30402 18.1251C5.94196 19.404 4.91887 20.9352 4.28137 22.6122C4.70177 23.4159 5.20679 24.1789 5.79291 24.8894C6.41142 21.7424 8.54065 19.1486 11.4347 17.8036C12.6558 18.9622 14.2513 19.6721 16 19.6721C17.7024 19.6721 19.3014 18.9967 20.5517 17.8165Z" fill="#6F6F6F" />
                </svg>
              </ListItemIcon>
              <a onClick={() => changeRoute('payment_method')}>Payout Methods</a>
            </ListItem>
          </List>
        </div>

        <div className="profileSingleLinnk">
          <List className="customDivider">
            <ListItem button>
              <ListItemIcon>
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.5C0 7.66479 7.15894 0.5 16 0.5C24.8468 0.5 32 7.66827 32 16.5C32 25.3649 24.8184 32.5 16 32.5C7.12442 32.5 0 25.3068 0 16.5ZM20.5517 17.8165C23.439 19.1729 25.5791 21.777 26.2042 24.8928C26.7904 24.1827 27.2956 23.4202 27.7162 22.617C26.6543 19.8351 24.5609 17.5324 21.8869 16.1441C22.6598 14.8466 23.0762 13.3181 23.0762 11.7244C23.0762 7.31036 19.9036 3.7767 16 3.7767C12.1041 3.7767 8.92374 7.30206 8.92374 11.7244C8.92374 13.316 9.33911 14.8426 10.1102 16.1389C9.09532 16.6651 8.15073 17.3302 7.30402 18.1251C5.94196 19.404 4.91887 20.9352 4.28137 22.6122C4.70177 23.4159 5.20679 24.1789 5.79291 24.8894C6.41142 21.7424 8.54065 19.1486 11.4347 17.8036C12.6558 18.9622 14.2513 19.6721 16 19.6721C17.7024 19.6721 19.3014 18.9967 20.5517 17.8165Z" fill="#6F6F6F" />
                </svg>
              </ListItemIcon>
              <a onClick={() => changeRoute('recived_payment')}>Recived Payments</a>
            </ListItem>
          </List>
        </div>
        <div className="profileSingleLinnk">
          <List className="customDivider">
            <ListItem button>
              <ListItemIcon>
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.5C0 7.66479 7.15894 0.5 16 0.5C24.8468 0.5 32 7.66827 32 16.5C32 25.3649 24.8184 32.5 16 32.5C7.12442 32.5 0 25.3068 0 16.5ZM20.5517 17.8165C23.439 19.1729 25.5791 21.777 26.2042 24.8928C26.7904 24.1827 27.2956 23.4202 27.7162 22.617C26.6543 19.8351 24.5609 17.5324 21.8869 16.1441C22.6598 14.8466 23.0762 13.3181 23.0762 11.7244C23.0762 7.31036 19.9036 3.7767 16 3.7767C12.1041 3.7767 8.92374 7.30206 8.92374 11.7244C8.92374 13.316 9.33911 14.8426 10.1102 16.1389C9.09532 16.6651 8.15073 17.3302 7.30402 18.1251C5.94196 19.404 4.91887 20.9352 4.28137 22.6122C4.70177 23.4159 5.20679 24.1789 5.79291 24.8894C6.41142 21.7424 8.54065 19.1486 11.4347 17.8036C12.6558 18.9622 14.2513 19.6721 16 19.6721C17.7024 19.6721 19.3014 18.9967 20.5517 17.8165Z" fill="#6F6F6F" />
                </svg>
              </ListItemIcon>
              <a onClick={() => changeRoute('final_earning')}>Finalised Earnings</a>
            </ListItem>
          </List>
        </div>
        <div className="profileSingleLinnk">
          <List className="customDivider">
            <ListItem button>
              <ListItemIcon>
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.5C0 7.66479 7.15894 0.5 16 0.5C24.8468 0.5 32 7.66827 32 16.5C32 25.3649 24.8184 32.5 16 32.5C7.12442 32.5 0 25.3068 0 16.5ZM20.5517 17.8165C23.439 19.1729 25.5791 21.777 26.2042 24.8928C26.7904 24.1827 27.2956 23.4202 27.7162 22.617C26.6543 19.8351 24.5609 17.5324 21.8869 16.1441C22.6598 14.8466 23.0762 13.3181 23.0762 11.7244C23.0762 7.31036 19.9036 3.7767 16 3.7767C12.1041 3.7767 8.92374 7.30206 8.92374 11.7244C8.92374 13.316 9.33911 14.8426 10.1102 16.1389C9.09532 16.6651 8.15073 17.3302 7.30402 18.1251C5.94196 19.404 4.91887 20.9352 4.28137 22.6122C4.70177 23.4159 5.20679 24.1789 5.79291 24.8894C6.41142 21.7424 8.54065 19.1486 11.4347 17.8036C12.6558 18.9622 14.2513 19.6721 16 19.6721C17.7024 19.6721 19.3014 18.9967 20.5517 17.8165Z" fill="#6F6F6F" />
                </svg>
              </ListItemIcon>
              <a onClick={() => changeRoute('subscription_payment')}>Subscription Payments</a>
            </ListItem>
          </List>
        </div>
        <div className="profileSingleLinnk">
          <List className="customDivider">
            <ListItem button>
              <ListItemIcon>
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.5C0 7.66479 7.15894 0.5 16 0.5C24.8468 0.5 32 7.66827 32 16.5C32 25.3649 24.8184 32.5 16 32.5C7.12442 32.5 0 25.3068 0 16.5ZM20.5517 17.8165C23.439 19.1729 25.5791 21.777 26.2042 24.8928C26.7904 24.1827 27.2956 23.4202 27.7162 22.617C26.6543 19.8351 24.5609 17.5324 21.8869 16.1441C22.6598 14.8466 23.0762 13.3181 23.0762 11.7244C23.0762 7.31036 19.9036 3.7767 16 3.7767C12.1041 3.7767 8.92374 7.30206 8.92374 11.7244C8.92374 13.316 9.33911 14.8426 10.1102 16.1389C9.09532 16.6651 8.15073 17.3302 7.30402 18.1251C5.94196 19.404 4.91887 20.9352 4.28137 22.6122C4.70177 23.4159 5.20679 24.1789 5.79291 24.8894C6.41142 21.7424 8.54065 19.1486 11.4347 17.8036C12.6558 18.9622 14.2513 19.6721 16 19.6721C17.7024 19.6721 19.3014 18.9967 20.5517 17.8165Z" fill="#6F6F6F" />
                </svg>
              </ListItemIcon>
              <a onClick={() => changeRoute('subscribtion_package')}>Subscription Packages</a>
            </ListItem>
          </List>
        </div>
        <List sx={{ ml: 2, bgcolor: 'background.paper' }}>
          <ListItem button>
            <ListItemIcon>
              <svg width="37" height="24" viewBox="0 0 37 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.1294 0.105835H1.87055C0.837498 0.105835 0 0.943333 0 1.97639V4.10571H37V1.97639C37 0.943333 36.1625 0.105835 35.1294 0.105835Z" fill="#6F6F6F" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.87056 23.8942C0.837502 23.8942 0 23.0567 0 22.0237V9.57532H36.9999V22.0237C36.9999 23.0567 36.1624 23.8942 35.1294 23.8942H1.87056ZM4.77132 12.6675H22.9249C23.5963 12.6675 24.1406 13.2119 24.1406 13.8832C24.1406 14.5546 23.5963 15.0988 22.9249 15.0988H4.77132C4.09996 15.0988 3.55568 14.5546 3.55568 13.8832C3.55568 13.2119 4.09996 12.6675 4.77132 12.6675ZM8.58034 17.287H4.77132C4.09996 17.287 3.55568 17.8313 3.55568 18.5026C3.55568 19.174 4.09996 19.7183 4.77132 19.7183H8.58034C9.25179 19.7183 9.79599 19.174 9.79599 18.5026C9.79599 17.8313 9.25171 17.287 8.58034 17.287ZM13.548 19.7183H15.8983C16.5696 19.7183 17.1139 19.174 17.1139 18.5026C17.1139 17.8313 16.5696 17.287 15.8983 17.287H13.548C12.8767 17.287 12.3324 17.8313 12.3324 18.5026C12.3324 19.174 12.8767 19.7183 13.548 19.7183ZM23.3542 19.7183C24.0256 19.7183 24.5699 19.174 24.5699 18.5026C24.5699 17.8313 24.0256 17.287 23.3542 17.287H21.004C20.3326 17.287 19.7883 17.8313 19.7883 18.5026C19.7883 19.174 20.3326 19.7183 21.004 19.7183H23.3542ZM28.2408 17.287H32.0498C32.7212 17.287 33.2655 17.8313 33.2655 18.5026C33.2655 19.174 32.7212 19.7183 32.0498 19.7183H28.2408C27.5694 19.7183 27.0251 19.174 27.0251 18.5026C27.0251 17.8313 27.5694 17.287 28.2408 17.287Z" fill="#6F6F6F" />
              </svg>
            </ListItemIcon>
            <a onClick={() => changeRoute('')}>Wallet Credits</a>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <svg width="37" height="32" viewBox="0 0 37 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.175 11.3542C36.183 11.3542 37.0001 12.1714 37.0001 13.1793V18.6547C37.0001 19.6626 36.183 20.4799 35.175 20.4799H24.7221C23.7141 20.4799 22.897 19.6626 22.897 18.6547V13.1793C22.897 12.1714 23.7141 11.3542 24.7221 11.3542H35.175ZM25.9983 15.9409C25.9983 17.116 26.9509 18.0685 28.1259 18.0685C29.301 18.0685 30.2535 17.1158 30.2535 15.9409C30.2535 14.7659 29.301 13.8134 28.1259 13.8134C26.9509 13.8134 25.9983 14.7659 25.9983 15.9409Z" fill="#6F6F6F" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4925 8.86549H34.0135V3.39016C34.0135 2.01784 32.9524 0.901367 31.5799 0.901367H2.54404C1.17172 0.901367 0 2.01784 0 3.39016V28.6099C0 29.9822 1.17172 31.0987 2.54404 31.0987H31.5799C32.9522 31.0987 34.0135 29.9822 34.0135 28.6099V22.9686H20.5172L20.4925 8.86549V8.86549Z" fill="#6F6F6F" />
              </svg>
            </ListItemIcon>
            <a onClick={() => changeRoute('')}>Wallet History</a>
          </ListItem>
        </List>

        <div className="profileFooter">
          <List>
            <ListItem button>
              <a onClick={() => changeRoute('')}>Privacy Policies</a>
            </ListItem>
            <ListItem button>
              <a onClick={() => changeRoute('')}>Terms Of Use</a>
            </ListItem>
            <ListItem button>
              <a onClick={() => changeRoute('')}>Cookies</a>
            </ListItem>
            <ListItem button>
              <a onClick={() => changeRoute('')}>Crator© 2022</a>
            </ListItem>
          </List>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        {Menu()}
      </div>
    </>
  );
};

export default LeftMenu;
