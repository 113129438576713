import AdminRepository from "../api/AdminRepository";
import { useDispatch } from "react-redux";

export default function useAdminDashboard() {
  const dispatch = useDispatch();
  return {
    getAllUserCount: async () => {
      var responseData = await AdminRepository.GetAllUserCount();
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },

    getAllPostCount: async () => {
      var responseData = await AdminRepository.GetAllPostCount();
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },

    getadminEarningCount: async () => {
      var responseData = await AdminRepository.GetadminEarningCount();
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },

    getAdminSubscriptionEarning: async () => {
      var responseData = await AdminRepository.GetAdminSubscriptionEarning();
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },

    getPremiumPostCount: async () => {
      var responseData = await AdminRepository.getPremiumPostCount();
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },

    getLatestUsers: async () => {
      var responseData = await AdminRepository.GetLastestUsers();
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },

    getLastestSubsriptions: async () => {
      var responseData = await AdminRepository.GetLastestSubsriptions();
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },

    getAllPostData: async () => {
      var responseData = await AdminRepository.GetAllPostData();
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },

    updatePostData: async (data, postId) => {
      var responseData = await AdminRepository.updatePostData(data, postId);
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },
    postDeleteData: async (id) => {
      var responseData = await AdminRepository.postDeleteData(id);
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },
    posActiveDeactiveData: async (id, status) => {
      var responseData = await AdminRepository.posActiveDeactiveData(id);
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },

    webSiteSetting: async (event) => {
      var responseData = await AdminRepository.webSiteSetting(event);
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },
    webSiteSettingImg: async (data) => {
      var responseData = await AdminRepository.webSiteSettingImg(data);
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },
    webSiteGenSetting: async (data) => {
      var responseData = await AdminRepository.webSiteGenSetting(data);
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },
    webLimitSetting: async (data) => {
      var responseData = await AdminRepository.webLimitSetting(data);
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },
    billingInformations: async (data) => {
      var responseData = await AdminRepository.billingInformations(data);
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },
    createLanguagesData: async (data) => {
      var responseData = await AdminRepository.createLanguagesData(data);
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },

    //code for primium post
    getAllPrimiumPostData: async (data) => {
      var responseData = await AdminRepository.getAllPrimiumPostData(data);
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },


  };

}
