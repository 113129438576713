import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from 'lodash'
import Navbar from '../../Header/headerComponent';
import useUser from "../../../hooks/useUser";
import LeftMenu from "../LeftMenu";
import { useHistory } from "react-router-dom";
import './wallet-credits.css';


const WalletCredits = () => {
    const { auth } = useSelector((state) => state.auth);
    const { isLogin } = useUser()
    let history = useHistory();

    useEffect(async () => {
        await authCheck();
    }, [])

    const authCheck = async () => {
        var response = await isLogin();
        if (parseInt(response.status) === 401) {
            history.push("/login");
        }
    };
    return (
        <>
            <nav>
                <div className="headerSection">
                    <Navbar />
                </div>
            </nav>
            <div className="container">
                <div className="wrapHomeSection wrapHomeSectionWalletCredit">
                    <div className="leftSideMenu">
                        <LeftMenu />
                    </div>
                    <div className="__WalletCredits_wrap">
                        <div className="__WalletCredits_inner_item">
                            <h3>Balance is:</h3>
                        </div>
                        <div className="__WalletCredits_inner_item">
                            <div className="__WalletCredits_inner_btns">
                                <input
                                    type="text"
                                    name="userBalance"
                                    className="form-control form-control-sm"
                                    placeholder="$0.00"
                                />
                                <button>Add Credits</button>
                                <p>Or choose</p>
                                <button>Payment method</button>
                            </div>
                            <div className="save_input_btn">
                                <button>Save</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WalletCredits;



