import axios from "./config";
import cookies from "js-cookie";
const Api = axios

const token = cookies.get("token");
Api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
class ChatRepository {
  async getConversations(limit = 10) {
    try {
      const url = `chat/get-conversations?limit=${limit}`;
      const response = await Api.get(url);
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  async getChats(cid, page = 1, limit = 20) {
    try {
      let url = `chat/get-chats/${cid}`;
      url = `${url}?page=${page}&limit=${limit}`;
      const response = await Api.get(url);
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  async saveChats(data, multipart = false) {
    try {
      const url = `chat/save-chat`;
      let headers = { "Content-Type": "application/json" };
      if (multipart) {
        headers = { "Content-Type": "multipart/form-data" };
      }
      const response = await Api.post(url, data, { headers });
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  async deleteChat(id) {
    try {
      const url = `chat/delete-chat/${id}`;
      const response = await Api.get(url);
      return response;
    } catch (error) {
      throw error.response;
    }
  }
}

export default new ChatRepository();
