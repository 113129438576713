import React, { useEffect, useState,useRef } from "react";
import useHome from '../../../hooks/useHome'
import ProfileLeftMenu from '../ProfileLeftMenu'
import SubscriberList from "./SubscriberList";
import TabsComponent from "./Comman/TabsComponent";
import Navbar from "../../Header/headerComponent";

const Subscriber = ({ type }) => {
    const { follwersUsers,subscribeUsers } = useHome()
    const _isMounted = useRef(true);
    const [subscriber, setSubscriber] = useState([])
    const [noUser,setNoUser] = useState(true)

    useEffect(async () => {  
        var filter = 'all'
        if(type !== 'subscriber'){  
            var response = await follwersUsers(filter)
            if(response.userSubscriptions.length > 0){
                await setSubscriber(response.userSubscriptions)
                await setNoUser(true)
            }
            else{
                await setNoUser(false)
            }
        }
        else{   
            var response = await subscribeUsers(filter)
            if(response.userSubscriptions.length > 0){
                await setSubscriber(response.userSubscriptions)
                await setNoUser(true)
            }
            else{
                await setNoUser(false)
            }
            
        }  
        return () => { // ComponentWillUnmount in Class Component
            _isMounted.current = false;
        }

    }, [type])

    const filtelist = async(filter) =>{
        if(type !== 'subscriber'){  
            var response = await follwersUsers(filter)
            if(response.userSubscriptions.length > 0){
                await setSubscriber(response.userSubscriptions)
                await setNoUser(true)
            }
            else{
                await setNoUser(false)
            }
        }
        else{   
            var response = await subscribeUsers(filter)
            if(response.userSubscriptions.length > 0){
                await setSubscriber(response.userSubscriptions)
                await setNoUser(true)
            }
            else{
                await setNoUser(false)
            }
        }
    }

    const FilterByName = (search)=>{
        console.log('search',subscriber)
        subscriber.filter((u) =>{
            console.log('u',u.subscribeTo.includes(search))
            console.log(u.subscribeTo.username.search(search))
        })
    }

    return (
        <>
            <nav>
                <div className="headerSection">
                    <Navbar />
                </div>
            </nav>
            <div className="container">
                <div className="wrapHomeSection">
                    <div className="leftSideMenu">
                        <ProfileLeftMenu />
                    </div>
                    <div className="postArea">
                        <div>
                            <SubscriberList FilterByName={(name)=>FilterByName(name)} filtelist={((type)=>filtelist(type))} noUser={noUser} subscriberList={subscriber} /> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Subscriber;