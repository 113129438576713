export const actionTypes = {
  LOADING: "LOADING",
  LOGOUT: "LOGOUT",
  UPDATE_PROFILE: "UPDATE_PROFILE",
};

export function loading(payload) {
  return { type: actionTypes.LOADING, payload };
}

export function logout() {
  return { type: actionTypes.LOGOUT };
}

export function updateUserProfile(profile) {
  return { type: actionTypes.UPDATE_PROFILE, profile };
}
