import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import useAdmin from '../../../hooks/useAdmin';
import { notification } from 'antd';
import { SmileOutlined, CloseOutlined } from '@ant-design/icons';
import Box from "@mui/material/Box";
import AdminLeftMenu from '../../admin/adminLeftSideMenu/adminLeftMenuComponent';
import EditPackageForm from "./Forms/EditPackageForm";

const UpdatePackages = (props) => {
    let history = useHistory();
    const [compProp] = useState(props?.location?.state);
    const packageId = compProp?.packageId;
    const [reload, setReload] = useState(false);
    const { auth } = useSelector((state) => state.auth);
    const { isLogin } = useUser()
    const { getPackageDetailById, editPackages } = useAdmin();
    const [packageDetails, setPackageDetails] = useState([]);

    useEffect(async () => {
        await authCheck();
        setPackageDetails(await getPackageDetailById(packageId));
    }, [reload])

    const authCheck = async () => {
        var response = await isLogin();
        if (parseInt(response.status) === 401) {
            history.push("/login");
        }
    }

    const updatePacakgeDetails = (data) => {
        const res = editPackages(data, packageId);
        if (res) {
            notification.open({
                message: 'Update',
                description: res.message,
                icon: <SmileOutlined style={{ color: '#108ee9' }} />,
            });
        }
        else {
            notification.open({
                message: 'Error',
                description: 'Something went wrong...!',
                icon: <CloseOutlined style={{ color: '#f4364c' }} />,
            });
        }
        history.push('/packages');
        return res;
    }

    return (
        <nav>
            <div style={{ display: "flex" }}>
                <div className="sideBar">
                    <AdminLeftMenu />
                </div>
                <div style={{ width: "100%" }}>
                    <Box sx={{ flexGrow: 1 }}>
                        {auth && <EditPackageForm details={packageDetails?.data} packageUpdate={(data) => updatePacakgeDetails(data)} />}
                    </Box>
                </div>
            </div>
        </nav>
    );
};

export default connect((state) => state)(UpdatePackages);