import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import LeftMenu from "../LeftMenu";
import useDashboard from '../../../hooks/useDashboard'
import Navbar from '../../Header/headerComponent'
import useUser from "../../../hooks/useUser";
import { useHistory } from "react-router-dom";
import { notification } from 'antd';
import { SmileOutlined, CloseOutlined } from '@ant-design/icons';
import PaymentMethodForm from './Form/payMethodForm';
import '../settings.css'

const PaymentMethods = () => {
    const [reload, setRelaod] = useState(false);
    const { auth } = useSelector((state) => state.auth);
    const { isLogin } = useUser()
    const { addPaymentMethods } = useDashboard();
    let history = useHistory();

    useEffect(async () => {
        await authCheck();
    }, [reload])

    const authCheck = async () => {
        var response = await isLogin();
        if (parseInt(response.status) === 401) {
            history.push("/login");
        }
    };

    const addPayment = async (data) => {
        var res = await addPaymentMethods(data);
        if (res) {
            notification.open({
                message: 'Update',
                description: res.message,
                icon: <SmileOutlined style={{ color: '#108ee9' }} />,
            });
        }
        else {
            notification.open({
                message: 'Error',
                description: 'Something went wrong...!',
                icon: <CloseOutlined style={{ color: '#f4364c' }} />,
            });
        }
        setRelaod(!reload)
        return res;
    }
    return (
        <>
            <nav>
                <div className="headerSection">
                    <Navbar />
                </div>
            </nav>
            <div className="container">
                <div className="wrapHomeSection">
                    <div className="leftSideMenu">
                        <LeftMenu />
                    </div>
                    <div className="settingArea">
                        <Grid container>
                            <Grid item xs={12}>
                                <div className="bannerImageSection">
                                    <div className="bannerText">
                                        <pre>You can select Your default Withdrawal Option. No Platforms commission is Charged if Payout is selected in
                                            Evergrow Coin.</pre>
                                    </div>
                                </div>
                                <div className="settingFormSection">
                                    {auth ? <PaymentMethodForm addPayment={(data) => { addPayment(data) }} details={auth} /> : ''}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect((state) => state)(PaymentMethods);