import React,{useEffect,useState} from 'react'
import { useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import ProfileLeftMenu from "../Home/RighSideMenu/RightSidebarComponents";
import RightSidebarComponents from "../Home/RighSideMenu/RightSidebarComponents";
import useHome from '../../hooks/useHome';
import Navbar from "../Header/headerComponent";
import './LiveStreaming.css';

const JoinRoom = (props)=>{

    const [url,setUrl] = useState(null)
    const { joinRoom,deleteRoom } = useHome()
    const [hosturl,setHostUrl] = useState(null)
    const [isAdmin,setIsAdmin] = useState(false)
    const [meetingId,setMeetingId] = useState('')
    const history = useHistory();
    const {auth} = useSelector((state)=>state.auth)

    useEffect(async()=>{
        var res = await joinRoom(props.match.params.name);
        await setUrl(res.url)
        await setIsAdmin(res.isHost)
        await setIsAdmin(res.isHost)
        await setMeetingId(res.meetingId)
    },[auth])

    const JoinRoom = () =>{
        if(isAdmin){
          
        } 

    }
    const leaveRoom = async() =>{
        if(isAdmin){
          console.log(await deleteRoom(meetingId))
        }
        history.push('/live_treaming')               
    }
    const elm = document.querySelector("whereby-embed");
    const output = document.querySelector("output");
    if(elm){
       elm.addEventListener("join", joinRoom)
        elm.addEventListener("leave", leaveRoom)
    }
    return(
    <>
      <nav>
        <div className="headerSection">
          <Navbar />
        </div>
      </nav>
      <div className="container">
        <div className="wrapHomeSection feedPostMarginTopThirty">
          <div className="leftSideMenu">
          </div>
          <div className="middleSection">           
            <div className="postArea">
             {url ? <whereby-embed  room={url}></whereby-embed> :''} 
            </div>
            <div>
           
            </div>
          </div>
          <div className="leftSideMenu">
          </div>

        </div>
      </div>
    </>
    )
}

export default JoinRoom;