export const videoFormate = [
  "mp4",
  "avi",
  "mov",
  "mpg",
  "wmv",
  "flv",
  "webm",
  "mkv",
];
export const imageFormate = ["jpeg", "jpg", "png", "gif", "tiff"];
export const docsFormate = ["doc", "docx", "pdf", "txt", "odt", "xls", "xlsx"];
