import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getIn, Field, Formik, Form, ErrorMessage } from "formik";
import InputGroup from "../../../Comman/InputComponent";
import { adminPackageSchema } from "../../../../utils/Validations";

const AddPackageForm = ({ addPackage }) => {
    const initialValues = {
        packageType: "custom",
        packageKey: "",
        packageFund: "",
        walletAmount: "",
        packageStatus: "inactive"
    }

    return (
        <>
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={adminPackageSchema}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => {
                        addPackage(values);
                        resetForm();
                    }}
                >
                    {({ errors, touched, values, setFieldValue }) => (
                        <Form className="form-group">
                            <br></br>
                            <h1>Add Users</h1>
                            <div className="mt-5">
                                <div className="col-md-4 form-group error-div">
                                    <label htmlFor="userVerifiedStatus" style={{ display: 'block' }}>
                                        Package Type:
                                    </label>
                                    <div role="group" aria-labelledby="my-radio-group">
                                        <label>
                                            <Field type="radio" name="packageType" value="custom" />
                                            Custom
                                        </label>
                                        <label>
                                            <Field type="radio" name="packageType" value="normal" />
                                            Normal
                                        </label>
                                    </div>

                                    <InputGroup type="text" name="packageKey" label='Package Key' placaholder='package key' />
                                    <InputGroup type="text" name="packageFund" label='package fund' placeholder='package fund' />
                                    <InputGroup type="text" name="walletAmount" label='wallet amount' placeholder='wallet amount' />
                                </div>
                            </div>
                            <div className="settingSubmit"><button type="submit">Save</button></div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default connect((state) => state)(AddPackageForm);