import React,{useEffect,useState} from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Navbar from "../Header/headerComponent";
import "react-toastify/dist/ReactToastify.css";
import usePayment from '../../hooks/usePayment';
import useUser from "../../hooks/useUser";
import StripeCheckout from 'react-stripe-checkout';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',  
  color: theme.palette.text.secondary,
}));

toast.configure();

const Payment = () => {
  const {createPayment,getPointsList} = usePayment();
  const { isLogin,GetUserProfile } = useUser()
  const [points,sePoints] = useState()

  const history = useHistory()

  useEffect(async()=>{
    await authCheck()
    await sePoints(await getPointsList())
  },[])

  const authCheck = async () => {
    var response = await isLogin();
    if (parseInt(response.status) === 401) {
        history.push("/login");
    } else {     
        //history.push("/");
    }
};
  async function handleToken(token,amount) {
    token.amount = amount  
    var res = await createPayment(token)
  }

  return (
    <>
      <nav>
        <div className="headerSection">
          <Navbar />
        </div>
        <div>
          <div className="container" style={{marginTop:'50px'}}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                {points && points.map((value,key)=>(
                  <Grid item xs={6} md={4}>
                    <Item>
                      <div>
                        <h4>{value.packageFund}</h4>
                      </div>
                      <div>
                      <StripeCheckout
                        stripeKey="pk_test_51Jzps1FIk7q10ficH1eltzOpWcX2es9Ziqehb4aMwWp5WxiDNhOmGejVtJYqdGLe1QAoACBZwtTcFvqZAnilZ7Zt00RhRGqHSU"
                        token={((token)=>handleToken(token,value.packageFund))}
                        amount={value.packageFund * 100}
                        name="Crator"
                        billingAddress
                        shippingAddress
                      >
                        <Button
                          type="submit"
                          fullWidth={true}
                          variant="contained"
                          background="#3274e4"
                        >
                          ${value.packageFund}
                        </Button>
                      </StripeCheckout>
 
                      </div>  
                    </Item>
                  </Grid>
                ))}                  
              </Grid>
            </Box>            
          </div>  
        </div>
      </nav>
    </>
  );
}

export default Payment;
