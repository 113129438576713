import HomeRepository from "../api/HomeRepository.js";
import { useDispatch } from "react-redux";
import { login, logout } from "../store/auth/action";

export default function useStory() {
  const dispatch = useDispatch();
  return {
    StoryCreate: async (data) => {
      var responseData = await HomeRepository.StoryCreate(data);
      if (responseData.status === 201) {
        return true;
      }
      return false;
    },
    GetStory: async () => {
      var responseData = await HomeRepository.GetStory();
      if (responseData.status === 200) {
        return responseData.data.data;
      }
      return false;
    },
  };
}
