import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { isEmpty } from 'lodash'
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import LeftMenu from "../LeftMenu";
import useUser from "../../../hooks/useUser";
import useDashboard from "../../../hooks/useDashboard";
import Navbar from '../../Header/headerComponent';
import { useHistory } from "react-router-dom";
import { Button, notification } from 'antd';
import { SmileOutlined, CloseOutlined } from '@ant-design/icons';
import '../settings.css'
import AccountUpdateForm from "./Form/AccountUpdateForm";

const AccountSettings = () => {
    const [reload, setReload] = useState(false)
    const { auth } = useSelector((state) => state.auth);
    const { isLogin } = useUser()
    const { saveUserAccount, updateEmail } = useDashboard();

    let history = useHistory();

    useEffect(async () => {
        await authCheck();
    }, [reload])

    const authCheck = async () => {
        var response = await isLogin();
        if (parseInt(response.status) === 401) {
            history.push("/login");
        }
    };

    const update = async (data) => {
        var res = await saveUserAccount(data);
        if (res) {
            notification.open({
                message: 'Update',
                description: 'Profile Successfully Updated...!',
                icon: <SmileOutlined style={{ color: '#108ee9' }} />,
            });
        }
        else {
            notification.open({
                message: 'Error',
                description: 'Something went wrong...!',
                icon: <CloseOutlined style={{ color: '#f4364c' }} />,
            });
        }
        setReload(!reload)
    }
    const updateMail = async (data) => {
        var res = await updateEmail(data);
        if (res) {
            notification.open({
                message: 'Update',
                description: 'Profile Successfully Updated...!',
                icon: <SmileOutlined style={{ color: '#108ee9' }} />,
            });
        }
        else {
            notification.open({
                message: 'Error',
                description: res?.messages?.message,
                icon: <CloseOutlined style={{ color: '#f4364c' }} />,
            });
        }
        setReload(!reload)
        return res;
    }

    return (
        <div>
            <nav>
                <div className="headerSection">
                    <Navbar />
                </div>
            </nav>
            <div className="container">
                <div className="wrapHomeSection">
                    <div className="leftSideMenu">
                        <LeftMenu />
                    </div>
                    <div className="settingArea">
                        <Grid container>
                            <Grid item xs={12}>
                                <div className="settingFormFields">
                                    {!isEmpty(auth) ?
                                        <AccountUpdateForm
                                            update={((data) => update(data))}
                                            userDetails={auth}
                                            updateEmail={(data) => updateMail(data)}
                                        />
                                        : ''
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect((state) => state)(AccountSettings);